import { isEmpty } from 'radash';
import { create } from 'zustand';
import { loadingStore, messageStore } from '@modules/core/adapter/outbound/stores/ui.store';
import { InterestingRepositoryImpl } from '@modules/interesting/adapter/inbound/repositories/interesting.repository';
import { GetAllInterestingUsecase } from '@modules/interesting/application/usecases/getAllInteresting.usecase';
import { SaveAllChoosedInterestingUsecase } from '@modules/interesting/application/usecases/saveAllChoosedInteresting.usecase';
import { SaveChoosedInterestingUsecase } from '@modules/interesting/application/usecases/saveChoosedInteresting.usecase';
import { UpdateShouldShowInterestingUsecase } from '@modules/interesting/application/usecases/updateShouldShowInteresting.usecase';
import { IInteresting, IInterestingContent } from '@modules/interesting/domain/models/interesting';
import { userStore } from '@modules/user/adapter/outbound/stores/user.store';
export interface InterestState {
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
  activeStep: number;
  setActiveStep: (step: number) => void;
  interestStepList: IInteresting[];
  fetchInterestStepList: () => Promise<void>;
  setChoosedContent: (itemId: number) => void;
  interestStep: IInteresting;
  setInterestStep: () => void;
  save: () => void;
  saveAll: () => void;
  shouldShowSkipModal: boolean;
  setShouldShowSkipModal: (val: boolean) => void;
  shouldShowSuccessModal: boolean;
  setShouldShowSuccessModal: (val: boolean) => void;
  updateShouldShowInteresting: (shouldShow: boolean) => void;
}
const defaultValue: IInteresting = {
  id: null,
  title: '',
  content: [],
  type: null
};
export const interestingStore = create<InterestState>()((set, get) => ({
  isModalOpen: false,
  setIsModalOpen: (val: boolean): void => {
    set(() => ({
      isModalOpen: val
    }));
  },
  activeStep: 1,
  setActiveStep: (step: number): void => {
    if (!isEmpty(step)) {
      set(() => ({
        activeStep: step
      }));
      get().setInterestStep();
    }
    if (step > get().interestStepList?.length) {
      get().interestStepList.forEach(({
        content
      }) => {
        const hasChoosedItem = content.some(({
          isChoosed
        }) => isChoosed);
        if (hasChoosedItem) {
          get().setShouldShowSuccessModal(true);
          return;
        } else {
          get().setShouldShowSkipModal(true);
        }
      });
    }
  },
  interestStepList: null,
  fetchInterestStepList: async () => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const interestingRepositoryImpl = new InterestingRepositoryImpl();
      const getAllUsecase = new GetAllInterestingUsecase(interestingRepositoryImpl);
      await userStore.getState().fetchUserDetail();
      const lists = await getAllUsecase.handle();
      if (!isEmpty(lists) && !isEmpty(userStore.getState().userDetail)) {
        set(() => ({
          interestStepList: lists
        }));
        get().setInterestStep();
      }
    } catch (err) {
      console.error('Error fetchInterestStepList', err);
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  },
  interestStep: defaultValue,
  setInterestStep: (): void => {
    if (!isEmpty(get().interestStepList)) {
      set(() => ({
        interestStep: get().interestStepList.find(({
          id
        }) => id === get().activeStep)
      }));
    }
  },
  setChoosedContent: (itemId: number): void => {
    const filterItem: IInterestingContent[] = get().interestStep.content.map(contentItem => {
      if (contentItem.id === itemId) contentItem.isChoosed = !contentItem.isChoosed;
      return contentItem;
    });
    if (!isEmpty(filterItem)) {
      set(() => ({
        interestStep: {
          ...get().interestStep,
          content: filterItem
        }
      }));
    }
  },
  save: async () => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const interestingRepositoryImpl = new InterestingRepositoryImpl();
      const saveUsecase = new SaveChoosedInterestingUsecase(interestingRepositoryImpl);
      await saveUsecase.handle(get().interestStep);
      get().setActiveStep(get().activeStep + 1);
    } catch (err) {
      console.error('error interestingStore.save', err);
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  },
  saveAll: async () => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const interestingRepositoryImpl = new InterestingRepositoryImpl();
      const saveAllUsecase = new SaveAllChoosedInterestingUsecase(interestingRepositoryImpl);
      await saveAllUsecase.handle(get().interestStepList);
      get().fetchInterestStepList();
    } catch (err) {
      console.error('error interestingStore.saveAll', err);
    } finally {
      loadingStore.setState({
        isLoading: false
      });
      messageStore.setState({
        messageInfo: {
          text: 'บันทึกความสนใจสำเร็จ',
          status: 'success',
          duration: 2
        }
      });
    }
  },
  shouldShowSkipModal: false,
  setShouldShowSkipModal: (val: boolean) => {
    set(() => ({
      shouldShowSkipModal: val
    }));
  },
  shouldShowSuccessModal: false,
  setShouldShowSuccessModal: (val: boolean) => {
    set(() => ({
      shouldShowSuccessModal: val
    }));
  },
  updateShouldShowInteresting: async (shouldShow: boolean) => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const interestingRepositoryImpl = new InterestingRepositoryImpl();
      const updateShouldShowInterestingUsecase = new UpdateShouldShowInterestingUsecase(interestingRepositoryImpl);
      await updateShouldShowInterestingUsecase.handle(shouldShow);
      userStore.getState().fetchUserDetail();
    } catch (err) {
      console.error('Error updateShouldShowInteresting', err);
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  }
}));