import { Skeleton } from 'antd';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "ContentModalstyle__Container",
  componentId: "sc-qta6a8-0"
})(["", " border-radius:0 0 28px 28px;"], {
  "marginTop": "3rem",
  "display": "flex",
  "flexDirection": "column",
  "gap": "3rem"
});
export const Wrapper = styled.div.withConfig({
  displayName: "ContentModalstyle__Wrapper",
  componentId: "sc-qta6a8-1"
})(["", ""], {
  "position": "relative"
});
export const LineMask = styled.div.withConfig({
  displayName: "ContentModalstyle__LineMask",
  componentId: "sc-qta6a8-2"
})(["", " ", " border-radius:200px 200px 0 0;@media (max-width:", "){", " border-radius:20px 20px 0 0;}"], {
  "position": "absolute",
  "top": "-1px",
  "left": "50%",
  "zIndex": "50",
  "--tw-translate-x": "-50%",
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
}, {
  "height": "0.75rem",
  "width": "95.5%",
  "backgroundColor": "rgba(239,239,239,1)"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "width": "95%"
});
export const NodeWrapper = styled.div.withConfig({
  displayName: "ContentModalstyle__NodeWrapper",
  componentId: "sc-qta6a8-3"
})(["", ""], {
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center"
});
export const ContentWrapper = styled.div.withConfig({
  displayName: "ContentModalstyle__ContentWrapper",
  componentId: "sc-qta6a8-4"
})(["background-image:url('/images/interesting/interesting-content-bg.png');", " ", " @media (max-width:", "){", "}"], {
  "backgroundSize": "contain",
  "backgroundPosition": "center",
  "backgroundRepeat": "no-repeat"
}, {
  "height": "411px",
  "width": "100% !important",
  "overflowY": "scroll"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "height": "388px",
  "borderRadius": "1.5rem",
  "backgroundSize": "cover",
  "backgroundPosition": "left top",
  "paddingLeft": "0.75rem",
  "paddingRight": "0.75rem",
  "paddingTop": "1rem",
  "paddingBottom": "1rem"
});
export const ContentDescription = styled.div.withConfig({
  displayName: "ContentModalstyle__ContentDescription",
  componentId: "sc-qta6a8-5"
})(["", " @media (max-width:", "){", "}"], {
  "marginTop": "1.5rem",
  "textAlign": "center",
  "fontSize": "28px",
  "lineHeight": "30px",
  "--tw-text-opacity": "1",
  "color": "rgb(167 167 167 / var(--tw-text-opacity, 1))"
}, ({
  theme
}) => theme.breakpoint.md, {
  "marginTop": "0.5rem",
  "fontSize": "1.5rem",
  "lineHeight": "2rem"
});
export const ContentDescriptionSkeleton = styled(Skeleton).withConfig({
  displayName: "ContentModalstyle__ContentDescriptionSkeleton",
  componentId: "sc-qta6a8-6"
})(["", ""], {
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "1.5rem",
  "height": "1.5rem",
  "width": "33.333333%"
});
export const Content = styled.div.withConfig({
  displayName: "ContentModalstyle__Content",
  componentId: "sc-qta6a8-7"
})(["", " @media (max-width:", "){", "}"], {
  "display": "grid",
  "gridTemplateColumns": "repeat(5, minmax(0, 1fr))",
  "columnGap": "42px",
  "rowGap": "1rem",
  "paddingLeft": "3.5rem",
  "paddingRight": "3.5rem",
  "paddingBottom": "1.5rem",
  "paddingTop": "2.75rem",
  "textTransform": "uppercase"
}, ({
  theme
}) => theme.breakpoint.md, {
  "gridTemplateColumns": "repeat(3, minmax(0, 1fr))",
  "gap": "0.5rem",
  "paddingLeft": "0px",
  "paddingRight": "0px",
  "paddingBottom": "0.25rem",
  "paddingTop": "1rem"
});