import { FC, ReactNode } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Col, Row } from 'antd';
import { filterStore } from '@modules/filter/adapter/outbound/stores/filter.store';
import { IMenuLink } from '@providers/footer/Footer.provider';
import { ModuleType } from '@shared/types/module.type';
import Contact from '../contact/Contact';
import Social from '../social/Social';
import { Container, CustomList, CustomListItem, Wrapper } from './FooterDesktop.style';
export interface Props {
  whatIEatMenu: IMenuLink[];
  productMenu: IMenuLink[];
  optionMenu: IMenuLink[];
  onClickTracker: (title: string) => void;
}
const FooterDesktop: FC<Props> = ({
  whatIEatMenu,
  productMenu,
  optionMenu,
  onClickTracker
}: Props) => {
  const router = useRouter();
  const {
    clearAllSelectedCategoryItem
  } = filterStore(state => state);
  const renderMenu = (menus: IMenuLink[]) => {
    const isSearchPage = router.pathname === '/search';
    return menus.map((menu, index) => {
      return <Link href={menu.linkout} key={index} onClick={() => {
        onClickTracker(menu.text);
        isSearchPage && clearAllSelectedCategoryItem(ModuleType.Product);
      }} aria-label={menu.text}>
                    {menu.text}
                </Link>;
    });
  };
  const listContent = (content: {
    className?: string;
    header?: ReactNode;
    content?: ReactNode[];
  }) => {
    return <CustomList className={content.className} header={content.header} split={false} itemLayout="horizontal" dataSource={content.content} renderItem={(item: ReactNode, index: number) => <CustomListItem key={index}>{item}</CustomListItem>} />;
  };
  const list = {
    secondCol: {
      className: 'second-col',
      header: <b>กินอะไรดี?</b>,
      content: renderMenu(whatIEatMenu)
    },
    thirdCol: {
      className: 'third-col',
      header: <b>ผลิตภัณฑ์ซีพี</b>,
      content: renderMenu(productMenu)
    },
    quartCol: {
      className: 'quart-col',
      header: '',
      content: renderMenu(optionMenu)
    }
  };
  return <Container>
            <Wrapper>
                <Row>
                    <Col span={24} md={8}>
                        <Contact />
                    </Col>
                    <Col span={24} md={3}>
                        {listContent(list.secondCol)}
                    </Col>
                    <Col span={24} md={3}>
                        {listContent(list.thirdCol)}
                    </Col>
                    <Col span={24} md={3}>
                        {listContent(list.quartCol)}
                    </Col>
                    <Col span={24} md={7}>
                        <Social />
                    </Col>
                </Row>
            </Wrapper>
        </Container>;
};
export default FooterDesktop;