import Image from 'next/image';
import styled from 'styled-components';
import theme from '@shared/assets/palette.json';
export const Container = styled.div.withConfig({
  displayName: "ItemWithIconstyle__Container",
  componentId: "sc-1mqvinr-0"
})(["", ""], {
  "position": "relative",
  "marginLeft": "auto",
  "marginRight": "auto",
  "height": "fit-content",
  "width": "fit-content"
});
export const ItemWrapper = styled.div.withConfig({
  displayName: "ItemWithIconstyle__ItemWrapper",
  componentId: "sc-1mqvinr-1"
})(["", " ", " outline:", ";background-image:", ";box-shadow:", ";&:hover{.bg-image{", " @media (max-width:", "){", "}}}@media (max-width:", "){", "}"], {
  "position": "relative",
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.5rem"
}, {
  "margin": "0.375rem",
  "aspectRatio": "1 / 1",
  "width": "114px",
  "cursor": "pointer",
  "overflow": "hidden",
  "borderRadius": "9999px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity, 1))"
}, ({
  $isActive
}) => $isActive ? '6px solid #29CC6A' : '0', ({
  $isActive
}) => $isActive ? 'linear-gradient(#CE0E2D, #DF2B41)' : 'linear-gradient(none)', ({
  $isActive
}) => $isActive ? '0' : '3px 2px 0px 0px #0000000F inset', {
  "--tw-scale-x": "1.25",
  "--tw-scale-y": "1.25",
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "transitionProperty": "transform",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "--tw-scale-x": "1",
  "--tw-scale-y": "1",
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "width": "90px"
});
export const ImageStyle = styled(Image).withConfig({
  displayName: "ItemWithIconstyle__ImageStyle",
  componentId: "sc-1mqvinr-2"
})(["", " filter:", ";@media (max-width:", "){", "}"], {
  "aspectRatio": "1 / 1",
  "width": "2.5rem",
  "objectFit": "cover",
  "transitionProperty": "transform",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
}, ({
  $isActive
}) => $isActive ? 'invert(97%) sepia(100%) saturate(0%) hue-rotate(44deg) brightness(103%) contrast(104%)' : `invert(35%) sepia(12%) saturate(13%) hue-rotate(345deg) brightness(92%) contrast(92%)`, ({
  theme
}) => theme.breakpoint.lg, {
  "width": "2rem"
});
export const CheckerWrapper = styled.div.withConfig({
  displayName: "ItemWithIconstyle__CheckerWrapper",
  componentId: "sc-1mqvinr-3"
})(["", " ", " ", " @media (max-width:", "){", "}"], {
  "position": "absolute",
  "bottom": "0.5rem",
  "right": "0.5rem",
  "zIndex": "49"
}, {
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center"
}, {
  "aspectRatio": "1 / 1",
  "width": "1.75rem",
  "borderRadius": "9999px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(41 204 106 / var(--tw-bg-opacity, 1))",
  "outlineStyle": "solid",
  "outlineWidth": "3px",
  "outlineColor": "#fff"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "width": "1.25rem"
});
export const Checker = styled(Image).withConfig({
  displayName: "ItemWithIconstyle__Checker",
  componentId: "sc-1mqvinr-4"
})(["", " @media (max-width:", "){", "}"], {
  "aspectRatio": "1 / 1",
  "height": "1rem",
  "width": "1rem"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "width": "0.75rem"
});
export const Title = styled.div.withConfig({
  displayName: "ItemWithIconstyle__Title",
  componentId: "sc-1mqvinr-5"
})(["", " ", " ", " color:", ";@media (max-width:", "){", "}"], {
  "position": "relative",
  "zIndex": "49",
  "maxWidth": "80px",
  "textTransform": "uppercase"
}, {
  "whiteSpace": "break-spaces",
  "textAlign": "center"
}, {
  "fontSize": "19px",
  "fontWeight": "700",
  "lineHeight": "16px"
}, ({
  $isActive
}) => $isActive ? 'white' : `${theme.grey[40]}`, ({
  theme
}) => theme.breakpoint.lg, {
  "width": "80%",
  "fontSize": "1rem",
  "lineHeight": "18px"
});