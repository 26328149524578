import { FloatButton } from 'antd';
import styled from 'styled-components';
export const CustomBackTop = styled(FloatButton.BackTop).withConfig({
  displayName: "BackTopstyle__CustomBackTop",
  componentId: "sc-1cilcae-0"
})(["", " @media (max-width:", "){", "}"], {
  "right": "2.5rem",
  "zIndex": "40"
}, ({
  theme
}) => theme.breakpoint.md, {
  "right": "1rem"
});