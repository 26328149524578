import { FC } from 'react';
import Image from 'next/image';
import { analytics } from '@shared/utils/segment.utils';
import { CustomBackTop } from './BackTop.style';
const BackTop: FC = () => {
  const handleOnClickTracker = () => {
    analytics.track('Back To Top Click', {
      event: 'back_to_top_click'
    });
  };
  return <CustomBackTop icon={<Image src="/icons/arrow-top.svg" alt="arrow-top" width={32} height={33} />} onClick={() => {
    handleOnClickTracker();
  }} />;
};
export default BackTop;