import { FC } from 'react';
import { isEmpty } from 'radash';
import { interestingStore } from '@modules/interesting/adapter/outbound/stores/interesting.store';
import InterestingItemSkeleton from '@components/skeleton/interesting/InterestingContent';
import InterestContentItem from './Item';
import InterestContentItemWithIcon from './withIcon/ItemWithIcon';
interface Props {
  isMixer: boolean;
}
const InterestContentItemLogic: FC<Props> = ({
  isMixer
}: Props) => {
  const {
    interestStep,
    setChoosedContent
  } = interestingStore(state => state);
  return isEmpty(interestStep?.content) ? <InterestingItemSkeleton /> : interestStep?.content?.map(item => {
    if (isMixer) {
      return <InterestContentItemWithIcon key={item.id} imageUrl={item.imageUrl} title={item.title} isActive={item.isChoosed} onClick={() => setChoosedContent(item.id)} />;
    }
    return <InterestContentItem key={item.id} imageUrl={item.imageUrl} title={item.title} isActive={item.isChoosed} onClick={() => setChoosedContent(item.id)} />;
  });
};
export default InterestContentItemLogic;