import { FC, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { isEmpty } from 'radash';
import { authStore } from '@modules/auth/adapter/outbound/stores/auth.store';
import { interestingStore } from '@modules/interesting/adapter/outbound/stores/interesting.store';
import { userStore } from '@modules/user/adapter/outbound/stores/user.store';
import { getAuthCookiesName } from '@shared/helpers/getAuthCookiesName.helper';
import useLockedBody from '@shared/utils/hooks/useLockedBody.utils';
import InterestModal from './InterestModal';
const InterestModalLogic: FC = () => {
  const token = Cookies.get(getAuthCookiesName());
  const [isInit, setIsInit] = useState(false);
  const [_locked, setLocked] = useLockedBody(false, 'root');
  const {
    isModalOpen,
    setIsModalOpen,
    updateShouldShowInteresting,
    fetchInterestStepList
  } = interestingStore(state => state);
  const {
    userDetail
  } = userStore(state => state);
  const {
    isRenewingToken
  } = authStore(state => state);
  useEffect(() => {
    setIsInit(true);
  }, []);
  useEffect(() => {
    if (isInit) handleIsOpenModal();
  }, [isInit, token, userDetail, isRenewingToken]);
  useEffect(() => {
    setLocked(isModalOpen);
    if (isModalOpen) fetchInterestStepList();
  }, [isModalOpen]);
  const handleIsOpenModal = () => {
    if (isEmpty(token)) {
      setIsModalOpen(false);
    } else if (userDetail?.shouldShowInteresting && !isRenewingToken) {
      setIsModalOpen(true);
      updateShouldShowInteresting(false);
    }
  };
  return <InterestModal isModalOpen={isModalOpen} />;
};
export default InterestModalLogic;