import { Skeleton } from 'antd';
import styled from 'styled-components';
export const SkeletonWrapper = styled.div.withConfig({
  displayName: "InterestingContentstyle__SkeletonWrapper",
  componentId: "sc-18k8xbo-0"
})(["", ""], {
  "position": "relative",
  "aspectRatio": "1 / 1"
});
export const CustomSkeletonBg = styled(Skeleton.Avatar).withConfig({
  displayName: "InterestingContentstyle__CustomSkeletonBg",
  componentId: "sc-18k8xbo-1"
})(["", " .ant-skeleton-avatar{", "}"], {
  "position": "absolute",
  "inset": "0px",
  "zIndex": "10"
}, {
  "aspectRatio": "1 / 1",
  "height": "auto !important",
  "width": "100% !important",
  "--tw-bg-opacity": "1 !important",
  "backgroundColor": "rgb(246 246 246 / var(--tw-bg-opacity, 1)) !important"
});
export const CustomSkeleton = styled(Skeleton.Avatar).withConfig({
  displayName: "InterestingContentstyle__CustomSkeleton",
  componentId: "sc-18k8xbo-2"
})(["", " .ant-skeleton-avatar{", "}"], {
  "position": "absolute",
  "inset": "0px",
  "zIndex": "20"
}, {
  "aspectRatio": "1 / 1",
  "height": "auto !important",
  "width": "100% !important"
});