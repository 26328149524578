import { Button } from 'antd';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "FooterModalstyle__Container",
  componentId: "sc-1sh9gao-0"
})(["", ""], {
  "marginTop": "1.5rem",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between"
});
export const LeftText = styled.div.withConfig({
  displayName: "FooterModalstyle__LeftText",
  componentId: "sc-1sh9gao-1"
})(["", " &:hover{", "}"], {
  "cursor": "pointer",
  "fontSize": "28px",
  "fontWeight": "500",
  "lineHeight": "30px",
  "--tw-text-opacity": "1",
  "color": "rgb(206 206 206 / var(--tw-text-opacity, 1))",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
}, {
  "--tw-text-opacity": "1",
  "color": "rgb(255 204 99 / var(--tw-text-opacity, 1))",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
});
export const NextButton = styled(Button).withConfig({
  displayName: "FooterModalstyle__NextButton",
  componentId: "sc-1sh9gao-2"
})(["", " background-image:linear-gradient(to bottom,rgba(206,14,45,1),rgba(223,43,65,1)) !important;box-shadow:0px 8px 12px 0px rgba(223,43,65,0.2) !important;span{", "}"], {
  "minHeight": "3rem",
  "minWidth": "123px",
  "paddingLeft": "2.5rem !important",
  "paddingRight": "2.5rem !important",
  "paddingTop": "0.375rem !important",
  "paddingBottom": "0.375rem !important"
}, {
  "fontSize": "28px"
});