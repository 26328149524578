import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {
  [key: string]: unknown;
}> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends {
  [key: string]: unknown;
}, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {
    input: string;
    output: string;
  };
  String: {
    input: string;
    output: string;
  };
  Boolean: {
    input: boolean;
    output: boolean;
  };
  Int: {
    input: number;
    output: number;
  };
  Float: {
    input: number;
    output: number;
  };
  DateTime: {
    input: any;
    output: any;
  };
};
export enum ActivityCategory {
  Activities = 'Activities',
  Promotion = 'Promotion',
}
export type ActivityResponse = {
  __typename?: 'ActivityResponse';
  activityCategory?: Maybe<ActivityCategory>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  detail?: Maybe<Scalars['String']['output']>;
  endedAt: Scalars['DateTime']['output'];
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  feedDescription?: Maybe<Scalars['String']['output']>;
  feedKeyword?: Maybe<Scalars['String']['output']>;
  feedTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  ogImage?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slugUrl?: Maybe<Scalars['String']['output']>;
  startedAt: Scalars['DateTime']['output'];
  status: StatusType;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type ActivityWithCreatorResponse = {
  __typename?: 'ActivityWithCreatorResponse';
  activityCategory?: Maybe<ActivityCategory>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<CreatorResponse>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  detail?: Maybe<Scalars['String']['output']>;
  endedAt: Scalars['DateTime']['output'];
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  feedDescription?: Maybe<Scalars['String']['output']>;
  feedKeyword?: Maybe<Scalars['String']['output']>;
  feedTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  ogImage?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slugUrl?: Maybe<Scalars['String']['output']>;
  startedAt: Scalars['DateTime']['output'];
  status: StatusType;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type AdminResponse = {
  __typename?: 'AdminResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  isResetPassword: Scalars['Boolean']['output'];
  jtiToken?: Maybe<Scalars['String']['output']>;
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  password: Scalars['String']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
  role: AdminRole;
  status: StatusType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export enum AdminRole {
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin',
}
export type AuthResponse = {
  __typename?: 'AuthResponse';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};
export type BannerRelateRequest = {
  /** page relate banner id */
  id: Scalars['Int']['input'];
  priority: Scalars['Int']['input'];
};
export type BannerRelateWithAllPageResponse = {
  __typename?: 'BannerRelateWithAllPageResponse';
  bannerDesktop: Scalars['String']['output'];
  bannerIos?: Maybe<Scalars['String']['output']>;
  bannerMobile: Scalars['String']['output'];
  bannerRelatePage?: Maybe<Array<RelateWithPageResponse>>;
  bannerType: BannerType;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  linkOutUrl?: Maybe<Scalars['String']['output']>;
  pages?: Maybe<Array<Scalars['String']['output']>>;
  path?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type BannerRelateWithPageResponse = {
  __typename?: 'BannerRelateWithPageResponse';
  bannerDesktop: Scalars['String']['output'];
  bannerIos?: Maybe<Scalars['String']['output']>;
  bannerMobile: Scalars['String']['output'];
  bannerRelatePage?: Maybe<RelateWithPageResponse>;
  bannerType: BannerType;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  linkOutUrl?: Maybe<Scalars['String']['output']>;
  pages?: Maybe<Array<Scalars['String']['output']>>;
  path?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type BannerRelateWithPageResponseWithTotal = {
  __typename?: 'BannerRelateWithPageResponseWithTotal';
  data: Array<BannerRelateWithAllPageResponse>;
  total: Scalars['Int']['output'];
};
export type BannerResponse = {
  __typename?: 'BannerResponse';
  bannerDesktop: Scalars['String']['output'];
  bannerIos?: Maybe<Scalars['String']['output']>;
  bannerMobile: Scalars['String']['output'];
  bannerType: BannerType;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  linkOutUrl?: Maybe<Scalars['String']['output']>;
  pages?: Maybe<Array<Scalars['String']['output']>>;
  path?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export enum BannerType {
  Game = 'Game',
  GameEvent = 'GameEvent',
  HeroBanner = 'HeroBanner',
}
export type BranchResponse = {
  __typename?: 'BranchResponse';
  id: Scalars['Int']['output'];
  linkOut?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};
export type BrandCmpdResponse = {
  __typename?: 'BrandCMPDResponse';
  id: Scalars['Int']['output'];
  linkOutUrl?: Maybe<Scalars['String']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};
export type BrandPopularResponse = {
  __typename?: 'BrandPopularResponse';
  brandId: Scalars['Int']['output'];
  coverImg: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  linkOutUrl?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  /** default value is 100 */
  priority: Scalars['Int']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type BrandResponse = {
  __typename?: 'BrandResponse';
  brandId: Scalars['Int']['output'];
  coverImg: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  linkOutUrl?: Maybe<Scalars['String']['output']>;
  /** default value is 100 */
  priority: Scalars['Int']['output'];
  status: StatusType;
  thumbnailUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type CmsDeleteBannerRequest = {
  /** use banner id */
  bannerId: Scalars['Int']['input'];
};
export type CmsDeleteRegisterBannerRequest = {
  /** use regis banner id */
  id: Scalars['Int']['input'];
};
export type CmsDeleteTrendsForYouRequest = {
  /** use trends for you id */
  id: Scalars['Int']['input'];
};
export type CmsGetBannerByIdRequest = {
  /** banner id */
  id: Scalars['Int']['input'];
};
export type CmsGetRegisterBannerByIdRequest = {
  /** use register banner id */
  id: Scalars['Int']['input'];
};
export type CmsGetTrendsForYouByIdRequest = {
  /** use trends for you id */
  id: Scalars['Int']['input'];
};
export type CmsSaveTrendsForYouRequest = {
  facebookUrl?: InputMaybe<Scalars['String']['input']>;
  hashtag: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  priority?: Scalars['Int']['input'];
  publishedAt: Scalars['DateTime']['input'];
  status: StatusType;
  tiktokUrl?: InputMaybe<Scalars['String']['input']>;
  xTwitterUrl?: InputMaybe<Scalars['String']['input']>;
  youtubeUrl?: InputMaybe<Scalars['String']['input']>;
};
export type CampaignListResponse = {
  __typename?: 'CampaignListResponse';
  campaigns?: Maybe<Array<Maybe<CampaignResponse>>>;
  total: Scalars['Int']['output'];
};
export type CampaignResponse = {
  __typename?: 'CampaignResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  linkoutUrl: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  status: StatusType;
  thumbUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type CdpAuthResponse = {
  __typename?: 'CdpAuthResponse';
  accessToken: Scalars['String']['output'];
};
export type CdpLoginRequest = {
  authCode: Scalars['String']['input'];
};
export type CdpProfileResponse = {
  __typename?: 'CdpProfileResponse';
  birthDate: Scalars['Int']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  point: Scalars['Int']['output'];
  shouldShowInteresting: Scalars['Boolean']['output'];
  uuid: Scalars['String']['output'];
};
export enum CdpSourceType {
  Collection = 'Collection',
  DailyLogin = 'DailyLogin',
  MiniGame1 = 'MiniGame1',
  MiniGame2 = 'MiniGame2',
  MiniGame3 = 'MiniGame3',
  MiniGame4 = 'MiniGame4',
  MiniGame5 = 'MiniGame5',
  MiniGame6 = 'MiniGame6',
  Tutorial = 'Tutorial',
}
export type CdpTopupTrueMoneyRequest = {
  campaign: Scalars['String']['input'];
};
export type CdpTopupTrueMoneyResponse = {
  __typename?: 'CdpTopupTrueMoneyResponse';
  topUpAmount: Scalars['Int']['output'];
};
export type CdpUpdateUserPointResponse = {
  __typename?: 'CdpUpdateUserPointResponse';
  point: Scalars['Int']['output'];
};
export type CdpUpdateUserProfileRequest = {
  point: Scalars['Int']['input'];
  source: CdpSourceType;
};
export type CmsAllFeedSeoResponse = {
  __typename?: 'CmsAllFeedSEOResponse';
  feedSEO?: Maybe<Array<Maybe<CmsFeedSeoResponse>>>;
  total: Scalars['Float']['output'];
};
export type CmsCpConnectListResponse = {
  __typename?: 'CmsCPConnectListResponse';
  CPConnectList?: Maybe<Array<Maybe<CpConnectResponse>>>;
  total: Scalars['Float']['output'];
};
export type CmsDelPopularBrandRequest = {
  /** use id in brand list */
  id: Scalars['Int']['input'];
};
export type CmsDeleteCampaignRequest = {
  /** use campaign id */
  id: Scalars['Int']['input'];
};
export type CmsDeleteCollectionRequest = {
  /** collection id */
  collectionId: Scalars['Int']['input'];
};
export type CmsDeleteCpConnectRequest = {
  /** use cpconnect id */
  id: Scalars['Int']['input'];
};
export type CmsDeleteFeedSeoRequest = {
  /** feed seo id */
  id: Scalars['Int']['input'];
};
export type CmsDeleteGroupCategoryRequest = {
  id: Scalars['Int']['input'];
};
export type CmsDeleteIngredientRequest = {
  /** ingredient id */
  id: Scalars['Int']['input'];
};
export type CmsDeletePopupRequest = {
  /** popup id */
  id: Scalars['Int']['input'];
};
export type CmsDeleteProductPinRequest = {
  id: Scalars['Int']['input'];
};
export type CmsDeleteRecipeRequest = {
  recipeId: Scalars['Int']['input'];
};
export type CmsDeleteTiktokHashtagRequest = {
  /** tiktok hashtag id */
  id: Scalars['Int']['input'];
};
export type CmsDeleteTiktokVideoRequest = {
  id: Scalars['Int']['input'];
};
export type CmsDeleteTipsTricksRequest = {
  id: Scalars['Int']['input'];
};
export type CmsDeleteUnitRequest = {
  /** unit id */
  unitId: Scalars['Int']['input'];
};
export type CmsDraftRecipeRequest = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  collectionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  cooking?: InputMaybe<Scalars['String']['input']>;
  coverImageUrl?: InputMaybe<Scalars['String']['input']>;
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  feedDescription?: InputMaybe<Scalars['String']['input']>;
  feedKeyword?: InputMaybe<Scalars['String']['input']>;
  feedTitle?: InputMaybe<Scalars['String']['input']>;
  groupCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  hackId?: InputMaybe<Scalars['Int']['input']>;
  /** Send Id for update recipe */
  id?: InputMaybe<Scalars['Int']['input']>;
  ingredientImage?: InputMaybe<Scalars['String']['input']>;
  ingredients?: InputMaybe<Array<CmsRecipeRelateIngredientRequest>>;
  levelId?: InputMaybe<Scalars['Int']['input']>;
  meatCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  methodId?: InputMaybe<Scalars['Int']['input']>;
  ogImage?: Scalars['String']['input'];
  people?: Scalars['Int']['input'];
  priority?: Scalars['Int']['input'];
  products?: InputMaybe<Array<CmsRecipeRelateProductRequest>>;
  regionId?: InputMaybe<Scalars['Int']['input']>;
  slugUrl: Scalars['String']['input'];
  /** request status Draft only */
  status: StatusType;
  thumbUrl?: Scalars['String']['input'];
  time?: Scalars['String']['input'];
  title: Scalars['String']['input'];
  toPeople?: InputMaybe<Scalars['Int']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
  youtubeUrl?: InputMaybe<Scalars['String']['input']>;
};
export type CmsDraftSaveActivityRequest = {
  activityCategory: ActivityCategory;
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  description?: Scalars['String']['input'];
  detail?: InputMaybe<Scalars['String']['input']>;
  endedAt?: Scalars['DateTime']['input'];
  expiredAt?: InputMaybe<Scalars['DateTime']['input']>;
  feedDescription?: InputMaybe<Scalars['String']['input']>;
  feedKeyword?: InputMaybe<Scalars['String']['input']>;
  feedTitle?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  /** activity/promotion cover image */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  ogImage?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  slugUrl: Scalars['String']['input'];
  startedAt?: Scalars['DateTime']['input'];
  /** request status Draft only */
  status: StatusType;
  thumbUrl?: Scalars['String']['input'];
  title: Scalars['String']['input'];
};
export type CmsFeedSeoResponse = {
  __typename?: 'CmsFeedSEOResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  feedDescription: Scalars['String']['output'];
  feedImage?: Maybe<Scalars['String']['output']>;
  feedKeyword?: Maybe<Scalars['String']['output']>;
  feedTitle: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  status: StatusType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};
export type CmsGetActivityByIdRequest = {
  id: Scalars['Int']['input'];
};
export type CmsGetActivityRequest = {
  activityCategory?: InputMaybe<Array<ActivityCategory>>;
  status?: InputMaybe<Array<StatusType>>;
  title?: InputMaybe<Scalars['String']['input']>;
};
export type CmsGetAllBannerByPageRequest = {
  pageId: Scalars['Int']['input'];
};
export type CmsGetAllCollectionRequest = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<StatusType>>>;
};
export type CmsGetAllCreatorRequest = {
  /** search creator by title,slug, or id */
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<StatusType>>;
};
export type CmsGetAllIngredientRequest = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<StatusType>>;
};
export type CmsGetAllRecipeRequest = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<StatusType>>;
};
export type CmsGetAllTiktokVideoResponse = {
  __typename?: 'CmsGetAllTiktokVideoResponse';
  tiktokVideoList?: Maybe<Array<Maybe<CmsTiktokVideoWithTiktokHashtagResponse>>>;
  total: Scalars['Int']['output'];
};
export type CmsGetAllTipsTricksRequest = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<StatusType>>;
};
export type CmsGetAllTrendsForYouResponse = {
  __typename?: 'CmsGetAllTrendsForYouResponse';
  total: Scalars['Float']['output'];
  trendsForYouList?: Maybe<Array<CmsTrendsForYouResponse>>;
};
export type CmsGetAllUnitRequest = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<StatusType>>;
};
export type CmsGetBannerListRequest = {
  bannerType?: InputMaybe<Array<BannerType>>;
  pageIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<Array<StatusType>>;
};
export type CmsGetBrandListRequest = {
  keyword?: InputMaybe<Scalars['String']['input']>;
};
export type CmsGetCpConnectByIdRequest = {
  /** use cp connect id */
  id: Scalars['Int']['input'];
};
export type CmsGetCpConnectListRequest = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<StatusType>>>;
};
export type CmsGetCampaignByIdRequest = {
  /** use campaign id */
  id: Scalars['Int']['input'];
};
export type CmsGetCampaignListRequest = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<StatusType>>>;
};
export type CmsGetCollectionByIdRequest = {
  /** collection id */
  id: Scalars['Int']['input'];
};
export type CmsGetFeedSeObyIdRequest = {
  /** feed seo id */
  id: Scalars['Int']['input'];
};
export type CmsGetGroupCategoryByIdRequest = {
  id: Scalars['Int']['input'];
};
export type CmsGetGroupCategoryListRequest = {
  groupCateIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<StatusType>>;
};
export type CmsGetIngredientByIdRequest = {
  /** ingredient id */
  ingredientId: Scalars['Int']['input'];
};
export type CmsGetPopularBrandByIdRequest = {
  /** use id in brand list */
  id: Scalars['Int']['input'];
};
export type CmsGetPopularBrandListRequest = {
  status?: InputMaybe<Array<StatusType>>;
  /** use name for search popular brand */
  title?: InputMaybe<Scalars['String']['input']>;
};
export type CmsGetPopupDetailByIdRequest = {
  id: Scalars['Int']['input'];
};
export type CmsGetPopupListRequest = {
  statusList?: InputMaybe<Array<StatusType>>;
};
export type CmsGetProductPinRequest = {
  id: Scalars['Int']['input'];
};
export type CmsGetProductRequest = {
  keyword?: InputMaybe<Scalars['String']['input']>;
};
export type CmsGetRecipeByIdRequest = {
  /** param recipe id */
  id: Scalars['Float']['input'];
};
export type CmsGetRecipeRequest = {
  keyword?: InputMaybe<Scalars['String']['input']>;
};
export type CmsGetRegisterBannerListRequest = {
  bannerTypes?: InputMaybe<Array<RegisterBannerType>>;
  statusTypes?: InputMaybe<Array<StatusType>>;
};
export type CmsGetSeoListRequest = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  statusList?: InputMaybe<Array<StatusType>>;
};
export type CmsGetTiktokHashtagByIdRequest = {
  /** tiktok hashtag id */
  id: Scalars['Int']['input'];
};
export type CmsGetTiktokHashtagListRequest = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<StatusType>>>;
};
export type CmsGetTiktokHashtagListResponse = {
  __typename?: 'CmsGetTiktokHashtagListResponse';
  tikTokHashtags: Array<TiktokHashtagResponse>;
  total: Scalars['Float']['output'];
};
export type CmsGetTiktokVideoByIdRequest = {
  id: Scalars['Int']['input'];
};
export type CmsGetTiktokVideoListRequest = {
  status?: InputMaybe<Array<InputMaybe<StatusType>>>;
  tikTokHashtagId?: InputMaybe<Scalars['Int']['input']>;
};
export type CmsGetTipsTricksByIdRequest = {
  id: Scalars['Int']['input'];
};
export type CmsGetTrendsForYouListRequestDto = {
  hashtagName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<StatusType>>;
};
export type CmsGetUnitByIdRequest = {
  /** unit id */
  unitId: Scalars['Int']['input'];
};
export type CmsPageBannerRequest = {
  endedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pageId: Scalars['Int']['input'];
  position: Array<PositionType>;
  startedAt: Scalars['DateTime']['input'];
};
export type CmsPopupResponseListWithPaginationResponse = {
  __typename?: 'CmsPopupResponseListWithPaginationResponse';
  popupResponse?: Maybe<Array<PopupResponse>>;
  total: Scalars['Int']['output'];
};
export type CmsRecipeRelateIngredientRequest = {
  ingredientId: Scalars['Int']['input'];
  ingredientType: IngredientType;
  priority: Scalars['Int']['input'];
  quantity: Scalars['String']['input'];
  unit: Scalars['String']['input'];
};
export type CmsRecipeRelateProductRequest = {
  linkOutURL?: InputMaybe<Scalars['String']['input']>;
  priority: Scalars['Int']['input'];
  productId: Scalars['Int']['input'];
  quantity: Scalars['String']['input'];
  unit: Scalars['String']['input'];
};
export type CmsSaveActivityRequest = {
  activityCategory: ActivityCategory;
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['String']['input'];
  detail?: InputMaybe<Scalars['String']['input']>;
  endedAt: Scalars['DateTime']['input'];
  expiredAt?: InputMaybe<Scalars['DateTime']['input']>;
  feedDescription?: InputMaybe<Scalars['String']['input']>;
  feedKeyword?: InputMaybe<Scalars['String']['input']>;
  feedTitle?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  /** activity/promotion cover image */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  ogImage?: InputMaybe<Scalars['String']['input']>;
  publishedAt: Scalars['DateTime']['input'];
  slugUrl: Scalars['String']['input'];
  startedAt: Scalars['DateTime']['input'];
  status: StatusType;
  thumbUrl?: Scalars['String']['input'];
  title: Scalars['String']['input'];
};
export type CmsSaveBannerRequest = {
  bannerDesktop: Scalars['String']['input'];
  /** use for show game banner video for iOS device */
  bannerIos?: InputMaybe<Scalars['String']['input']>;
  bannerMobile: Scalars['String']['input'];
  bannerType: BannerType;
  /** send bannerId for update value */
  id?: InputMaybe<Scalars['Int']['input']>;
  linkOutUrl?: InputMaybe<Scalars['String']['input']>;
  pages?: InputMaybe<Array<CmsPageBannerRequest>>;
  path?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};
export type CmsSaveCpConnectRequest = {
  description?: InputMaybe<Scalars['String']['input']>;
  /** use id when update cp connect */
  id?: InputMaybe<Scalars['Int']['input']>;
  imageLogoUrl: Scalars['String']['input'];
  linkOutUrl: Scalars['String']['input'];
  mainCoverUrl: Scalars['String']['input'];
  priority?: Scalars['Int']['input'];
  publishedAt: Scalars['DateTime']['input'];
  status: StatusType;
  title: Scalars['String']['input'];
};
export type CmsSaveCampaignRequest = {
  description?: InputMaybe<Scalars['String']['input']>;
  endedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** send campaign id for update value */
  id?: InputMaybe<Scalars['Int']['input']>;
  linkoutUrl: Scalars['String']['input'];
  priority?: Scalars['Int']['input'];
  publishedAt: Scalars['DateTime']['input'];
  status: StatusType;
  thumbUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
};
export type CmsSaveCollectionRequest = {
  bgCover?: InputMaybe<Scalars['String']['input']>;
  colorMenuPopular?: InputMaybe<Scalars['String']['input']>;
  colorTitlePopular?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Send id for update */
  id?: InputMaybe<Scalars['Int']['input']>;
  isPopular?: Scalars['Boolean']['input'];
  pin?: InputMaybe<Scalars['Int']['input']>;
  priority?: Scalars['Int']['input'];
  /** recipe id that relate with collection */
  recipeIds: Array<Scalars['Int']['input']>;
  slugUrl: Scalars['String']['input'];
  status: StatusType;
  thumbPopular?: InputMaybe<Scalars['String']['input']>;
  thumbUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
  video?: InputMaybe<Scalars['String']['input']>;
  youtubeUrl?: InputMaybe<Scalars['String']['input']>;
};
export type CmsSaveDraftCollectionRequest = {
  bgCover?: InputMaybe<Scalars['String']['input']>;
  colorMenuPopular?: InputMaybe<Scalars['String']['input']>;
  colorTitlePopular?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Send id for update */
  id?: InputMaybe<Scalars['Int']['input']>;
  isPopular?: Scalars['Boolean']['input'];
  pin?: InputMaybe<Scalars['Int']['input']>;
  priority?: Scalars['Int']['input'];
  /** recipe id that relate with collection */
  recipeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  slugUrl: Scalars['String']['input'];
  /** Must be draft status */
  status: StatusType;
  thumbPopular?: InputMaybe<Scalars['String']['input']>;
  thumbUrl?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  video?: InputMaybe<Scalars['String']['input']>;
  youtubeUrl?: InputMaybe<Scalars['String']['input']>;
};
export type CmsSaveDraftTipsTricksRequest = {
  category?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  endedAt?: InputMaybe<Scalars['DateTime']['input']>;
  feedDescription?: InputMaybe<Scalars['String']['input']>;
  feedKeyword?: InputMaybe<Scalars['String']['input']>;
  feedTitle?: InputMaybe<Scalars['String']['input']>;
  /** use id for update tipstricks */
  id?: InputMaybe<Scalars['Int']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isPin?: InputMaybe<Scalars['Boolean']['input']>;
  ogImage?: InputMaybe<Scalars['String']['input']>;
  priority?: Scalars['Int']['input'];
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  slug: Scalars['String']['input'];
  /** must be status draft only */
  status: StatusType;
  thumbUrl?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  youtubeUrl?: InputMaybe<Scalars['String']['input']>;
};
export type CmsSaveFeedSeoRequest = {
  feedDescription: Scalars['String']['input'];
  feedImage?: InputMaybe<Scalars['String']['input']>;
  feedKeyword?: InputMaybe<Scalars['String']['input']>;
  feedTitle: Scalars['String']['input'];
  /** Send Id for update seo */
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  status: StatusType;
  url: Scalars['String']['input'];
};
export type CmsSaveIngredientRequest = {
  /** Send id if want to update ingredient */
  id?: InputMaybe<Scalars['Int']['input']>;
  status: StatusType;
  title: Scalars['String']['input'];
};
export type CmsSaveMainGroupCategoryRequest = {
  bgCoverUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Send id for update */
  id?: InputMaybe<Scalars['Int']['input']>;
  imageIconUrl?: InputMaybe<Scalars['String']['input']>;
  interestingCoverUrl?: InputMaybe<Scalars['String']['input']>;
  isPin?: Scalars['Boolean']['input'];
  mainColor?: InputMaybe<Scalars['String']['input']>;
  mainCoverMobileUrl?: InputMaybe<Scalars['String']['input']>;
  mainCoverUrl?: InputMaybe<Scalars['String']['input']>;
  priority?: Scalars['Int']['input'];
  slugUrl: Scalars['String']['input'];
  status: StatusType;
  subMainColor?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  tooltipText?: InputMaybe<Scalars['String']['input']>;
  trainTitleColor?: InputMaybe<Scalars['String']['input']>;
};
export type CmsSavePopularBrandRequest = {
  brandId: Scalars['Int']['input'];
  coverImg: Scalars['String']['input'];
  /** send id for update value */
  id?: InputMaybe<Scalars['Int']['input']>;
  linkOutUrl?: InputMaybe<Scalars['String']['input']>;
  priority?: Scalars['Int']['input'];
  status: StatusType;
  title: Scalars['String']['input'];
};
export type CmsSavePopupRequest = {
  endedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Send Id for update popup */
  id?: InputMaybe<Scalars['Int']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  linkOutUrl?: InputMaybe<Scalars['String']['input']>;
  popupType: PopupType;
  priority: Scalars['Int']['input'];
  startedAt: Scalars['DateTime']['input'];
  status: StatusType;
  title: Scalars['String']['input'];
  youtubeUrl?: InputMaybe<Scalars['String']['input']>;
};
export type CmsSaveProductPinRequest = {
  id?: InputMaybe<Scalars['Int']['input']>;
  priority: Scalars['Int']['input'];
  productId: Scalars['Int']['input'];
};
export type CmsSaveRecipeRequest = {
  categoryIds: Array<Scalars['Int']['input']>;
  collectionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  cooking?: InputMaybe<Scalars['String']['input']>;
  coverImageUrl?: InputMaybe<Scalars['String']['input']>;
  creatorId: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  feedDescription?: InputMaybe<Scalars['String']['input']>;
  feedKeyword?: InputMaybe<Scalars['String']['input']>;
  feedTitle?: InputMaybe<Scalars['String']['input']>;
  groupCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  hackId: Scalars['Int']['input'];
  /** Send Id for update recipe */
  id?: InputMaybe<Scalars['Int']['input']>;
  ingredientImage?: InputMaybe<Scalars['String']['input']>;
  ingredients?: InputMaybe<Array<InputMaybe<CmsRecipeRelateIngredientRequest>>>;
  levelId: Scalars['Int']['input'];
  meatCategoryIds: Array<Scalars['Int']['input']>;
  methodId: Scalars['Int']['input'];
  ogImage: Scalars['String']['input'];
  people: Scalars['Int']['input'];
  priority: Scalars['Int']['input'];
  products?: InputMaybe<Array<InputMaybe<CmsRecipeRelateProductRequest>>>;
  regionId: Scalars['Int']['input'];
  slugUrl: Scalars['String']['input'];
  status: StatusType;
  thumbUrl: Scalars['String']['input'];
  time: Scalars['String']['input'];
  title: Scalars['String']['input'];
  toPeople?: InputMaybe<Scalars['Int']['input']>;
  typeId: Scalars['Int']['input'];
  youtubeUrl?: InputMaybe<Scalars['String']['input']>;
};
export type CmsSaveRegisterBannerRequest = {
  bannerDesktop: Scalars['String']['input'];
  bannerMobile: Scalars['String']['input'];
  /** use regis banner id */
  id?: InputMaybe<Scalars['Int']['input']>;
  linkOutUrl?: InputMaybe<Scalars['String']['input']>;
  registerBannerType: RegisterBannerType;
  status: StatusType;
  title: Scalars['String']['input'];
};
export type CmsSaveSubGroupCategoryRequest = {
  bgCoverUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Send id for update */
  id?: InputMaybe<Scalars['Int']['input']>;
  imageIconUrl?: InputMaybe<Scalars['String']['input']>;
  interestingCoverUrl?: InputMaybe<Scalars['String']['input']>;
  isPin?: Scalars['Boolean']['input'];
  mainColor?: InputMaybe<Scalars['String']['input']>;
  mainCoverMobileUrl?: InputMaybe<Scalars['String']['input']>;
  mainCoverUrl?: InputMaybe<Scalars['String']['input']>;
  /** groupCategory id for parentId */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  priority?: Scalars['Int']['input'];
  /** recipe id that relate with groupCategory */
  recipeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  slugUrl?: InputMaybe<Scalars['String']['input']>;
  status: StatusType;
  subMainColor?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  tooltipText?: InputMaybe<Scalars['String']['input']>;
  trainTitleColor?: InputMaybe<Scalars['String']['input']>;
};
export type CmsSaveTiktokHashtagRequest = {
  hashtag: Scalars['String']['input'];
  /** send hashtag id for update value */
  id?: InputMaybe<Scalars['Int']['input']>;
  priority: Scalars['Int']['input'];
  status: StatusType;
  title: Scalars['String']['input'];
};
export type CmsSaveTiktokVideoRequest = {
  id?: InputMaybe<Scalars['Int']['input']>;
  status: StatusType;
  thumbUrl: Scalars['String']['input'];
  tikTokCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  tikTokDescription?: InputMaybe<Scalars['String']['input']>;
  tikTokDurationVideo?: InputMaybe<Scalars['Int']['input']>;
  tikTokHashtagIds: Array<Scalars['Int']['input']>;
  tikTokShareCount?: InputMaybe<Scalars['Int']['input']>;
  tikTokThumbnail?: InputMaybe<Scalars['String']['input']>;
  tikTokVideoId: Scalars['String']['input'];
  tikTokVideoUrl: Scalars['String']['input'];
  tikTokViewCount: Scalars['Int']['input'];
};
export type CmsSaveTipsTricksRequest = {
  category: Array<Scalars['Int']['input']>;
  creatorId: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  detail: Scalars['String']['input'];
  endedAt?: InputMaybe<Scalars['DateTime']['input']>;
  feedDescription?: InputMaybe<Scalars['String']['input']>;
  feedKeyword?: InputMaybe<Scalars['String']['input']>;
  feedTitle?: InputMaybe<Scalars['String']['input']>;
  /** use id for update tipstricks */
  id?: InputMaybe<Scalars['Int']['input']>;
  imageUrl: Scalars['String']['input'];
  isPin?: Scalars['Boolean']['input'];
  ogImage?: InputMaybe<Scalars['String']['input']>;
  priority?: Scalars['Int']['input'];
  publishedAt: Scalars['DateTime']['input'];
  slug: Scalars['String']['input'];
  status: StatusType;
  thumbUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
  youtubeUrl?: InputMaybe<Scalars['String']['input']>;
};
export type CmsSaveUnitRequest = {
  /** Send id if want to update unit */
  id?: InputMaybe<Scalars['Int']['input']>;
  status: StatusType;
  title: Scalars['String']['input'];
};
export type CmsTiktokVideoWithTiktokHashtagResponse = {
  __typename?: 'CmsTiktokVideoWithTiktokHashtagResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  status: StatusType;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  tikTokCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  tikTokDescription?: Maybe<Scalars['String']['output']>;
  tikTokDurationVideo?: Maybe<Scalars['Float']['output']>;
  tikTokEmbedVideo?: Maybe<Scalars['String']['output']>;
  tikTokHashtags: Array<TiktokHashtagResponse>;
  tikTokLikesCount?: Maybe<Scalars['Float']['output']>;
  tikTokShareCount?: Maybe<Scalars['Float']['output']>;
  tikTokThumbUrl?: Maybe<Scalars['String']['output']>;
  tikTokVideoId: Scalars['String']['output'];
  tikTokVideoUrl: Scalars['String']['output'];
  tikTokViewCount: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type CmsTrendsForYouResponse = {
  __typename?: 'CmsTrendsForYouResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  hashtag: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  instagramUrl?: Maybe<Scalars['String']['output']>;
  priority: Scalars['Int']['output'];
  publishedAt: Scalars['DateTime']['output'];
  status: StatusType;
  tiktokUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  xTwitterUrl?: Maybe<Scalars['String']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};
export type CmsUpdateBannerPriorityRequest = {
  relateBanners: Array<BannerRelateRequest>;
};
export type CmsUpdateGroupCategoryPriorityRequest = {
  trainGroupCategories: Array<GroupCategoryPriorityRequest>;
};
export type CollectionDetailResponse = {
  __typename?: 'CollectionDetailResponse';
  bgCover?: Maybe<Scalars['String']['output']>;
  colorMenuPopular?: Maybe<Scalars['String']['output']>;
  colorTitlePopular?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isPopular: Scalars['Boolean']['output'];
  pin?: Maybe<Scalars['Float']['output']>;
  priority: Scalars['Float']['output'];
  recipeList?: Maybe<Array<Maybe<RecipeRelateProductResponse>>>;
  recipeListTotal?: Maybe<Scalars['Float']['output']>;
  slugUrl: Scalars['String']['output'];
  status: StatusType;
  thumbPopular?: Maybe<Scalars['String']['output']>;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};
export type CollectionResponse = {
  __typename?: 'CollectionResponse';
  bgCover?: Maybe<Scalars['String']['output']>;
  colorMenuPopular?: Maybe<Scalars['String']['output']>;
  colorTitlePopular?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isPopular: Scalars['Boolean']['output'];
  pin?: Maybe<Scalars['Float']['output']>;
  priority: Scalars['Float']['output'];
  recipeListTotal?: Maybe<Scalars['Float']['output']>;
  slugUrl: Scalars['String']['output'];
  status: StatusType;
  thumbPopular?: Maybe<Scalars['String']['output']>;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};
export type CollectionWithRecipeResponse = {
  __typename?: 'CollectionWithRecipeResponse';
  bgCover?: Maybe<Scalars['String']['output']>;
  colorMenuPopular?: Maybe<Scalars['String']['output']>;
  colorTitlePopular?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isPopular: Scalars['Boolean']['output'];
  pin?: Maybe<Scalars['Float']['output']>;
  priority: Scalars['Float']['output'];
  recipeList?: Maybe<Array<Maybe<RecipeResponse>>>;
  recipeListTotal?: Maybe<Scalars['Float']['output']>;
  slugUrl: Scalars['String']['output'];
  status: StatusType;
  thumbPopular?: Maybe<Scalars['String']['output']>;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};
export type CpConnectResponse = {
  __typename?: 'CpConnectResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  imageLogoUrl?: Maybe<Scalars['String']['output']>;
  linkOutUrl: Scalars['String']['output'];
  mainCoverUrl?: Maybe<Scalars['String']['output']>;
  priority: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type CreatorResponse = {
  __typename?: 'CreatorResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slugUrl: Scalars['String']['output'];
  status: StatusType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type DataMemberFavoriteResponse = {
  __typename?: 'DataMemberFavoriteResponse';
  collections?: Maybe<Array<CollectionResponse>>;
  products?: Maybe<Array<ProductResponse>>;
  recipes?: Maybe<Array<RecipeRelateResponse>>;
};
export type DataSearchResponse = {
  __typename?: 'DataSearchResponse';
  products?: Maybe<Array<ProductResponse>>;
  recipes?: Maybe<Array<RecipeRelateResponse>>;
  tipsTricks?: Maybe<Array<TipsTricksWithCategoryResponse>>;
};
export type DeleteAdminRequest = {
  /** use admin id from profile login */
  adminId: Scalars['Int']['input'];
  /** use id from admin list for delete */
  id: Scalars['Int']['input'];
};
export enum FavoriteType {
  Collection = 'COLLECTION',
  Product = 'PRODUCT',
  Recipe = 'RECIPE',
}
export type FeedSeoResponse = {
  __typename?: 'FeedSEOResponse';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};
export type FilterActivityResponse = {
  __typename?: 'FilterActivityResponse';
  label: Scalars['Int']['output'];
  options: Array<OptionMonth>;
};
export type FoodTypeResponse = {
  __typename?: 'FoodTypeResponse';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};
export type GetActivityDetailRequest = {
  /** param slug or id */
  slug: Scalars['String']['input'];
};
export type GetActivityListRequest = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
};
export type GetAdminByIdRequest = {
  /** use admin id */
  adminId: Scalars['Int']['input'];
};
export type GetAdminListResponse = {
  __typename?: 'GetAdminListResponse';
  admins?: Maybe<Array<Maybe<AdminResponse>>>;
  total: Scalars['Float']['output'];
};
export type GetAllActivityResponse = {
  __typename?: 'GetAllActivityResponse';
  activities?: Maybe<Array<ActivityResponse>>;
  total: Scalars['Int']['output'];
};
export type GetAllBannerByPageResponse = {
  __typename?: 'GetAllBannerByPageResponse';
  bottom?: Maybe<Array<BannerResponse>>;
  highlight: Array<BannerResponse>;
};
export type GetAllBannerWithRelateResponse = {
  __typename?: 'GetAllBannerWithRelateResponse';
  bottom?: Maybe<Array<Maybe<BannerRelateWithPageResponse>>>;
  highlight: Array<BannerRelateWithPageResponse>;
};
export type GetAllBrandPopularResponse = {
  __typename?: 'GetAllBrandPopularResponse';
  popularBrands?: Maybe<Array<Maybe<BrandPopularResponse>>>;
  total: Scalars['Int']['output'];
};
export type GetAllCollectionRequest = {
  /** search by title,slug and id */
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<StatusType>>;
};
export type GetAllCollectionResponse = {
  __typename?: 'GetAllCollectionResponse';
  collections?: Maybe<Array<Maybe<CollectionWithRecipeResponse>>>;
  total: Scalars['Float']['output'];
};
export type GetAllCollectionWithoutRelateResponse = {
  __typename?: 'GetAllCollectionWithoutRelateResponse';
  collections?: Maybe<Array<Maybe<CollectionResponse>>>;
  total: Scalars['Float']['output'];
};
export type GetAllCreatorResponse = {
  __typename?: 'GetAllCreatorResponse';
  creators?: Maybe<Array<CreatorResponse>>;
  total: Scalars['Float']['output'];
};
export type GetAllFavoriteCollectionAmountResponse = {
  __typename?: 'GetAllFavoriteCollectionAmountResponse';
  collectionId: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};
export type GetAllFavoriteProductAmountResponse = {
  __typename?: 'GetAllFavoriteProductAmountResponse';
  count: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
};
export type GetAllFavoriteRecipeAmountResponse = {
  __typename?: 'GetAllFavoriteRecipeAmountResponse';
  count: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  recipeId: Scalars['Int']['output'];
};
export type GetAllFoodTypeRequest = {
  status?: InputMaybe<Array<StatusType>>;
};
export type GetAllGroupCategoryResponse = {
  __typename?: 'GetAllGroupCategoryResponse';
  groupCategory?: Maybe<Array<GroupCategoryWithParentResponse>>;
  total: Scalars['Float']['output'];
};
export type GetAllIngredientsResponse = {
  __typename?: 'GetAllIngredientsResponse';
  ingredients?: Maybe<Array<IngredientsResponse>>;
  total: Scalars['Float']['output'];
};
export type GetAllProductByMeatCategoryRequest = {
  /** insert meat category id */
  meatCategoryId?: InputMaybe<Scalars['Int']['input']>;
};
export type GetAllProductResponse = {
  __typename?: 'GetAllProductResponse';
  products: Array<ProductResponse>;
  total?: Maybe<Scalars['Int']['output']>;
};
export type GetAllPromotionResponse = {
  __typename?: 'GetAllPromotionResponse';
  promotions?: Maybe<Array<PromotionResponse>>;
  total: Scalars['Int']['output'];
};
export type GetAllRecipeCategoryRequest = {
  status?: InputMaybe<StatusType>;
};
export type GetAllRecipeRelateResponse = {
  __typename?: 'GetAllRecipeRelateResponse';
  recipes: Array<RecipeRelateResponse>;
  total: Scalars['Int']['output'];
};
export type GetAllRecipeRequest = {
  /** search by title,slug, or id */
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<StatusType>>;
};
export type GetAllRecipeResponse = {
  __typename?: 'GetAllRecipeResponse';
  recipes?: Maybe<Array<RecipeResponse>>;
  total: Scalars['Float']['output'];
};
export type GetAllUnitRequest = {
  status?: InputMaybe<Array<StatusType>>;
  title?: InputMaybe<Scalars['String']['input']>;
};
export type GetAllUnitResponse = {
  __typename?: 'GetAllUnitResponse';
  total: Scalars['Float']['output'];
  units?: Maybe<Array<UnitResponse>>;
};
export type GetBannerByPageRequest = {
  /** use slug url */
  page: Scalars['String']['input'];
};
export type GetChildrenGroupCategoryRequest = {
  /** group category`s id */
  id: Scalars['Int']['input'];
};
export type GetCollectionDetailRequest = {
  /** use slug url */
  slugUrl: Scalars['String']['input'];
};
export type GetCpConnectRequest = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};
export type GetFeedSeObyPathRequest = {
  path?: InputMaybe<Scalars['String']['input']>;
};
export type GetGroupCategoryDetailRequest = {
  /** use slug url */
  slugUrl: Scalars['String']['input'];
};
export type GetGroupCategoryRelateRecipeByIdRequest = {
  /** use group category's id */
  id: Scalars['Int']['input'];
};
export type GetGroupCategoryRequest = {
  /** group category`s slug url */
  slug: Scalars['String']['input'];
};
export type GetLatestActivityRequest = {
  id: Scalars['Int']['input'];
};
export type GetMemberFavoriteCollectionRequest = {
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};
export type GetMemberFavoriteProductRequest = {
  productIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};
export type GetMemberFavoriteRecipeRequest = {
  recipeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};
export type GetMemberFavoriteResultRequest = {
  /** enum tab page for query  */
  tab: TabNameForMemberFavoriteResult;
};
export type GetMemberInterestingResultRequest = {
  /** enum tab page for query  */
  tab: InterestingType;
};
export type GetMemberSubscribeMenuByRecipeIdRequest = {
  /** param recipe id */
  recipeId: Scalars['Float']['input'];
};
export type GetPopularSearchAutoSuggestionRequest = {
  keyword?: InputMaybe<Scalars['String']['input']>;
};
export type GetProductByBrandIdRequest = {
  /** brand id of product */
  brandId: Scalars['Int']['input'];
};
export type GetProductByGroupCategoryIdRequest = {
  /** group category id of product */
  groupCategoryId: Scalars['Int']['input'];
};
export type GetProductDetailResponse = {
  __typename?: 'GetProductDetailResponse';
  brandImg?: Maybe<Scalars['String']['output']>;
  categoryIds?: Maybe<Array<Scalars['Int']['output']>>;
  cooking?: Maybe<Scalars['String']['output']>;
  cpKnowCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  feedDescription?: Maybe<Scalars['String']['output']>;
  feedKeyword?: Maybe<Scalars['String']['output']>;
  feedTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  meatCategories?: Maybe<Array<MeatCategoryResponse>>;
  meatCategoryIds?: Maybe<Array<Scalars['Int']['output']>>;
  nutrition?: Maybe<Scalars['String']['output']>;
  ogImage?: Maybe<Scalars['String']['output']>;
  preserve?: Maybe<Scalars['String']['output']>;
  productCategories?: Maybe<Array<ProductCategoryResponse>>;
  productDescription?: Maybe<ProductDescription>;
  productIngredients?: Maybe<Scalars['String']['output']>;
  recipe?: Maybe<Array<RecipeRelateResponse>>;
  shops?: Maybe<Array<Shops>>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  slugUrl?: Maybe<Scalars['String']['output']>;
  status?: Maybe<StatusType>;
  tag?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tagDetail?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weight?: Maybe<Scalars['String']['output']>;
};
export type GetProductPinRequest = {
  /** use page slug url to get product to show */
  page: Scalars['String']['input'];
};
export type GetProductRelateProductByInterestingRequest = {
  productId: Scalars['Int']['input'];
};
export type GetProductRelateProductRequest = {
  /** use product id */
  productId: Scalars['Int']['input'];
};
export type GetProductRequest = {
  /** use product's slug or id */
  slug: Scalars['String']['input'];
};
export type GetRecipeByGroupCategoryIdRequest = {
  /** use child group category`s id */
  id: Scalars['Int']['input'];
};
export type GetRecipeByHackIdRequest = {
  /** param hack id */
  hackId: Scalars['Float']['input'];
};
export type GetRecipeRelateRecipeCategoryRequest = {
  /** use recipe's id */
  recipeId: Scalars['Int']['input'];
};
export type GetRecipeRequest = {
  /** param slug or id Recipe */
  slug: Scalars['String']['input'];
};
export type GetRegisterBannerRequest = {
  /** use type of registerBannerType. This will get all if null */
  registerBannerType?: InputMaybe<RegisterBannerType>;
};
export type GetSearchResultRequest = {
  productCondition?: InputMaybe<ProductSearchCondition>;
  recipeCondition?: InputMaybe<RecipeSearchCondition>;
  /** enum tab page for query  */
  tab: TabNameForSearch;
  tipsTrickCondition?: InputMaybe<TipsTrickSearchCondition>;
  title?: InputMaybe<Scalars['String']['input']>;
};
export type GetSurpriseMenuResultRequest = {
  /** meat category id for query  */
  meatCategoryId?: InputMaybe<Scalars['Int']['input']>;
  /** recipe method id for query  */
  recipeMethodId?: InputMaybe<Scalars['Int']['input']>;
};
export type GetTiktokVideoByHashtagRequest = {
  /** use tiktok hashtag */
  tiktokHashtag: Scalars['String']['input'];
};
export type GetTipsTricksByCategoryIdRequest = {
  /** Use TipsTricksCategory ids as number[] */
  categoryId: Array<Scalars['Int']['input']>;
  tipsTrickId?: InputMaybe<Scalars['Int']['input']>;
};
export type GetTipsTricksDetailRequest = {
  /** use tipstricks slug or id */
  slug: Scalars['String']['input'];
};
export type GetUnitRequest = {
  /** use unit id */
  id: Scalars['Int']['input'];
};
export type GroupCategoryPriorityRequest = {
  /** groupCategory id */
  id: Scalars['Int']['input'];
  priority: Scalars['Int']['input'];
};
export type GroupCategoryResponse = {
  __typename?: 'GroupCategoryResponse';
  bgCoverUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageIconUrl?: Maybe<Scalars['String']['output']>;
  interestingCoverUrl?: Maybe<Scalars['String']['output']>;
  isPin: Scalars['Boolean']['output'];
  mainColor?: Maybe<Scalars['String']['output']>;
  mainCoverMobileUrl?: Maybe<Scalars['String']['output']>;
  mainCoverUrl?: Maybe<Scalars['String']['output']>;
  parentId: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  slugUrl?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  subMainColor?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tooltipText?: Maybe<Scalars['String']['output']>;
  trainTitleColor?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type GroupCategoryTrainResponse = {
  __typename?: 'GroupCategoryTrainResponse';
  bottomContent: Array<GroupCategoryResponse>;
  middleContent: Array<GroupCategoryResponse>;
  topContent: Array<GroupCategoryResponse>;
};
export type GroupCategoryWithChildrenResponse = {
  __typename?: 'GroupCategoryWithChildrenResponse';
  bgCoverUrl?: Maybe<Scalars['String']['output']>;
  children?: Maybe<Array<GroupCategoryResponse>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageIconUrl?: Maybe<Scalars['String']['output']>;
  interestingCoverUrl?: Maybe<Scalars['String']['output']>;
  isPin: Scalars['Boolean']['output'];
  mainColor?: Maybe<Scalars['String']['output']>;
  mainCoverMobileUrl?: Maybe<Scalars['String']['output']>;
  mainCoverUrl?: Maybe<Scalars['String']['output']>;
  parentId: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  slugUrl?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  subMainColor?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tooltipText?: Maybe<Scalars['String']['output']>;
  trainTitleColor?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type GroupCategoryWithParentResponse = {
  __typename?: 'GroupCategoryWithParentResponse';
  bgCoverUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageIconUrl?: Maybe<Scalars['String']['output']>;
  interestingCoverUrl?: Maybe<Scalars['String']['output']>;
  isPin: Scalars['Boolean']['output'];
  mainColor?: Maybe<Scalars['String']['output']>;
  mainCoverMobileUrl?: Maybe<Scalars['String']['output']>;
  mainCoverUrl?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<GroupCategoryResponse>;
  parentId: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  slugUrl?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  subMainColor?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tooltipText?: Maybe<Scalars['String']['output']>;
  trainTitleColor?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type GroupCategoryWithRecipeResponse = {
  __typename?: 'GroupCategoryWithRecipeResponse';
  bgCoverUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageIconUrl?: Maybe<Scalars['String']['output']>;
  interestingCoverUrl?: Maybe<Scalars['String']['output']>;
  isPin: Scalars['Boolean']['output'];
  mainColor?: Maybe<Scalars['String']['output']>;
  mainCoverMobileUrl?: Maybe<Scalars['String']['output']>;
  mainCoverUrl?: Maybe<Scalars['String']['output']>;
  parentId: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  recipeList?: Maybe<Array<Maybe<RecipeResponse>>>;
  slugUrl?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  subMainColor?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tooltipText?: Maybe<Scalars['String']['output']>;
  trainTitleColor?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export enum IngredientType {
  NonProduct = 'NonProduct',
  Other = 'Other',
  Product = 'Product',
}
export type IngredientsResponse = {
  __typename?: 'IngredientsResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export enum InterestingType {
  GroupCategory = 'GroupCategory',
  MeatCategory = 'MeatCategory',
  RecipeType = 'RecipeType',
}
export type LoginRequest = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};
export type MeatCategoryResponse = {
  __typename?: 'MeatCategoryResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  interestingCoverUrl?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  status?: Maybe<StatusType>;
  surpriseMenuImgUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type MeatCategoryWithChildrenResponse = {
  __typename?: 'MeatCategoryWithChildrenResponse';
  children?: Maybe<Array<Maybe<MeatCategoryResponse>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  interestingCoverUrl?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  status?: Maybe<StatusType>;
  surpriseMenuImgUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type MemberFavoriteResultResponse = {
  __typename?: 'MemberFavoriteResultResponse';
  collectionTotal: Scalars['Int']['output'];
  data?: Maybe<DataMemberFavoriteResponse>;
  productTotal: Scalars['Int']['output'];
  recipeTotal: Scalars['Int']['output'];
};
export type Mutation = {
  __typename?: 'Mutation';
  adminLogout: Scalars['Boolean']['output'];
  cdpLogin: CdpAuthResponse;
  cdpLogout: Scalars['Boolean']['output'];
  cdpRenewToken: CdpAuthResponse;
  cdpTopupTrueMoney: CdpTopupTrueMoneyResponse;
  cdpUpdateUserPoint: CdpUpdateUserPointResponse;
  cmsDelPopularBrand: Scalars['Boolean']['output'];
  cmsDeleteActivity: Scalars['Boolean']['output'];
  cmsDeleteBanner: Scalars['Boolean']['output'];
  cmsDeleteCampaign: Scalars['Boolean']['output'];
  cmsDeleteCollection: Scalars['Boolean']['output'];
  cmsDeleteCpConnect: Scalars['Boolean']['output'];
  /** delete groupCategory by id */
  cmsDeleteGroupCategory: Scalars['Boolean']['output'];
  /** soft delete ingredient */
  cmsDeleteIngredient: Scalars['Boolean']['output'];
  /** soft delete popup */
  cmsDeletePopup: Scalars['Boolean']['output'];
  /** delete product pin data */
  cmsDeleteProductPin: Scalars['Boolean']['output'];
  /** delete recipe by id */
  cmsDeleteRecipe: Scalars['Boolean']['output'];
  cmsDeleteRegisterBanner: Scalars['Boolean']['output'];
  /** delete seo in cms */
  cmsDeleteSEO: Scalars['Boolean']['output'];
  cmsDeleteTiktokHashtag: Scalars['Boolean']['output'];
  cmsDeleteTiktokVideo: Scalars['Boolean']['output'];
  /** delete tipstricks by id */
  cmsDeleteTipsTricks: Scalars['Boolean']['output'];
  cmsDeleteTrendsForYou: Scalars['Boolean']['output'];
  /** soft delete unit and hard delete unit conjunction */
  cmsDeleteUnit: Scalars['Boolean']['output'];
  cmsSaveActivity: Scalars['Boolean']['output'];
  cmsSaveBanner: Scalars['Boolean']['output'];
  cmsSaveCPConnect: Scalars['Boolean']['output'];
  cmsSaveCampaign: Scalars['Boolean']['output'];
  cmsSaveCollection: Scalars['Boolean']['output'];
  cmsSaveDraftActivity: Scalars['Boolean']['output'];
  cmsSaveDraftCollection: Scalars['Boolean']['output'];
  /** create and update recipe by status = Draft */
  cmsSaveDraftRecipe: Scalars['Boolean']['output'];
  /** create  tipstricks with draft status */
  cmsSaveDraftTipsTricks: Scalars['Boolean']['output'];
  /** create and update ingredient in cms */
  cmsSaveIngredient: Scalars['Boolean']['output'];
  /** save main groupCategory by id */
  cmsSaveMainGroupCategory: Scalars['Boolean']['output'];
  cmsSavePopularBrand: Scalars['Boolean']['output'];
  /** create and update popup in cms */
  cmsSavePopup: Scalars['Boolean']['output'];
  cmsSaveProductPin: Scalars['Boolean']['output'];
  /** create and update recipe in cms */
  cmsSaveRecipe: Scalars['Boolean']['output'];
  cmsSaveRegisterBanner: Scalars['Boolean']['output'];
  /** create and update seo in cms */
  cmsSaveSEO: Scalars['Boolean']['output'];
  /** save sub groupCategory by id */
  cmsSaveSubGroupCategory: Scalars['Boolean']['output'];
  cmsSaveTiktokHashtag: Scalars['Boolean']['output'];
  cmsSaveTiktokVideo: Scalars['Boolean']['output'];
  /** create and update tipstricks */
  cmsSaveTipsTricks: Scalars['Boolean']['output'];
  cmsSaveTrendsForYou: Scalars['Boolean']['output'];
  /** create and update unit in cms */
  cmsSaveUnit: Scalars['Boolean']['output'];
  cmsUpdateBannerPriority: Scalars['Boolean']['output'];
  cmsUpdateGroupCategoryPriority: Scalars['Boolean']['output'];
  deleteAdmin: Scalars['Boolean']['output'];
  resetPasswordAdmin: Scalars['Boolean']['output'];
  saveAdmin: Scalars['Boolean']['output'];
  updateMemberFavorite: UpdateMemberFavoriteResponse;
  updateMemberInteresting: Scalars['Boolean']['output'];
  updateShouldShowInterestingMember: Scalars['Boolean']['output'];
};
export type MutationCdpLoginArgs = {
  data: CdpLoginRequest;
};
export type MutationCdpTopupTrueMoneyArgs = {
  data: CdpTopupTrueMoneyRequest;
};
export type MutationCdpUpdateUserPointArgs = {
  data: CdpUpdateUserProfileRequest;
};
export type MutationCmsDelPopularBrandArgs = {
  data: CmsDelPopularBrandRequest;
};
export type MutationCmsDeleteActivityArgs = {
  id: Scalars['Float']['input'];
};
export type MutationCmsDeleteBannerArgs = {
  data: CmsDeleteBannerRequest;
};
export type MutationCmsDeleteCampaignArgs = {
  data: CmsDeleteCampaignRequest;
};
export type MutationCmsDeleteCollectionArgs = {
  data: CmsDeleteCollectionRequest;
};
export type MutationCmsDeleteCpConnectArgs = {
  data: CmsDeleteCpConnectRequest;
};
export type MutationCmsDeleteGroupCategoryArgs = {
  data: CmsDeleteGroupCategoryRequest;
};
export type MutationCmsDeleteIngredientArgs = {
  data: CmsDeleteIngredientRequest;
};
export type MutationCmsDeletePopupArgs = {
  data: CmsDeletePopupRequest;
};
export type MutationCmsDeleteProductPinArgs = {
  data: CmsDeleteProductPinRequest;
};
export type MutationCmsDeleteRecipeArgs = {
  data: CmsDeleteRecipeRequest;
};
export type MutationCmsDeleteRegisterBannerArgs = {
  data: CmsDeleteRegisterBannerRequest;
};
export type MutationCmsDeleteSeoArgs = {
  data: CmsDeleteFeedSeoRequest;
};
export type MutationCmsDeleteTiktokHashtagArgs = {
  data: CmsDeleteTiktokHashtagRequest;
};
export type MutationCmsDeleteTiktokVideoArgs = {
  data: CmsDeleteTiktokVideoRequest;
};
export type MutationCmsDeleteTipsTricksArgs = {
  data: CmsDeleteTipsTricksRequest;
};
export type MutationCmsDeleteTrendsForYouArgs = {
  data: CmsDeleteTrendsForYouRequest;
};
export type MutationCmsDeleteUnitArgs = {
  data: CmsDeleteUnitRequest;
};
export type MutationCmsSaveActivityArgs = {
  data: CmsSaveActivityRequest;
};
export type MutationCmsSaveBannerArgs = {
  data: CmsSaveBannerRequest;
};
export type MutationCmsSaveCpConnectArgs = {
  data: CmsSaveCpConnectRequest;
};
export type MutationCmsSaveCampaignArgs = {
  data: CmsSaveCampaignRequest;
};
export type MutationCmsSaveCollectionArgs = {
  data: CmsSaveCollectionRequest;
};
export type MutationCmsSaveDraftActivityArgs = {
  data: CmsDraftSaveActivityRequest;
};
export type MutationCmsSaveDraftCollectionArgs = {
  data: CmsSaveDraftCollectionRequest;
};
export type MutationCmsSaveDraftRecipeArgs = {
  data: CmsDraftRecipeRequest;
};
export type MutationCmsSaveDraftTipsTricksArgs = {
  data: CmsSaveDraftTipsTricksRequest;
};
export type MutationCmsSaveIngredientArgs = {
  data: CmsSaveIngredientRequest;
};
export type MutationCmsSaveMainGroupCategoryArgs = {
  data: CmsSaveMainGroupCategoryRequest;
};
export type MutationCmsSavePopularBrandArgs = {
  data: CmsSavePopularBrandRequest;
};
export type MutationCmsSavePopupArgs = {
  data: CmsSavePopupRequest;
};
export type MutationCmsSaveProductPinArgs = {
  data: CmsSaveProductPinRequest;
};
export type MutationCmsSaveRecipeArgs = {
  data: CmsSaveRecipeRequest;
};
export type MutationCmsSaveRegisterBannerArgs = {
  data: CmsSaveRegisterBannerRequest;
};
export type MutationCmsSaveSeoArgs = {
  data: CmsSaveFeedSeoRequest;
};
export type MutationCmsSaveSubGroupCategoryArgs = {
  data: CmsSaveSubGroupCategoryRequest;
};
export type MutationCmsSaveTiktokHashtagArgs = {
  data: CmsSaveTiktokHashtagRequest;
};
export type MutationCmsSaveTiktokVideoArgs = {
  data: CmsSaveTiktokVideoRequest;
};
export type MutationCmsSaveTipsTricksArgs = {
  data: CmsSaveTipsTricksRequest;
};
export type MutationCmsSaveTrendsForYouArgs = {
  data: CmsSaveTrendsForYouRequest;
};
export type MutationCmsSaveUnitArgs = {
  data: CmsSaveUnitRequest;
};
export type MutationCmsUpdateBannerPriorityArgs = {
  data: CmsUpdateBannerPriorityRequest;
};
export type MutationCmsUpdateGroupCategoryPriorityArgs = {
  data: CmsUpdateGroupCategoryPriorityRequest;
};
export type MutationDeleteAdminArgs = {
  data: DeleteAdminRequest;
};
export type MutationResetPasswordAdminArgs = {
  data: ResetPasswordAdminRequest;
};
export type MutationSaveAdminArgs = {
  data: SaveAdminRequest;
};
export type MutationUpdateMemberFavoriteArgs = {
  data: UpdateMemberFavoriteRequest;
};
export type MutationUpdateMemberInterestingArgs = {
  data: UpdateMemberInterestingRequest;
};
export type MutationUpdateShouldShowInterestingMemberArgs = {
  data: UpdateShouldShowInterestingRequest;
};
export type OptionMonth = {
  __typename?: 'OptionMonth';
  label: Scalars['String']['output'];
  value: Scalars['DateTime']['output'];
};
export type PageRelateBannerResponse = {
  __typename?: 'PageRelateBannerResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  position: PositionType;
  priority: Scalars['Float']['output'];
  startedAt: Scalars['DateTime']['output'];
  status: StatusType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type PageResponse = {
  __typename?: 'PageResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  slugUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type PaginationRequest = {
  /** The number of items to return. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** The number of items to skip. */
  offset?: InputMaybe<Scalars['Int']['input']>;
};
export enum PasswordType {
  ChangePassword = 'ChangePassword',
  ResetPassword = 'ResetPassword',
}
export type PopularCollectionResponse = {
  __typename?: 'PopularCollectionResponse';
  bgCover?: Maybe<Scalars['String']['output']>;
  colorMenuPopular?: Maybe<Scalars['String']['output']>;
  colorTitlePopular?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isPopular: Scalars['Boolean']['output'];
  pin?: Maybe<Scalars['Float']['output']>;
  priority: Scalars['Float']['output'];
  recipeList?: Maybe<Array<Maybe<RecipeResponse>>>;
  recipeListTotal?: Maybe<Scalars['Float']['output']>;
  slugUrl: Scalars['String']['output'];
  status: StatusType;
  thumbPopular?: Maybe<Scalars['String']['output']>;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};
export type PopularSearchAutoSuggestionResponse = {
  __typename?: 'PopularSearchAutoSuggestionResponse';
  products?: Maybe<Array<PopularSearchResponse>>;
  recipes?: Maybe<Array<PopularSearchResponse>>;
  tipsTricks?: Maybe<Array<PopularSearchResponse>>;
};
export type PopularSearchResponse = {
  __typename?: 'PopularSearchResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  feedTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  slugUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: PopularSearchType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};
export enum PopularSearchType {
  Product = 'Product',
  Recipe = 'Recipe',
  TipsTricks = 'TipsTricks',
}
export type PopupResponse = {
  __typename?: 'PopupResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  linkOutUrl?: Maybe<Scalars['String']['output']>;
  popupType: PopupType;
  priority: Scalars['Float']['output'];
  startedAt: Scalars['DateTime']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};
export enum PopupType {
  Image = 'Image',
  Youtube = 'Youtube',
}
export enum PositionType {
  All = 'All',
  Bottom = 'Bottom',
  Highlight = 'Highlight',
}
export type ProductCategoryResponse = {
  __typename?: 'ProductCategoryResponse';
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};
export type ProductDescription = {
  __typename?: 'ProductDescription';
  allergen?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  brandImg?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  fdaNumber?: Maybe<Scalars['String']['output']>;
  meatType?: Maybe<Scalars['String']['output']>;
  productExpiredDate?: Maybe<Scalars['String']['output']>;
  productStartDate?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['String']['output']>;
};
export type ProductPinWithProductDetailResponse = {
  __typename?: 'ProductPinWithProductDetailResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  page?: Maybe<PageResponse>;
  priority: Scalars['Int']['output'];
  product?: Maybe<ProductResponse>;
  productId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type ProductResponse = {
  __typename?: 'ProductResponse';
  brandImg?: Maybe<Scalars['String']['output']>;
  categoryIds?: Maybe<Array<Scalars['Int']['output']>>;
  cpKnowCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  meatCategoryIds?: Maybe<Array<Scalars['Int']['output']>>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  slugUrl?: Maybe<Scalars['String']['output']>;
  status?: Maybe<StatusType>;
  tag?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tagDetail?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weight?: Maybe<Scalars['String']['output']>;
};
export type ProductSearchCondition = {
  /** ช่องการจัดจำหน่าย */
  branchIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  brandIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  meatCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  productCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};
export type PromotionResponse = {
  __typename?: 'PromotionResponse';
  activityCategory?: Maybe<ActivityCategory>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  detail?: Maybe<Scalars['String']['output']>;
  endedAt: Scalars['DateTime']['output'];
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  feedDescription?: Maybe<Scalars['String']['output']>;
  feedKeyword?: Maybe<Scalars['String']['output']>;
  feedTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** boolean that check is activity duration has expired */
  isExpire?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  ogImage?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slugUrl?: Maybe<Scalars['String']['output']>;
  startedAt: Scalars['DateTime']['output'];
  status: StatusType;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type PromotionWithCreatorResponse = {
  __typename?: 'PromotionWithCreatorResponse';
  activityCategory?: Maybe<ActivityCategory>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<CreatorResponse>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  detail?: Maybe<Scalars['String']['output']>;
  endedAt: Scalars['DateTime']['output'];
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  feedDescription?: Maybe<Scalars['String']['output']>;
  feedKeyword?: Maybe<Scalars['String']['output']>;
  feedTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** boolean that check is activity duration has expired */
  isExpire?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  ogImage?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slugUrl?: Maybe<Scalars['String']['output']>;
  startedAt: Scalars['DateTime']['output'];
  status: StatusType;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type Query = {
  __typename?: 'Query';
  adminLogin: AuthResponse;
  cdpGetUserProfile?: Maybe<CdpProfileResponse>;
  cdpVerifyAccessToken: Scalars['Boolean']['output'];
  cmsGetActivityById: ActivityWithCreatorResponse;
  cmsGetActivityList?: Maybe<GetAllActivityResponse>;
  cmsGetAllBanner?: Maybe<BannerRelateWithPageResponseWithTotal>;
  cmsGetAllBannerByPage?: Maybe<GetAllBannerWithRelateResponse>;
  cmsGetAllCollection?: Maybe<GetAllCollectionWithoutRelateResponse>;
  /** Cms get all Creator. */
  cmsGetAllCreator?: Maybe<GetAllCreatorResponse>;
  /** get ingredient list for cms ingredient page */
  cmsGetAllIngredient?: Maybe<GetAllIngredientsResponse>;
  /** get GroupCategoryAll for filter */
  cmsGetAllMainGroupCategoryForFilter?: Maybe<Array<GroupCategoryResponse>>;
  cmsGetAllPage?: Maybe<Array<PageResponse>>;
  cmsGetAllProductPin?: Maybe<Array<Maybe<ProductPinWithProductDetailResponse>>>;
  cmsGetAllRecipe?: Maybe<GetAllRecipeResponse>;
  cmsGetAllRegisterBanner: Array<RegisterBannerResponse>;
  cmsGetAllTipsTricks?: Maybe<TipsTricksWithPaginationResponse>;
  cmsGetAllTrendsForYou?: Maybe<CmsGetAllTrendsForYouResponse>;
  /** get unit list for cms unit menu */
  cmsGetAllUnit?: Maybe<GetAllUnitResponse>;
  cmsGetBannerById: BannerRelateWithAllPageResponse;
  /** get brand list form CMPD */
  cmsGetBrandList?: Maybe<Array<BrandCmpdResponse>>;
  cmsGetCPConnectList: CmsCpConnectListResponse;
  cmsGetCampaignById: CampaignResponse;
  cmsGetCampaignList: CampaignListResponse;
  cmsGetCollection?: Maybe<Array<CollectionResponse>>;
  /** get collection detail with recipe list by collection id */
  cmsGetCollectionById: CollectionWithRecipeResponse;
  cmsGetCpConnectById: CpConnectResponse;
  /** Cms get creator for form recipe. */
  cmsGetCreator?: Maybe<Array<CreatorResponse>>;
  /** get group category for form cmsRecipe */
  cmsGetGroupCategory?: Maybe<Array<GroupCategoryWithChildrenResponse>>;
  /** get group category by id */
  cmsGetGroupCategoryById?: Maybe<GroupCategoryWithRecipeResponse>;
  /** get ingredient for form cmsRecipe */
  cmsGetIngredient?: Maybe<Array<IngredientsResponse>>;
  /** get ingredient for form cmsRecipe */
  cmsGetIngredientById?: Maybe<IngredientsResponse>;
  /** get group category list */
  cmsGetMainGroupCategoryList?: Maybe<GetAllGroupCategoryResponse>;
  /** get meat category for form cmsRecipe */
  cmsGetMeatCategory?: Maybe<Array<MeatCategoryWithChildrenResponse>>;
  cmsGetPopularBrandById?: Maybe<BrandPopularResponse>;
  cmsGetPopularBrandList?: Maybe<GetAllBrandPopularResponse>;
  cmsGetPopupDetailById?: Maybe<PopupResponse>;
  cmsGetPopupList?: Maybe<CmsPopupResponseListWithPaginationResponse>;
  /** get product for form cmsRecipe */
  cmsGetProduct?: Maybe<Array<ProductResponse>>;
  cmsGetProductPin: ProductPinWithProductDetailResponse;
  /** get recipe for form cmsCollection */
  cmsGetRecipe?: Maybe<Array<RecipeResponse>>;
  /** get recipe menu by id or slug */
  cmsGetRecipeById: RecipeRelateResponse;
  /** get recipe category for form cmsRecipe */
  cmsGetRecipeCategory?: Maybe<Array<RecipeCategoryWithChildrenResponse>>;
  /** get recipe hack for form recipe */
  cmsGetRecipeHack?: Maybe<Array<RecipeHackResponse>>;
  /** get recipe level for form recipe */
  cmsGetRecipeLevel?: Maybe<Array<RecipeLevelResponse>>;
  /** get recipe method for form cmsRecipe */
  cmsGetRecipeMethod?: Maybe<Array<RecipeMethodWithChildrenResponse>>;
  cmsGetRecipeRegion?: Maybe<Array<RecipeRegionWithChildrenResponse>>;
  /** get recipe type for form cmsRecipe */
  cmsGetRecipeType?: Maybe<Array<RecipeTypeWithChildrenResponse>>;
  cmsGetRegisterBannerById: RegisterBannerResponse;
  cmsGetSEOById?: Maybe<CmsFeedSeoResponse>;
  cmsGetSEOList?: Maybe<CmsAllFeedSeoResponse>;
  /** get group category list */
  cmsGetSubGroupCategoryList?: Maybe<GetAllGroupCategoryResponse>;
  cmsGetTiktokHashtagByid: TiktokHashtagResponse;
  cmsGetTiktokHashtagList: CmsGetTiktokHashtagListResponse;
  cmsGetTiktokVideoById: CmsTiktokVideoWithTiktokHashtagResponse;
  cmsGetTiktokVideoList?: Maybe<CmsGetAllTiktokVideoResponse>;
  cmsGetTipsTricksById: TipsTricksDetailWithRelateDetailResponse;
  /** use tipstricks category for cms form */
  cmsGetTipsTricksCategory?: Maybe<Array<Maybe<TipsTricksCategoryResponse>>>;
  /** get train group category */
  cmsGetTrainGroupCategoryList?: Maybe<Array<GroupCategoryResponse>>;
  cmsGetTrendsForYouById: CmsTrendsForYouResponse;
  /** get unit for form cmsRecipe */
  cmsGetUnit?: Maybe<Array<UnitResponse>>;
  /** get unit by id */
  cmsGetUnitById?: Maybe<UnitResponse>;
  getActivityDetail: ActivityWithCreatorResponse;
  getActivityFilter?: Maybe<Array<FilterActivityResponse>>;
  getActivityList?: Maybe<GetAllActivityResponse>;
  getAdminById: AdminResponse;
  getAdminList?: Maybe<GetAdminListResponse>;
  getAllBranchFilter?: Maybe<Array<BranchResponse>>;
  getAllBrand?: Maybe<Array<BrandResponse>>;
  getAllCollection: GetAllCollectionResponse;
  getAllFavoriteCollectionAmount?: Maybe<Array<Maybe<GetAllFavoriteCollectionAmountResponse>>>;
  getAllFavoriteProductAmount?: Maybe<Array<Maybe<GetAllFavoriteProductAmountResponse>>>;
  getAllFavoriteRecipeAmount?: Maybe<Array<Maybe<GetAllFavoriteRecipeAmountResponse>>>;
  /** Get all parent meat category to display in search filter */
  getAllFilterMeatCategory?: Maybe<Array<MeatCategoryResponse>>;
  /** get all recipe method parent to search filter */
  getAllFilterRecipeMethod?: Maybe<Array<RecipeMethodResponse>>;
  getAllFilterRecipeRegion?: Maybe<Array<RecipeRegionResponse>>;
  getAllFilterRecipeType?: Maybe<Array<RecipeTypeResponse>>;
  getAllFilterTipsTricksCategory?: Maybe<Array<TipsTricksCategoryResponse>>;
  getAllFoodType?: Maybe<Array<FoodTypeResponse>>;
  getAllGroupCategory?: Maybe<Array<GroupCategoryResponse>>;
  getAllMeatCategory?: Maybe<Array<MeatCategoryResponse>>;
  getAllProductByMeatCategory: GetAllProductResponse;
  getAllProductCategory?: Maybe<Array<ProductCategoryResponse>>;
  /** get all recipe */
  getAllRecipe?: Maybe<GetAllRecipeResponse>;
  getAllRecipeCategory?: Maybe<Array<RecipeCategoryResponse>>;
  getAllRecipeHack?: Maybe<Array<RecipeHackResponse>>;
  getAllTipsAndTricksHighlight: Array<TipsTricksResponse>;
  /** get all unit */
  getAllUnit?: Maybe<GetAllUnitResponse>;
  getBannerByPage: GetAllBannerByPageResponse;
  getChildrenGroupCategory: Array<GroupCategoryResponse>;
  getCmsRegisterBannerList: RegisterBannerListResponse;
  getCollectionDetail: CollectionDetailResponse;
  /** get CP connect */
  getCpConnect?: Maybe<Array<CpConnectResponse>>;
  getFeedSEOByPath?: Maybe<FeedSeoResponse>;
  getGroupCategoriesTrain: GroupCategoryTrainResponse;
  getGroupCategory: GroupCategoryResponse;
  getGroupCategoryDetail?: Maybe<GroupCategoryWithChildrenResponse>;
  getGroupCategoryMainInteresting: Array<GroupCategoryResponse>;
  getGroupCategoryRelateRecipeById?: Maybe<GroupCategoryWithRecipeResponse>;
  getHighlightCollection?: Maybe<Array<CollectionWithRecipeResponse>>;
  getLatestActivity?: Maybe<Array<ActivityResponse>>;
  getLatestCampaign?: Maybe<CampaignResponse>;
  getLatestPromotion?: Maybe<Array<PromotionResponse>>;
  /** Get all parent meat category fro interesting */
  getMeatCategoryInteresting?: Maybe<Array<MeatCategoryResponse>>;
  getMemberFavoriteCollection: Array<Scalars['Int']['output']>;
  getMemberFavoriteProduct: Array<Scalars['Int']['output']>;
  getMemberFavoriteRecipe: Array<Scalars['Int']['output']>;
  getMemberFavoriteResult?: Maybe<MemberFavoriteResultResponse>;
  getMemberInterestingResult?: Maybe<Array<Scalars['Float']['output']>>;
  /** get subscribe menu result */
  getMemberSubscribeMenuByRecipeId?: Maybe<Array<Scalars['String']['output']>>;
  getMenuCollection?: Maybe<Array<CollectionResponse>>;
  getPopularCollection?: Maybe<Array<PopularCollectionResponse>>;
  /** return top 10 most data that people have searched */
  getPopularSearch?: Maybe<Array<PopularSearchResponse>>;
  /** return top 3 data from recipe , product , tipsTricks that people have searched */
  getPopularSearchAutoSuggestion?: Maybe<PopularSearchAutoSuggestionResponse>;
  getPopup?: Maybe<PopupResponse>;
  getProduct?: Maybe<GetProductDetailResponse>;
  /** get product by brand id */
  getProductByBrandId?: Maybe<Array<ProductResponse>>;
  getProductByGroupCategoryId?: Maybe<Array<Maybe<ProductResponse>>>;
  /** get pin product by page slug url */
  getProductPin?: Maybe<Array<ProductPinWithProductDetailResponse>>;
  /** get product relate product by meat category id, show 20 items or less */
  getProductRelateProduct?: Maybe<Array<ProductResponse>>;
  /** get product relate product by interesting */
  getProductRelateProductByInteresting?: Maybe<Array<Maybe<ProductResponse>>>;
  getPromotionDetail: PromotionWithCreatorResponse;
  getPromotionEightItems?: Maybe<Array<PromotionResponse>>;
  getPromotionFilter?: Maybe<Array<FilterActivityResponse>>;
  getPromotionList?: Maybe<GetAllPromotionResponse>;
  /** get recipe menu by id or slug */
  getRecipe: RecipeRelateResponse;
  getRecipeByGroupCategoryId: GetAllRecipeRelateResponse;
  /** get recipe by hack id */
  getRecipeByHackId: Array<RecipeRelateResponse>;
  getRecipeRelateRecipe: Array<RecipeRelateRecipeResponse>;
  getRecipeRelateRecipeByMemberInteresting: Array<RecipeRelateRecipeResponse>;
  getRecipeTypeInteresting?: Maybe<Array<RecipeTypeResponse>>;
  getRecommendTipsTricks: Array<TipsTricksWithCategoryResponse>;
  /** Get max 24 recommendedProduct */
  getRecommendedProduct?: Maybe<Array<ProductResponse>>;
  getRegisterBanner: RegisterBannerResponse;
  getRegisterBannerList?: Maybe<Array<RegisterBannerResponse>>;
  /** get search result is array[Recipe[], Product[], TipsTricks[]] */
  getSearchResult: SearchResultResponse;
  /** get surprise menu result */
  getSurpriseMenuResult?: Maybe<Array<RecipeResponse>>;
  getTiktokHashtag: Array<TiktokHashtagResponse>;
  getTiktokVideoByHashtag: Array<TiktokVideoResponse>;
  getTipsTricksByCategoryId?: Maybe<TipsTricksWithPaginationResponse>;
  getTipsTricksCategory: Array<TipsTricksCategoryResponse>;
  getTipsTricksDetail: TipsTricksDetailWithRelateDetailResponse;
  getTipsTricksPopular: Array<TipsTricksWithCategoryResponse>;
  getTrendsForYou?: Maybe<Array<TrendsForYouResponse>>;
  getUnit?: Maybe<UnitResponse>;
  /** get limit 5 collection for what should i eat */
  getWhatShouldIEatCollections?: Maybe<Array<CollectionResponse>>;
  getWhatShouldIEatGroupCategories?: Maybe<Array<GroupCategoryResponse>>;
  /** Get meat category for what should i eat */
  getWhatShouldIEatMeatCategories?: Maybe<Array<MeatCategoryResponse>>;
  /** get all recipe method parent for what should i eat */
  getWhatShouldIEatRecipeMethods?: Maybe<Array<RecipeMethodResponse>>;
  /** Get all parent recipe type for what should i eat */
  getWhatShouldIEatRecipeTypes?: Maybe<Array<RecipeTypeResponse>>;
  renewToken: AuthResponse;
  testAccessTokenGuard: Scalars['String']['output'];
  testRefreshTokenGuard: Scalars['String']['output'];
  verifyAccessToken: Scalars['Boolean']['output'];
};
export type QueryAdminLoginArgs = {
  data: LoginRequest;
};
export type QueryCmsGetActivityByIdArgs = {
  data: CmsGetActivityByIdRequest;
};
export type QueryCmsGetActivityListArgs = {
  filter?: InputMaybe<CmsGetActivityRequest>;
  pagination?: InputMaybe<PaginationRequest>;
};
export type QueryCmsGetAllBannerArgs = {
  data?: InputMaybe<CmsGetBannerListRequest>;
  pagination?: InputMaybe<PaginationRequest>;
};
export type QueryCmsGetAllBannerByPageArgs = {
  data: CmsGetAllBannerByPageRequest;
};
export type QueryCmsGetAllCollectionArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where?: InputMaybe<CmsGetAllCollectionRequest>;
};
export type QueryCmsGetAllCreatorArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where?: InputMaybe<CmsGetAllCreatorRequest>;
};
export type QueryCmsGetAllIngredientArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where?: InputMaybe<CmsGetAllIngredientRequest>;
};
export type QueryCmsGetAllRecipeArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where?: InputMaybe<CmsGetAllRecipeRequest>;
};
export type QueryCmsGetAllTipsTricksArgs = {
  filter?: InputMaybe<CmsGetAllTipsTricksRequest>;
  pagination?: InputMaybe<PaginationRequest>;
};
export type QueryCmsGetAllTrendsForYouArgs = {
  filter?: InputMaybe<CmsGetTrendsForYouListRequestDto>;
  pagination?: InputMaybe<PaginationRequest>;
};
export type QueryCmsGetAllUnitArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where?: InputMaybe<CmsGetAllUnitRequest>;
};
export type QueryCmsGetBannerByIdArgs = {
  data: CmsGetBannerByIdRequest;
};
export type QueryCmsGetBrandListArgs = {
  data?: InputMaybe<CmsGetBrandListRequest>;
};
export type QueryCmsGetCpConnectListArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where?: InputMaybe<CmsGetCpConnectListRequest>;
};
export type QueryCmsGetCampaignByIdArgs = {
  data: CmsGetCampaignByIdRequest;
};
export type QueryCmsGetCampaignListArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where?: InputMaybe<CmsGetCampaignListRequest>;
};
export type QueryCmsGetCollectionByIdArgs = {
  data: CmsGetCollectionByIdRequest;
};
export type QueryCmsGetCpConnectByIdArgs = {
  data: CmsGetCpConnectByIdRequest;
};
export type QueryCmsGetGroupCategoryByIdArgs = {
  data: CmsGetGroupCategoryByIdRequest;
};
export type QueryCmsGetIngredientByIdArgs = {
  data: CmsGetIngredientByIdRequest;
};
export type QueryCmsGetMainGroupCategoryListArgs = {
  filter?: InputMaybe<CmsGetGroupCategoryListRequest>;
  pagination?: InputMaybe<PaginationRequest>;
};
export type QueryCmsGetPopularBrandByIdArgs = {
  data: CmsGetPopularBrandByIdRequest;
};
export type QueryCmsGetPopularBrandListArgs = {
  filter?: InputMaybe<CmsGetPopularBrandListRequest>;
  pagination?: InputMaybe<PaginationRequest>;
};
export type QueryCmsGetPopupDetailByIdArgs = {
  data: CmsGetPopupDetailByIdRequest;
};
export type QueryCmsGetPopupListArgs = {
  data?: InputMaybe<CmsGetPopupListRequest>;
  pagination?: InputMaybe<PaginationRequest>;
};
export type QueryCmsGetProductArgs = {
  data?: InputMaybe<CmsGetProductRequest>;
};
export type QueryCmsGetProductPinArgs = {
  data: CmsGetProductPinRequest;
};
export type QueryCmsGetRecipeArgs = {
  data?: InputMaybe<CmsGetRecipeRequest>;
};
export type QueryCmsGetRecipeByIdArgs = {
  data: CmsGetRecipeByIdRequest;
};
export type QueryCmsGetRegisterBannerByIdArgs = {
  data: CmsGetRegisterBannerByIdRequest;
};
export type QueryCmsGetSeoByIdArgs = {
  data: CmsGetFeedSeObyIdRequest;
};
export type QueryCmsGetSeoListArgs = {
  filter?: InputMaybe<CmsGetSeoListRequest>;
  pagination?: InputMaybe<PaginationRequest>;
};
export type QueryCmsGetSubGroupCategoryListArgs = {
  filter?: InputMaybe<CmsGetGroupCategoryListRequest>;
  pagination?: InputMaybe<PaginationRequest>;
};
export type QueryCmsGetTiktokHashtagByidArgs = {
  data: CmsGetTiktokHashtagByIdRequest;
};
export type QueryCmsGetTiktokHashtagListArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where?: InputMaybe<CmsGetTiktokHashtagListRequest>;
};
export type QueryCmsGetTiktokVideoByIdArgs = {
  data: CmsGetTiktokVideoByIdRequest;
};
export type QueryCmsGetTiktokVideoListArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where?: InputMaybe<CmsGetTiktokVideoListRequest>;
};
export type QueryCmsGetTipsTricksByIdArgs = {
  data: CmsGetTipsTricksByIdRequest;
};
export type QueryCmsGetTrendsForYouByIdArgs = {
  data: CmsGetTrendsForYouByIdRequest;
};
export type QueryCmsGetUnitByIdArgs = {
  data: CmsGetUnitByIdRequest;
};
export type QueryGetActivityDetailArgs = {
  data: GetActivityDetailRequest;
};
export type QueryGetActivityListArgs = {
  data?: InputMaybe<GetActivityListRequest>;
  pagination?: InputMaybe<PaginationRequest>;
};
export type QueryGetAdminByIdArgs = {
  data: GetAdminByIdRequest;
};
export type QueryGetAdminListArgs = {
  pagination?: InputMaybe<PaginationRequest>;
};
export type QueryGetAllCollectionArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where?: InputMaybe<GetAllCollectionRequest>;
};
export type QueryGetAllFoodTypeArgs = {
  data?: InputMaybe<GetAllFoodTypeRequest>;
};
export type QueryGetAllGroupCategoryArgs = {
  pagination?: InputMaybe<PaginationRequest>;
};
export type QueryGetAllProductByMeatCategoryArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where?: InputMaybe<GetAllProductByMeatCategoryRequest>;
};
export type QueryGetAllRecipeArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where?: InputMaybe<GetAllRecipeRequest>;
};
export type QueryGetAllRecipeCategoryArgs = {
  data?: InputMaybe<GetAllRecipeCategoryRequest>;
};
export type QueryGetAllUnitArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where?: InputMaybe<GetAllUnitRequest>;
};
export type QueryGetBannerByPageArgs = {
  where: GetBannerByPageRequest;
};
export type QueryGetChildrenGroupCategoryArgs = {
  data: GetChildrenGroupCategoryRequest;
};
export type QueryGetCmsRegisterBannerListArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where?: InputMaybe<CmsGetRegisterBannerListRequest>;
};
export type QueryGetCollectionDetailArgs = {
  where: GetCollectionDetailRequest;
};
export type QueryGetCpConnectArgs = {
  data?: InputMaybe<GetCpConnectRequest>;
};
export type QueryGetFeedSeoByPathArgs = {
  data: GetFeedSeObyPathRequest;
};
export type QueryGetGroupCategoryArgs = {
  data: GetGroupCategoryRequest;
};
export type QueryGetGroupCategoryDetailArgs = {
  where: GetGroupCategoryDetailRequest;
};
export type QueryGetGroupCategoryRelateRecipeByIdArgs = {
  where: GetGroupCategoryRelateRecipeByIdRequest;
};
export type QueryGetLatestActivityArgs = {
  data: GetLatestActivityRequest;
};
export type QueryGetLatestPromotionArgs = {
  data: GetLatestActivityRequest;
};
export type QueryGetMemberFavoriteCollectionArgs = {
  data?: InputMaybe<GetMemberFavoriteCollectionRequest>;
};
export type QueryGetMemberFavoriteProductArgs = {
  data?: InputMaybe<GetMemberFavoriteProductRequest>;
};
export type QueryGetMemberFavoriteRecipeArgs = {
  data?: InputMaybe<GetMemberFavoriteRecipeRequest>;
};
export type QueryGetMemberFavoriteResultArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where: GetMemberFavoriteResultRequest;
};
export type QueryGetMemberInterestingResultArgs = {
  where: GetMemberInterestingResultRequest;
};
export type QueryGetMemberSubscribeMenuByRecipeIdArgs = {
  data: GetMemberSubscribeMenuByRecipeIdRequest;
};
export type QueryGetPopularSearchAutoSuggestionArgs = {
  where: GetPopularSearchAutoSuggestionRequest;
};
export type QueryGetProductArgs = {
  data: GetProductRequest;
};
export type QueryGetProductByBrandIdArgs = {
  data: GetProductByBrandIdRequest;
};
export type QueryGetProductByGroupCategoryIdArgs = {
  where: GetProductByGroupCategoryIdRequest;
};
export type QueryGetProductPinArgs = {
  where: GetProductPinRequest;
};
export type QueryGetProductRelateProductArgs = {
  data: GetProductRelateProductRequest;
};
export type QueryGetProductRelateProductByInterestingArgs = {
  where: GetProductRelateProductByInterestingRequest;
};
export type QueryGetPromotionDetailArgs = {
  data: GetActivityDetailRequest;
};
export type QueryGetPromotionListArgs = {
  data?: InputMaybe<GetActivityListRequest>;
  pagination?: InputMaybe<PaginationRequest>;
};
export type QueryGetRecipeArgs = {
  data: GetRecipeRequest;
};
export type QueryGetRecipeByGroupCategoryIdArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where: GetRecipeByGroupCategoryIdRequest;
};
export type QueryGetRecipeByHackIdArgs = {
  data: GetRecipeByHackIdRequest;
};
export type QueryGetRecipeRelateRecipeArgs = {
  where: GetRecipeRelateRecipeCategoryRequest;
};
export type QueryGetRecipeRelateRecipeByMemberInterestingArgs = {
  where: GetRecipeRelateRecipeCategoryRequest;
};
export type QueryGetRegisterBannerArgs = {
  where: GetRegisterBannerRequest;
};
export type QueryGetSearchResultArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where: GetSearchResultRequest;
};
export type QueryGetSurpriseMenuResultArgs = {
  data: GetSurpriseMenuResultRequest;
};
export type QueryGetTiktokVideoByHashtagArgs = {
  where: GetTiktokVideoByHashtagRequest;
};
export type QueryGetTipsTricksByCategoryIdArgs = {
  pagination?: InputMaybe<PaginationRequest>;
  where: GetTipsTricksByCategoryIdRequest;
};
export type QueryGetTipsTricksDetailArgs = {
  where: GetTipsTricksDetailRequest;
};
export type QueryGetUnitArgs = {
  data: GetUnitRequest;
};
export type RecipeCategoryResponse = {
  __typename?: 'RecipeCategoryResponse';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  parentId: Scalars['Int']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};
export type RecipeCategoryWithChildrenResponse = {
  __typename?: 'RecipeCategoryWithChildrenResponse';
  children?: Maybe<Array<Maybe<RecipeCategoryResponse>>>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  parentId: Scalars['Int']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};
export type RecipeHackResponse = {
  __typename?: 'RecipeHackResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type RecipeLevelResponse = {
  __typename?: 'RecipeLevelResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type RecipeMethodResponse = {
  __typename?: 'RecipeMethodResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  parentId: Scalars['Float']['output'];
  status: StatusType;
  surpriseMenuImgUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type RecipeMethodWithChildrenResponse = {
  __typename?: 'RecipeMethodWithChildrenResponse';
  children?: Maybe<Array<Maybe<RecipeMethodResponse>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  parentId: Scalars['Float']['output'];
  status: StatusType;
  surpriseMenuImgUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type RecipeRegionResponse = {
  __typename?: 'RecipeRegionResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  parentId: Scalars['Float']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type RecipeRegionWithChildrenResponse = {
  __typename?: 'RecipeRegionWithChildrenResponse';
  children?: Maybe<Array<Maybe<RecipeRegionResponse>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  parentId: Scalars['Float']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type RecipeRelateIngredientsResponse = {
  __typename?: 'RecipeRelateIngredientsResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  ingredient?: Maybe<IngredientsResponse>;
  ingredientType?: Maybe<IngredientType>;
  linkoutUrl?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Float']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  unit?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type RecipeRelateProductResponse = {
  __typename?: 'RecipeRelateProductResponse';
  cooking?: Maybe<Scalars['String']['output']>;
  cookingImage?: Maybe<Scalars['String']['output']>;
  coverImageUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  feedDescription?: Maybe<Scalars['String']['output']>;
  feedKeyword?: Maybe<Scalars['String']['output']>;
  feedTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  ingredient?: Maybe<Scalars['String']['output']>;
  ingredientImage?: Maybe<Scalars['String']['output']>;
  ogImage?: Maybe<Scalars['String']['output']>;
  people: Scalars['Float']['output'];
  priority: Scalars['Float']['output'];
  recipeProduct?: Maybe<Array<Maybe<RelateWithProductResponse>>>;
  slugUrl?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  time: Scalars['String']['output'];
  tipsTricks?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  toPeople?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};
export type RecipeRelateRecipeResponse = {
  __typename?: 'RecipeRelateRecipeResponse';
  cooking?: Maybe<Scalars['String']['output']>;
  cookingImage?: Maybe<Scalars['String']['output']>;
  coverImageUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  feedDescription?: Maybe<Scalars['String']['output']>;
  feedKeyword?: Maybe<Scalars['String']['output']>;
  feedTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  ingredient?: Maybe<Scalars['String']['output']>;
  ingredientImage?: Maybe<Scalars['String']['output']>;
  ogImage?: Maybe<Scalars['String']['output']>;
  people: Scalars['Float']['output'];
  priority: Scalars['Float']['output'];
  recipeCategory?: Maybe<Array<RecipeCategoryResponse>>;
  recipeMeatCategory?: Maybe<Array<MeatCategoryResponse>>;
  slugUrl?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  time: Scalars['String']['output'];
  tipsTricks?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  toPeople?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};
export type RecipeRelateResponse = {
  __typename?: 'RecipeRelateResponse';
  cooking?: Maybe<Scalars['String']['output']>;
  cookingImage?: Maybe<Scalars['String']['output']>;
  coverImageUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<CreatorResponse>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  feedDescription?: Maybe<Scalars['String']['output']>;
  feedKeyword?: Maybe<Scalars['String']['output']>;
  feedTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  ingredient?: Maybe<Scalars['String']['output']>;
  ingredientImage?: Maybe<Scalars['String']['output']>;
  ogImage?: Maybe<Scalars['String']['output']>;
  people: Scalars['Float']['output'];
  priority: Scalars['Float']['output'];
  recipeCategory?: Maybe<Array<Maybe<RecipeCategoryResponse>>>;
  recipeCollection?: Maybe<Array<Maybe<CollectionResponse>>>;
  recipeGroupCategory?: Maybe<Array<Maybe<GroupCategoryResponse>>>;
  recipeHack?: Maybe<RecipeHackResponse>;
  recipeIngredient?: Maybe<Array<Maybe<RecipeRelateIngredientsResponse>>>;
  recipeLevel?: Maybe<RecipeLevelResponse>;
  recipeMeatCategory?: Maybe<Array<Maybe<MeatCategoryResponse>>>;
  recipeMethod?: Maybe<RecipeMethodResponse>;
  recipeProduct?: Maybe<Array<Maybe<RelateWithProductResponse>>>;
  recipeRegion?: Maybe<RecipeRegionResponse>;
  recipeType?: Maybe<RecipeTypeResponse>;
  slugUrl?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  time: Scalars['String']['output'];
  tipsTricks?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  toPeople?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};
export type RecipeResponse = {
  __typename?: 'RecipeResponse';
  cooking?: Maybe<Scalars['String']['output']>;
  cookingImage?: Maybe<Scalars['String']['output']>;
  coverImageUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  feedDescription?: Maybe<Scalars['String']['output']>;
  feedKeyword?: Maybe<Scalars['String']['output']>;
  feedTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  ingredient?: Maybe<Scalars['String']['output']>;
  ingredientImage?: Maybe<Scalars['String']['output']>;
  ogImage?: Maybe<Scalars['String']['output']>;
  people: Scalars['Float']['output'];
  priority: Scalars['Float']['output'];
  slugUrl?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  time: Scalars['String']['output'];
  tipsTricks?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  toPeople?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};
export type RecipeSearchCondition = {
  meatCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  methodIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  typeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};
export type RecipeTypeResponse = {
  __typename?: 'RecipeTypeResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  interestingCoverUrl?: Maybe<Scalars['String']['output']>;
  parentId: Scalars['Float']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type RecipeTypeWithChildrenResponse = {
  __typename?: 'RecipeTypeWithChildrenResponse';
  children?: Maybe<Array<Maybe<RecipeTypeResponse>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  interestingCoverUrl?: Maybe<Scalars['String']['output']>;
  parentId: Scalars['Float']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type RegisterBannerListResponse = {
  __typename?: 'RegisterBannerListResponse';
  result: Array<RegisterBannerResponse>;
  total: Scalars['Float']['output'];
};
export type RegisterBannerResponse = {
  __typename?: 'RegisterBannerResponse';
  bannerDesktop: Scalars['String']['output'];
  bannerMobile: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  linkoutUrl?: Maybe<Scalars['String']['output']>;
  registerBannerType?: Maybe<RegisterBannerType>;
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export enum RegisterBannerType {
  BannerPlayGame = 'BannerPlayGame',
  BannerRegister = 'BannerRegister',
  BannerSubscribed = 'BannerSubscribed',
}
export type RelateWithBannerResponse = {
  __typename?: 'RelateWithBannerResponse';
  banner?: Maybe<BannerResponse>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  position: PositionType;
  priority: Scalars['Float']['output'];
  startedAt: Scalars['DateTime']['output'];
  status: StatusType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type RelateWithPageResponse = {
  __typename?: 'RelateWithPageResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  page?: Maybe<PageResponse>;
  position: PositionType;
  priority: Scalars['Float']['output'];
  startedAt: Scalars['DateTime']['output'];
  status: StatusType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type RelateWithProductResponse = {
  __typename?: 'RelateWithProductResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  linkoutUrl?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Float']['output']>;
  product?: Maybe<ProductResponse>;
  quantity?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  unit?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type ResetPasswordAdminRequest = {
  /** use id from admin list for reset password */
  id: Scalars['Int']['input'];
  password: Scalars['String']['input'];
  passwordType: PasswordType;
};
export type SaveAdminRequest = {
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  /** send adminId for update value */
  id?: InputMaybe<Scalars['Int']['input']>;
  password: Scalars['String']['input'];
  role: AdminRole;
  status: StatusType;
};
export type SearchResultResponse = {
  __typename?: 'SearchResultResponse';
  data?: Maybe<DataSearchResponse>;
  productTotal: Scalars['Int']['output'];
  recipeTotal: Scalars['Int']['output'];
  tipsTrickTotal: Scalars['Int']['output'];
};
export type Shops = {
  __typename?: 'Shops';
  imageUrl: Scalars['String']['output'];
  shopUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};
export type Socials = {
  __typename?: 'Socials';
  name: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};
export enum StatusType {
  Deleted = 'Deleted',
  Draft = 'Draft',
  Published = 'Published',
  Unpublished = 'Unpublished',
}
export enum TabNameForMemberFavoriteResult {
  Collection = 'Collection',
  Product = 'Product',
  Recipe = 'Recipe',
}
export enum TabNameForSearch {
  Product = 'Product',
  Recipe = 'Recipe',
  TipsTrick = 'TipsTrick',
}
export type TiktokHashtagResponse = {
  __typename?: 'TiktokHashtagResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  hashtag: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  priority: Scalars['Float']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type TiktokVideoResponse = {
  __typename?: 'TiktokVideoResponse';
  coverImg?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  durationVideo: Scalars['Float']['output'];
  embedVideo?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  likesCount?: Maybe<Scalars['Float']['output']>;
  shareCount?: Maybe<Scalars['Float']['output']>;
  status: StatusType;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  tiktokCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  tiktokThumbUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  videoId: Scalars['String']['output'];
  videoUrl: Scalars['String']['output'];
  viewCount: Scalars['Float']['output'];
};
export type TiktokVideoWithTiktokHashtagResponse = {
  __typename?: 'TiktokVideoWithTiktokHashtagResponse';
  coverImg?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  durationVideo: Scalars['Float']['output'];
  embedVideo?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  likesCount?: Maybe<Scalars['Float']['output']>;
  shareCount?: Maybe<Scalars['Float']['output']>;
  status: StatusType;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  tiktokCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  tiktokHashtags: Array<TiktokHashtagResponse>;
  tiktokThumbUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  videoId: Scalars['String']['output'];
  videoUrl: Scalars['String']['output'];
  viewCount: Scalars['Float']['output'];
};
export type TipsTrickSearchCondition = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};
export type TipsTricksCategoryResponse = {
  __typename?: 'TipsTricksCategoryResponse';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  parentId: Scalars['Int']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};
export type TipsTricksDetailWithRelateDetailResponse = {
  __typename?: 'TipsTricksDetailWithRelateDetailResponse';
  createdAt: Scalars['DateTime']['output'];
  creator?: Maybe<CreatorResponse>;
  description: Scalars['String']['output'];
  detail: Scalars['String']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  feedDescription?: Maybe<Scalars['String']['output']>;
  feedKeyword?: Maybe<Scalars['String']['output']>;
  feedTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isPin: Scalars['Boolean']['output'];
  ogImage?: Maybe<Scalars['String']['output']>;
  priority: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slugUrl?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  tipsTricksCategory: Array<TipsTricksCategoryResponse>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};
export type TipsTricksResponse = {
  __typename?: 'TipsTricksResponse';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  detail: Scalars['String']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  feedDescription?: Maybe<Scalars['String']['output']>;
  feedKeyword?: Maybe<Scalars['String']['output']>;
  feedTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isPin: Scalars['Boolean']['output'];
  ogImage?: Maybe<Scalars['String']['output']>;
  priority: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slugUrl?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};
export type TipsTricksWithCategoryResponse = {
  __typename?: 'TipsTricksWithCategoryResponse';
  category?: Maybe<Array<TipsTricksCategoryResponse>>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  detail: Scalars['String']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  feedDescription?: Maybe<Scalars['String']['output']>;
  feedKeyword?: Maybe<Scalars['String']['output']>;
  feedTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isPin: Scalars['Boolean']['output'];
  ogImage?: Maybe<Scalars['String']['output']>;
  priority: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slugUrl?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  thumbUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};
export type TipsTricksWithPaginationResponse = {
  __typename?: 'TipsTricksWithPaginationResponse';
  tipsTricks?: Maybe<Array<TipsTricksWithCategoryResponse>>;
  total: Scalars['Float']['output'];
};
export type TrendsForYouResponse = {
  __typename?: 'TrendsForYouResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  hashtag: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  publishedAt: Scalars['DateTime']['output'];
  socials: Array<Socials>;
  status: StatusType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type UnitResponse = {
  __typename?: 'UnitResponse';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  status: StatusType;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};
export type UpdateMemberFavoriteRequest = {
  favoriteType: FavoriteType;
  idOfFavoriteItem: Scalars['Int']['input'];
};
export type UpdateMemberFavoriteResponse = {
  __typename?: 'UpdateMemberFavoriteResponse';
  isFavorite: Scalars['Boolean']['output'];
};
export type UpdateMemberInterestingRequest = {
  idOfInterestingItem: Array<Scalars['Int']['input']>;
  interestingType: InterestingType;
};
export type UpdateShouldShowInterestingRequest = {
  shouldShowInteresting: Scalars['Boolean']['input'];
};
export type GetActivityByMonthQueryQueryVariables = Exact<{
  data?: InputMaybe<GetActivityListRequest>;
  pagination?: InputMaybe<PaginationRequest>;
}>;
export type GetActivityByMonthQueryQuery = {
  __typename?: 'Query';
  getActivityList?: {
    __typename?: 'GetAllActivityResponse';
    total: number;
    activities?: Array<{
      __typename?: 'ActivityResponse';
      id: number;
      title: string;
      description: string;
      createdAt?: any | null;
      thumbUrl?: string | null;
      startedAt: any;
      endedAt: any;
      slugUrl?: string | null;
    }> | null;
  } | null;
};
export type GetActivityDetailQueryVariables = Exact<{
  data: GetActivityDetailRequest;
}>;
export type GetActivityDetailQuery = {
  __typename?: 'Query';
  getActivityDetail: {
    __typename?: 'ActivityWithCreatorResponse';
    id: number;
    detail?: string | null;
    imageUrl?: string | null;
    endedAt: any;
    location?: string | null;
    startedAt: any;
    title: string;
    createdAt?: any | null;
    slugUrl?: string | null;
    creator?: {
      __typename?: 'CreatorResponse';
      id: number;
      imageUrl: string;
      name: string;
      slugUrl: string;
    } | null;
  };
};
export type GetActivityFilterQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetActivityFilterQueryQuery = {
  __typename?: 'Query';
  getActivityFilter?: Array<{
    __typename?: 'FilterActivityResponse';
    label: number;
    options: Array<{
      __typename?: 'OptionMonth';
      label: string;
      value: any;
    }>;
  }> | null;
};
export type GetRelatedActivityQueryVariables = Exact<{
  data: GetLatestActivityRequest;
}>;
export type GetRelatedActivityQuery = {
  __typename?: 'Query';
  getLatestActivity?: Array<{
    __typename?: 'ActivityResponse';
    id: number;
    title: string;
    thumbUrl?: string | null;
    description: string;
    endedAt: any;
    startedAt: any;
    slugUrl?: string | null;
  }> | null;
};
export type GetTokenMutationMutationVariables = Exact<{
  data: CdpLoginRequest;
}>;
export type GetTokenMutationMutation = {
  __typename?: 'Mutation';
  cdpLogin: {
    __typename?: 'CdpAuthResponse';
    accessToken: string;
  };
};
export type RenewTokenMutationMutationVariables = Exact<{
  [key: string]: never;
}>;
export type RenewTokenMutationMutation = {
  __typename?: 'Mutation';
  cdpRenewToken: {
    __typename?: 'CdpAuthResponse';
    accessToken: string;
  };
};
export type RevokeTokenMutationMutationVariables = Exact<{
  [key: string]: never;
}>;
export type RevokeTokenMutationMutation = {
  __typename?: 'Mutation';
  cdpLogout: boolean;
};
export type GetBannerByPageQueryVariables = Exact<{
  where: GetBannerByPageRequest;
}>;
export type GetBannerByPageQuery = {
  __typename?: 'Query';
  getBannerByPage: {
    __typename?: 'GetAllBannerByPageResponse';
    highlight: Array<{
      __typename?: 'BannerResponse';
      id: number;
      bannerDesktop: string;
      bannerMobile: string;
      linkOutUrl?: string | null;
    }>;
    bottom?: Array<{
      __typename?: 'BannerResponse';
      id: number;
      bannerDesktop: string;
      bannerMobile: string;
      linkOutUrl?: string | null;
    }> | null;
  };
};
export type AllCollectionQueryQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationRequest>;
}>;
export type AllCollectionQueryQuery = {
  __typename?: 'Query';
  getAllCollection: {
    __typename?: 'GetAllCollectionResponse';
    total: number;
    collections?: Array<{
      __typename?: 'CollectionWithRecipeResponse';
      id: number;
      title?: string | null;
      slugUrl: string;
      recipeListTotal?: number | null;
      recipeList?: Array<{
        __typename?: 'RecipeResponse';
        id: number;
        slugUrl?: string | null;
        thumbUrl?: string | null;
      } | null> | null;
    } | null> | null;
  };
};
export type CollectionDetailQueryQueryVariables = Exact<{
  where: GetCollectionDetailRequest;
}>;
export type CollectionDetailQueryQuery = {
  __typename?: 'Query';
  getCollectionDetail: {
    __typename?: 'CollectionDetailResponse';
    id: number;
    slugUrl: string;
    thumbUrl?: string | null;
    title?: string | null;
    bgCover?: string | null;
    video?: string | null;
    description?: string | null;
    recipeListTotal?: number | null;
    recipeList?: Array<{
      __typename?: 'RecipeRelateProductResponse';
      id: number;
      slugUrl?: string | null;
      title: string;
      thumbUrl?: string | null;
      recipeProduct?: Array<{
        __typename?: 'RelateWithProductResponse';
        product?: {
          __typename?: 'ProductResponse';
          id: number;
          slugUrl?: string | null;
          thumbUrl?: string | null;
          title: string;
          tag?: Array<string | null> | null;
          weight?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};
export type NewCollectionQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type NewCollectionQueryQuery = {
  __typename?: 'Query';
  getHighlightCollection?: Array<{
    __typename?: 'CollectionWithRecipeResponse';
    id: number;
    title?: string | null;
    slugUrl: string;
    thumbUrl?: string | null;
    video?: string | null;
    description?: string | null;
    recipeListTotal?: number | null;
    recipeList?: Array<{
      __typename?: 'RecipeResponse';
      id: number;
      title: string;
      slugUrl?: string | null;
      thumbUrl?: string | null;
    } | null> | null;
  }> | null;
};
export type PopularCollectionQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type PopularCollectionQueryQuery = {
  __typename?: 'Query';
  getPopularCollection?: Array<{
    __typename?: 'PopularCollectionResponse';
    id: number;
    title?: string | null;
    colorTitlePopular?: string | null;
    thumbUrl?: string | null;
    colorMenuPopular?: string | null;
    thumbPopular?: string | null;
    recipeListTotal?: number | null;
    recipeList?: Array<{
      __typename?: 'RecipeResponse';
      id: number;
      slugUrl?: string | null;
      title: string;
      thumbUrl?: string | null;
    } | null> | null;
  }> | null;
};
export type GetCollectionRelatedProductQueryQueryVariables = Exact<{
  where: GetCollectionDetailRequest;
}>;
export type GetCollectionRelatedProductQueryQuery = {
  __typename?: 'Query';
  getCollectionDetail: {
    __typename?: 'CollectionDetailResponse';
    recipeList?: Array<{
      __typename?: 'RecipeRelateProductResponse';
      recipeProduct?: Array<{
        __typename?: 'RelateWithProductResponse';
        product?: {
          __typename?: 'ProductResponse';
          id: number;
          slugUrl?: string | null;
          thumbUrl?: string | null;
          title: string;
          tag?: Array<string | null> | null;
          weight?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};
export type GetCpConnectQueryQueryVariables = Exact<{
  data?: InputMaybe<GetCpConnectRequest>;
}>;
export type GetCpConnectQueryQuery = {
  __typename?: 'Query';
  getCpConnect?: Array<{
    __typename?: 'CpConnectResponse';
    title: string;
    mainCoverUrl?: string | null;
    linkOutUrl: string;
    imageLogoUrl?: string | null;
    id: number;
    description?: string | null;
  }> | null;
};
export type UpdateMemberFavoriteMutationVariables = Exact<{
  data: UpdateMemberFavoriteRequest;
}>;
export type UpdateMemberFavoriteMutation = {
  __typename?: 'Mutation';
  updateMemberFavorite: {
    __typename?: 'UpdateMemberFavoriteResponse';
    isFavorite: boolean;
  };
};
export type GetMemberFavoriteCollectionQueryQueryVariables = Exact<{
  data?: InputMaybe<GetMemberFavoriteCollectionRequest>;
}>;
export type GetMemberFavoriteCollectionQueryQuery = {
  __typename?: 'Query';
  getMemberFavoriteCollection: Array<number>;
};
export type GetMemberFavoriteProductQueryQueryVariables = Exact<{
  data?: InputMaybe<GetMemberFavoriteProductRequest>;
}>;
export type GetMemberFavoriteProductQueryQuery = {
  __typename?: 'Query';
  getMemberFavoriteProduct: Array<number>;
};
export type GetMemberFavoriteRecipeQueryQueryVariables = Exact<{
  data?: InputMaybe<GetMemberFavoriteRecipeRequest>;
}>;
export type GetMemberFavoriteRecipeQueryQuery = {
  __typename?: 'Query';
  getMemberFavoriteRecipe: Array<number>;
};
export type GetMemberFavoriteResultQueryQueryVariables = Exact<{
  where: GetMemberFavoriteResultRequest;
}>;
export type GetMemberFavoriteResultQueryQuery = {
  __typename?: 'Query';
  getMemberFavoriteResult?: {
    __typename?: 'MemberFavoriteResultResponse';
    recipeTotal: number;
    productTotal: number;
    collectionTotal: number;
    data?: {
      __typename?: 'DataMemberFavoriteResponse';
      recipes?: Array<{
        __typename?: 'RecipeRelateResponse';
        id: number;
        title: string;
        thumbUrl?: string | null;
        slugUrl?: string | null;
        updatedAt?: any | null;
        recipeMeatCategory?: Array<{
          __typename?: 'MeatCategoryResponse';
          title: string;
        } | null> | null;
      }> | null;
      products?: Array<{
        __typename?: 'ProductResponse';
        id: number;
        title: string;
        thumbUrl?: string | null;
        slugUrl?: string | null;
        tag?: Array<string | null> | null;
        updatedAt?: any | null;
      }> | null;
      collections?: Array<{
        __typename?: 'CollectionResponse';
        id: number;
        title?: string | null;
        thumbUrl?: string | null;
        video?: string | null;
        recipeListTotal?: number | null;
        slugUrl: string;
      }> | null;
    } | null;
  } | null;
};
export type GetFilterCategoryQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetFilterCategoryQueryQuery = {
  __typename?: 'Query';
  getAllFilterMeatCategory?: Array<{
    __typename?: 'MeatCategoryResponse';
    id: number;
    title: string;
    logo?: string | null;
  }> | null;
  getAllFilterRecipeType?: Array<{
    __typename?: 'RecipeTypeResponse';
    id: number;
    title: string;
  }> | null;
  getAllFilterRecipeMethod?: Array<{
    __typename?: 'RecipeMethodResponse';
    id: number;
    title: string;
  }> | null;
  getAllFilterRecipeRegion?: Array<{
    __typename?: 'RecipeRegionResponse';
    id: number;
    title: string;
  }> | null;
  getAllFilterTipsTricksCategory?: Array<{
    __typename?: 'TipsTricksCategoryResponse';
    id: number;
    title: string;
  }> | null;
  getAllBranchFilter?: Array<{
    __typename?: 'BranchResponse';
    id: number;
    title: string;
  }> | null;
  getAllProductCategory?: Array<{
    __typename?: 'ProductCategoryResponse';
    id: number;
    title: string;
  }> | null;
  getAllBrand?: Array<{
    __typename?: 'BrandResponse';
    brandId: number;
    title: string;
    thumbnailUrl: string;
  }> | null;
};
export type GroupCategoriesTrainQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GroupCategoriesTrainQuery = {
  __typename?: 'Query';
  getGroupCategoriesTrain: {
    __typename?: 'GroupCategoryTrainResponse';
    bottomContent: Array<{
      __typename?: 'GroupCategoryResponse';
      title?: string | null;
      slugUrl?: string | null;
      mainColor?: string | null;
      imageIconUrl?: string | null;
      id: number;
      tooltipText?: string | null;
    }>;
    middleContent: Array<{
      __typename?: 'GroupCategoryResponse';
      title?: string | null;
      slugUrl?: string | null;
      mainColor?: string | null;
      imageIconUrl?: string | null;
      id: number;
      tooltipText?: string | null;
    }>;
    topContent: Array<{
      __typename?: 'GroupCategoryResponse';
      title?: string | null;
      slugUrl?: string | null;
      mainColor?: string | null;
      imageIconUrl?: string | null;
      id: number;
      tooltipText?: string | null;
    }>;
  };
};
export type GetCategoryMenuQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetCategoryMenuQueryQuery = {
  __typename?: 'Query';
  getWhatShouldIEatGroupCategories?: Array<{
    __typename?: 'GroupCategoryResponse';
    id: number;
    title?: string | null;
    slugUrl?: string | null;
  }> | null;
  getWhatShouldIEatCollections?: Array<{
    __typename?: 'CollectionResponse';
    id: number;
    title?: string | null;
    slugUrl: string;
  }> | null;
  getWhatShouldIEatMeatCategories?: Array<{
    __typename?: 'MeatCategoryResponse';
    id: number;
    title: string;
  }> | null;
  getWhatShouldIEatRecipeMethods?: Array<{
    __typename?: 'RecipeMethodResponse';
    id: number;
    title: string;
  }> | null;
  getWhatShouldIEatRecipeTypes?: Array<{
    __typename?: 'RecipeTypeResponse';
    id: number;
    title: string;
  }> | null;
};
export type HomeCampaignQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type HomeCampaignQueryQuery = {
  __typename?: 'Query';
  getLatestCampaign?: {
    __typename?: 'CampaignResponse';
    id: number;
    title: string;
    description?: string | null;
    linkoutUrl: string;
    thumbUrl: string;
  } | null;
};
export type GameBannerQueryQueryVariables = Exact<{
  where: GetBannerByPageRequest;
}>;
export type GameBannerQueryQuery = {
  __typename?: 'Query';
  getBannerByPage: {
    __typename?: 'GetAllBannerByPageResponse';
    highlight: Array<{
      __typename?: 'BannerResponse';
      id: number;
      title: string;
      bannerDesktop: string;
      bannerMobile: string;
      bannerIos?: string | null;
      linkOutUrl?: string | null;
      path?: string | null;
      bannerType: BannerType;
      createdAt?: any | null;
      updatedAt?: any | null;
    }>;
  };
};
export type GetHomePopupQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetHomePopupQueryQuery = {
  __typename?: 'Query';
  getPopup?: {
    __typename?: 'PopupResponse';
    status: StatusType;
    createdAt?: any | null;
    updatedAt?: any | null;
    deletedAt?: any | null;
    id: number;
    title: string;
    popupType: PopupType;
    youtubeUrl?: string | null;
    imageUrl?: string | null;
    linkOutUrl?: string | null;
    priority: number;
    startedAt: any;
    endedAt?: any | null;
  } | null;
};
export type RecommendProductQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type RecommendProductQueryQuery = {
  __typename?: 'Query';
  getRecommendedProduct?: Array<{
    __typename?: 'ProductResponse';
    id: number;
    slugUrl?: string | null;
    title: string;
    tag?: Array<string | null> | null;
    thumbUrl?: string | null;
    weight?: string | null;
  }> | null;
};
export type TrendsForYouQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type TrendsForYouQueryQuery = {
  __typename?: 'Query';
  getTrendsForYou?: Array<{
    __typename?: 'TrendsForYouResponse';
    hashtag: string;
    id: number;
    socials: Array<{
      __typename?: 'Socials';
      name: string;
      url?: string | null;
    }>;
  }> | null;
};
export type SaveChoosedInterestingMutationMutationVariables = Exact<{
  data: UpdateMemberInterestingRequest;
}>;
export type SaveChoosedInterestingMutationMutation = {
  __typename?: 'Mutation';
  updateMemberInteresting: boolean;
};
export type UpdateShouldShowInterestingMutationMutationVariables = Exact<{
  data: UpdateShouldShowInterestingRequest;
}>;
export type UpdateShouldShowInterestingMutationMutation = {
  __typename?: 'Mutation';
  updateShouldShowInterestingMember: boolean;
};
export type GetAllInterestingQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetAllInterestingQueryQuery = {
  __typename?: 'Query';
  getMeatCategoryInteresting?: Array<{
    __typename?: 'MeatCategoryResponse';
    id: number;
    title: string;
    logo?: string | null;
  }> | null;
  getGroupCategoryMainInteresting: Array<{
    __typename?: 'GroupCategoryResponse';
    id: number;
    title?: string | null;
    interestingCoverUrl?: string | null;
  }>;
  getRecipeTypeInteresting?: Array<{
    __typename?: 'RecipeTypeResponse';
    id: number;
    title: string;
    interestingCoverUrl?: string | null;
  }> | null;
};
export type GetChoosedInterestingContentQueryQueryVariables = Exact<{
  where: GetMemberInterestingResultRequest;
}>;
export type GetChoosedInterestingContentQueryQuery = {
  __typename?: 'Query';
  getMemberInterestingResult?: Array<number> | null;
};
export type AllMeatCategoryQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type AllMeatCategoryQueryQuery = {
  __typename?: 'Query';
  getAllMeatCategory?: Array<{
    __typename?: 'MeatCategoryResponse';
    id: number;
    title: string;
  }> | null;
};
export type ProductAllPopularBrandQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type ProductAllPopularBrandQueryQuery = {
  __typename?: 'Query';
  getAllBrand?: Array<{
    __typename?: 'BrandResponse';
    brandId: number;
    title: string;
    coverImg: string;
    thumbnailUrl: string;
    linkOutUrl?: string | null;
  }> | null;
};
export type GetProductByBrandIdQueryQueryVariables = Exact<{
  data: GetProductByBrandIdRequest;
}>;
export type GetProductByBrandIdQueryQuery = {
  __typename?: 'Query';
  getProductByBrandId?: Array<{
    __typename?: 'ProductResponse';
    id: number;
    title: string;
    thumbUrl?: string | null;
    slugUrl?: string | null;
  }> | null;
};
export type ProductByMeatCategoryQueryQueryVariables = Exact<{
  where?: InputMaybe<GetAllProductByMeatCategoryRequest>;
  pagination?: InputMaybe<PaginationRequest>;
}>;
export type ProductByMeatCategoryQueryQuery = {
  __typename?: 'Query';
  getAllProductByMeatCategory: {
    __typename?: 'GetAllProductResponse';
    total?: number | null;
    products: Array<{
      __typename?: 'ProductResponse';
      id: number;
      title: string;
      slugUrl?: string | null;
      tag?: Array<string | null> | null;
      thumbUrl?: string | null;
      weight?: string | null;
    }>;
  };
};
export type ProductDetailQueryQueryVariables = Exact<{
  data: GetProductRequest;
}>;
export type ProductDetailQueryQuery = {
  __typename?: 'Query';
  getProduct?: {
    __typename?: 'GetProductDetailResponse';
    id: number;
    title: string;
    tag?: Array<string | null> | null;
    slugUrl?: string | null;
    shortDescription?: string | null;
    nutrition?: string | null;
    cooking?: string | null;
    preserve?: string | null;
    images?: Array<string | null> | null;
    productIngredients?: string | null;
    meatCategories?: Array<{
      __typename?: 'MeatCategoryResponse';
      id: number;
    }> | null;
    shops?: Array<{
      __typename?: 'Shops';
      title: string;
      imageUrl: string;
      shopUrl: string;
    }> | null;
    productCategories?: Array<{
      __typename?: 'ProductCategoryResponse';
      id: number;
    }> | null;
    productDescription?: {
      __typename?: 'ProductDescription';
      brand?: string | null;
      brandImg?: string | null;
      category?: string | null;
      weight?: string | null;
      amount?: string | null;
      meatType?: string | null;
      allergen?: string | null;
      productStartDate?: string | null;
      productExpiredDate?: string | null;
      fdaNumber?: string | null;
    } | null;
    recipe?: Array<{
      __typename?: 'RecipeRelateResponse';
      id: number;
      title: string;
      slugUrl?: string | null;
      thumbUrl?: string | null;
      recipeMeatCategory?: Array<{
        __typename?: 'MeatCategoryResponse';
        title: string;
      } | null> | null;
    }> | null;
  } | null;
};
export type GetProductPinQueryQueryVariables = Exact<{
  where: GetProductPinRequest;
}>;
export type GetProductPinQueryQuery = {
  __typename?: 'Query';
  getProductPin?: Array<{
    __typename?: 'ProductPinWithProductDetailResponse';
    id: number;
    product?: {
      __typename?: 'ProductResponse';
      id: number;
      slugUrl?: string | null;
      thumbUrl?: string | null;
      title: string;
    } | null;
  }> | null;
};
export type GetProductRelateProductByInterestingQueryVariables = Exact<{
  where: GetProductRelateProductByInterestingRequest;
}>;
export type GetProductRelateProductByInterestingQuery = {
  __typename?: 'Query';
  getProductRelateProductByInteresting?: Array<{
    __typename?: 'ProductResponse';
    id: number;
    title: string;
    slugUrl?: string | null;
    tag?: Array<string | null> | null;
    thumbUrl?: string | null;
    weight?: string | null;
  } | null> | null;
};
export type GetProductRelatedProductQueryQueryVariables = Exact<{
  data: GetProductRelateProductRequest;
}>;
export type GetProductRelatedProductQueryQuery = {
  __typename?: 'Query';
  getProductRelateProduct?: Array<{
    __typename?: 'ProductResponse';
    id: number;
    title: string;
    slugUrl?: string | null;
    tag?: Array<string | null> | null;
    thumbUrl?: string | null;
    weight?: string | null;
  }> | null;
};
export type GetProductRelatedRecipeQueryQueryVariables = Exact<{
  data: GetProductRequest;
}>;
export type GetProductRelatedRecipeQueryQuery = {
  __typename?: 'Query';
  getProduct?: {
    __typename?: 'GetProductDetailResponse';
    recipe?: Array<{
      __typename?: 'RecipeRelateResponse';
      id: number;
      title: string;
      slugUrl?: string | null;
      thumbUrl?: string | null;
    }> | null;
  } | null;
};
export type GetPromotionDetailQueryQueryVariables = Exact<{
  data: GetActivityDetailRequest;
}>;
export type GetPromotionDetailQueryQuery = {
  __typename?: 'Query';
  getPromotionDetail: {
    __typename?: 'PromotionWithCreatorResponse';
    status: StatusType;
    createdAt?: any | null;
    updatedAt?: any | null;
    deletedAt?: any | null;
    id: number;
    title: string;
    description: string;
    feedTitle?: string | null;
    feedDescription?: string | null;
    ogImage?: string | null;
    feedKeyword?: string | null;
    detail?: string | null;
    thumbUrl?: string | null;
    imageUrl?: string | null;
    location?: string | null;
    activityCategory?: ActivityCategory | null;
    startedAt: any;
    endedAt: any;
    publishedAt?: any | null;
    expiredAt?: any | null;
    slugUrl?: string | null;
    isExpire?: boolean | null;
    creator?: {
      __typename?: 'CreatorResponse';
      status: StatusType;
      createdAt?: any | null;
      updatedAt?: any | null;
      deletedAt?: any | null;
      id: number;
      name: string;
      imageUrl: string;
      slugUrl: string;
    } | null;
  };
};
export type GetRealtedPromotionQueryQueryVariables = Exact<{
  data: GetLatestActivityRequest;
}>;
export type GetRealtedPromotionQueryQuery = {
  __typename?: 'Query';
  getLatestPromotion?: Array<{
    __typename?: 'PromotionResponse';
    status: StatusType;
    createdAt?: any | null;
    updatedAt?: any | null;
    deletedAt?: any | null;
    id: number;
    title: string;
    description: string;
    feedTitle?: string | null;
    feedDescription?: string | null;
    ogImage?: string | null;
    feedKeyword?: string | null;
    detail?: string | null;
    thumbUrl?: string | null;
    imageUrl?: string | null;
    location?: string | null;
    activityCategory?: ActivityCategory | null;
    startedAt: any;
    endedAt: any;
    publishedAt?: any | null;
    expiredAt?: any | null;
    isExpire?: boolean | null;
    slugUrl?: string | null;
  }> | null;
};
export type GetNewestPromotionQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetNewestPromotionQueryQuery = {
  __typename?: 'Query';
  getPromotionEightItems?: Array<{
    __typename?: 'PromotionResponse';
    id: number;
    title: string;
    description: string;
    thumbUrl?: string | null;
    startedAt: any;
    endedAt: any;
    isExpire?: boolean | null;
    slugUrl?: string | null;
  }> | null;
};
export type GetPromotionByMonthQueryQueryVariables = Exact<{
  data?: InputMaybe<GetActivityListRequest>;
  pagination?: InputMaybe<PaginationRequest>;
}>;
export type GetPromotionByMonthQueryQuery = {
  __typename?: 'Query';
  getPromotionList?: {
    __typename?: 'GetAllPromotionResponse';
    total: number;
    promotions?: Array<{
      __typename?: 'PromotionResponse';
      id: number;
      title: string;
      description: string;
      thumbUrl?: string | null;
      startedAt: any;
      endedAt: any;
      isExpire?: boolean | null;
      slugUrl?: string | null;
    }> | null;
  } | null;
};
export type GetPromotionFilterQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetPromotionFilterQueryQuery = {
  __typename?: 'Query';
  getPromotionFilter?: Array<{
    __typename?: 'FilterActivityResponse';
    label: number;
    options: Array<{
      __typename?: 'OptionMonth';
      label: string;
      value: any;
    }>;
  }> | null;
};
export type RecipeGroupCarouselQueryQueryVariables = Exact<{
  where: GetProductByGroupCategoryIdRequest;
}>;
export type RecipeGroupCarouselQueryQuery = {
  __typename?: 'Query';
  getProductByGroupCategoryId?: Array<{
    __typename?: 'ProductResponse';
    title: string;
    id: number;
    thumbUrl?: string | null;
    slugUrl?: string | null;
  } | null> | null;
};
export type RecipeGroupChildrenQueryQueryVariables = Exact<{
  data: GetChildrenGroupCategoryRequest;
}>;
export type RecipeGroupChildrenQueryQuery = {
  __typename?: 'Query';
  getChildrenGroupCategory: Array<{
    __typename?: 'GroupCategoryResponse';
    id: number;
    title?: string | null;
  }>;
};
export type RecipeGroupDetailQueryQueryVariables = Exact<{
  data: GetGroupCategoryRequest;
}>;
export type RecipeGroupDetailQueryQuery = {
  __typename?: 'Query';
  getGroupCategory: {
    __typename?: 'GroupCategoryResponse';
    description?: string | null;
    id: number;
    imageIconUrl?: string | null;
    mainColor?: string | null;
    mainCoverMobileUrl?: string | null;
    mainCoverUrl?: string | null;
    subMainColor?: string | null;
    title?: string | null;
  };
};
export type RecipeListByGroupChildrenQueryQueryVariables = Exact<{
  where: GetRecipeByGroupCategoryIdRequest;
  pagination?: InputMaybe<PaginationRequest>;
}>;
export type RecipeListByGroupChildrenQueryQuery = {
  __typename?: 'Query';
  getRecipeByGroupCategoryId: {
    __typename?: 'GetAllRecipeRelateResponse';
    total: number;
    recipes: Array<{
      __typename?: 'RecipeRelateResponse';
      slugUrl?: string | null;
      title: string;
      id: number;
      thumbUrl?: string | null;
      recipeMeatCategory?: Array<{
        __typename?: 'MeatCategoryResponse';
        id: number;
        title: string;
      } | null> | null;
    }>;
  };
};
export type AllRecipeHackQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type AllRecipeHackQueryQuery = {
  __typename?: 'Query';
  getAllRecipeHack?: Array<{
    __typename?: 'RecipeHackResponse';
    id: number;
    title: string;
  }> | null;
};
export type RecipeByHackIdQueryQueryVariables = Exact<{
  data: GetRecipeByHackIdRequest;
}>;
export type RecipeByHackIdQueryQuery = {
  __typename?: 'Query';
  getRecipeByHackId: Array<{
    __typename?: 'RecipeRelateResponse';
    id: number;
    title: string;
    thumbUrl?: string | null;
    slugUrl?: string | null;
    recipeMeatCategory?: Array<{
      __typename?: 'MeatCategoryResponse';
      id: number;
      title: string;
    } | null> | null;
  }>;
};
export type RecipeDetailQueryQueryVariables = Exact<{
  data: GetRecipeRequest;
}>;
export type RecipeDetailQueryQuery = {
  __typename?: 'Query';
  getRecipe: {
    __typename?: 'RecipeRelateResponse';
    id: number;
    title: string;
    description?: string | null;
    slugUrl?: string | null;
    people: number;
    toPeople?: number | null;
    ingredientImage?: string | null;
    cooking?: string | null;
    time: string;
    youtubeUrl?: string | null;
    coverImageUrl?: string | null;
    creator?: {
      __typename?: 'CreatorResponse';
      id: number;
      name: string;
      imageUrl: string;
      slugUrl: string;
    } | null;
    recipeIngredient?: Array<{
      __typename?: 'RecipeRelateIngredientsResponse';
      id: number;
      unit?: string | null;
      quantity?: string | null;
      ingredientType?: IngredientType | null;
      linkoutUrl?: string | null;
      ingredient?: {
        __typename?: 'IngredientsResponse';
        id: number;
        title: string;
      } | null;
    } | null> | null;
    recipeProduct?: Array<{
      __typename?: 'RelateWithProductResponse';
      id: number;
      unit?: string | null;
      quantity?: string | null;
      linkoutUrl?: string | null;
      product?: {
        __typename?: 'ProductResponse';
        id: number;
        title: string;
      } | null;
    } | null> | null;
  };
};
export type GetRecipeRecommendMenuQueryQueryVariables = Exact<{
  where: GetGroupCategoryRelateRecipeByIdRequest;
}>;
export type GetRecipeRecommendMenuQueryQuery = {
  __typename?: 'Query';
  getGroupCategoryRelateRecipeById?: {
    __typename?: 'GroupCategoryWithRecipeResponse';
    id: number;
    title?: string | null;
    bgCoverUrl?: string | null;
    imageIconUrl?: string | null;
    mainColor?: string | null;
    slugUrl?: string | null;
    recipeList?: Array<{
      __typename?: 'RecipeResponse';
      id: number;
      thumbUrl?: string | null;
      title: string;
      slugUrl?: string | null;
    } | null> | null;
  } | null;
};
export type GetRecipeRecommendTagQueryQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationRequest>;
}>;
export type GetRecipeRecommendTagQueryQuery = {
  __typename?: 'Query';
  getAllGroupCategory?: Array<{
    __typename?: 'GroupCategoryResponse';
    id: number;
    title?: string | null;
  }> | null;
};
export type GetRecipeRelateRecipeByMemberInterestingQueryVariables = Exact<{
  where: GetRecipeRelateRecipeCategoryRequest;
}>;
export type GetRecipeRelateRecipeByMemberInterestingQuery = {
  __typename?: 'Query';
  getRecipeRelateRecipeByMemberInteresting: Array<{
    __typename?: 'RecipeRelateRecipeResponse';
    id: number;
    title: string;
    slugUrl?: string | null;
    thumbUrl?: string | null;
    tags?: Array<string> | null;
    people: number;
  }>;
};
export type RecipeRelatedProductQueryQueryVariables = Exact<{
  data: GetRecipeRequest;
}>;
export type RecipeRelatedProductQueryQuery = {
  __typename?: 'Query';
  getRecipe: {
    __typename?: 'RecipeRelateResponse';
    recipeProduct?: Array<{
      __typename?: 'RelateWithProductResponse';
      id: number;
      product?: {
        __typename?: 'ProductResponse';
        id: number;
        tag?: Array<string | null> | null;
        title: string;
        slugUrl?: string | null;
        thumbUrl?: string | null;
        weight?: string | null;
      } | null;
    } | null> | null;
  };
};
export type GetRecipeRelateRecipeQueryVariables = Exact<{
  where: GetRecipeRelateRecipeCategoryRequest;
}>;
export type GetRecipeRelateRecipeQuery = {
  __typename?: 'Query';
  getRecipeRelateRecipe: Array<{
    __typename?: 'RecipeRelateRecipeResponse';
    id: number;
    title: string;
    slugUrl?: string | null;
    thumbUrl?: string | null;
    tags?: Array<string> | null;
    people: number;
  }>;
};
export type GetRegisterBannerQueryQueryVariables = Exact<{
  where: GetRegisterBannerRequest;
}>;
export type GetRegisterBannerQueryQuery = {
  __typename?: 'Query';
  getRegisterBanner: {
    __typename?: 'RegisterBannerResponse';
    id: number;
    title: string;
    bannerDesktop: string;
    bannerMobile: string;
    linkoutUrl?: string | null;
  };
};
export type GetSocialMediaBannerQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetSocialMediaBannerQuery = {
  __typename?: 'Query';
  getRegisterBannerList?: Array<{
    __typename?: 'RegisterBannerResponse';
    id: number;
    title: string;
    bannerDesktop: string;
    bannerMobile: string;
    linkoutUrl?: string | null;
  }> | null;
};
export type PopularSearchQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type PopularSearchQueryQuery = {
  __typename?: 'Query';
  getPopularSearch?: Array<{
    __typename?: 'PopularSearchResponse';
    id: number;
    slugUrl: string;
    title: string;
    type: PopularSearchType;
  }> | null;
};
export type PopularSearchAutoSuggestionQueryQueryVariables = Exact<{
  where: GetPopularSearchAutoSuggestionRequest;
}>;
export type PopularSearchAutoSuggestionQueryQuery = {
  __typename?: 'Query';
  getPopularSearchAutoSuggestion?: {
    __typename?: 'PopularSearchAutoSuggestionResponse';
    products?: Array<{
      __typename?: 'PopularSearchResponse';
      title: string;
      id: number;
      slugUrl: string;
    }> | null;
    recipes?: Array<{
      __typename?: 'PopularSearchResponse';
      title: string;
      slugUrl: string;
    }> | null;
    tipsTricks?: Array<{
      __typename?: 'PopularSearchResponse';
      id: number;
      slugUrl: string;
      title: string;
    }> | null;
  } | null;
};
export type SearchResultQueryQueryVariables = Exact<{
  where: GetSearchResultRequest;
  pagination: PaginationRequest;
}>;
export type SearchResultQueryQuery = {
  __typename?: 'Query';
  getSearchResult: {
    __typename?: 'SearchResultResponse';
    productTotal: number;
    recipeTotal: number;
    tipsTrickTotal: number;
    data?: {
      __typename?: 'DataSearchResponse';
      products?: Array<{
        __typename?: 'ProductResponse';
        id: number;
        title: string;
        slugUrl?: string | null;
        tag?: Array<string | null> | null;
        thumbUrl?: string | null;
        weight?: string | null;
        updatedAt?: any | null;
      }> | null;
      recipes?: Array<{
        __typename?: 'RecipeRelateResponse';
        id: number;
        title: string;
        thumbUrl?: string | null;
        slugUrl?: string | null;
        updatedAt?: any | null;
        recipeMeatCategory?: Array<{
          __typename?: 'MeatCategoryResponse';
          title: string;
        } | null> | null;
      }> | null;
      tipsTricks?: Array<{
        __typename?: 'TipsTricksWithCategoryResponse';
        id: number;
        title: string;
        description: string;
        publishedAt?: any | null;
        slugUrl?: string | null;
        thumbUrl?: string | null;
        category?: Array<{
          __typename?: 'TipsTricksCategoryResponse';
          title: string;
        }> | null;
      }> | null;
    } | null;
  };
};
export type SeoQueryVariables = Exact<{
  data: GetFeedSeObyPathRequest;
}>;
export type SeoQuery = {
  __typename?: 'Query';
  getFeedSEOByPath?: {
    __typename?: 'FeedSEOResponse';
    title?: string | null;
    description?: string | null;
    image?: string | null;
    keywords?: string | null;
  } | null;
};
export type SurpriseMenuResultQueryQueryVariables = Exact<{
  data: GetSurpriseMenuResultRequest;
}>;
export type SurpriseMenuResultQueryQuery = {
  __typename?: 'Query';
  getSurpriseMenuResult?: Array<{
    __typename?: 'RecipeResponse';
    id: number;
    thumbUrl?: string | null;
    title: string;
    slugUrl?: string | null;
  }> | null;
};
export type GetSurpriseMenuFilterQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetSurpriseMenuFilterQueryQuery = {
  __typename?: 'Query';
  getAllFilterMeatCategory?: Array<{
    __typename?: 'MeatCategoryResponse';
    id: number;
    title: string;
    surpriseMenuImgUrl?: string | null;
  }> | null;
  getAllFilterRecipeMethod?: Array<{
    __typename?: 'RecipeMethodResponse';
    id: number;
    title: string;
    surpriseMenuImgUrl?: string | null;
  }> | null;
};
export type TipsTricksHighlightBannerQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type TipsTricksHighlightBannerQueryQuery = {
  __typename?: 'Query';
  getAllTipsAndTricksHighlight: Array<{
    __typename?: 'TipsTricksResponse';
    id: number;
    title: string;
    description: string;
    detail: string;
    slugUrl?: string | null;
    imageUrl?: string | null;
  }>;
};
export type PopularTipsQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type PopularTipsQueryQuery = {
  __typename?: 'Query';
  getTipsTricksPopular: Array<{
    __typename?: 'TipsTricksWithCategoryResponse';
    id: number;
    title: string;
    description: string;
    detail: string;
    slugUrl?: string | null;
    thumbUrl?: string | null;
    publishedAt?: any | null;
    category?: Array<{
      __typename?: 'TipsTricksCategoryResponse';
      title: string;
    }> | null;
  }>;
};
export type GetRecommendTipsTricksQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetRecommendTipsTricksQuery = {
  __typename?: 'Query';
  getRecommendTipsTricks: Array<{
    __typename?: 'TipsTricksWithCategoryResponse';
    id: number;
    title: string;
    description: string;
    thumbUrl?: string | null;
    publishedAt?: any | null;
    slugUrl?: string | null;
    category?: Array<{
      __typename?: 'TipsTricksCategoryResponse';
      title: string;
    }> | null;
  }>;
};
export type TipDetailQueryQueryVariables = Exact<{
  where: GetTipsTricksDetailRequest;
}>;
export type TipDetailQueryQuery = {
  __typename?: 'Query';
  getTipsTricksDetail: {
    __typename?: 'TipsTricksDetailWithRelateDetailResponse';
    id: number;
    title: string;
    detail: string;
    slugUrl?: string | null;
    publishedAt?: any | null;
    creator?: {
      __typename?: 'CreatorResponse';
      id: number;
      name: string;
      imageUrl: string;
      slugUrl: string;
    } | null;
    tipsTricksCategory: Array<{
      __typename?: 'TipsTricksCategoryResponse';
      id: number;
      title: string;
    }>;
  };
};
export type TipsTricksByCategoryIdQueryQueryVariables = Exact<{
  where: GetTipsTricksByCategoryIdRequest;
  pagination?: InputMaybe<PaginationRequest>;
}>;
export type TipsTricksByCategoryIdQueryQuery = {
  __typename?: 'Query';
  getTipsTricksByCategoryId?: {
    __typename?: 'TipsTricksWithPaginationResponse';
    total: number;
    tipsTricks?: Array<{
      __typename?: 'TipsTricksWithCategoryResponse';
      id: number;
      title: string;
      description: string;
      slugUrl?: string | null;
      thumbUrl?: string | null;
      publishedAt?: any | null;
      category?: Array<{
        __typename?: 'TipsTricksCategoryResponse';
        id: number;
        title: string;
      }> | null;
    }> | null;
  } | null;
};
export type TipsTricksCategoryQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type TipsTricksCategoryQueryQuery = {
  __typename?: 'Query';
  getTipsTricksCategory: Array<{
    __typename?: 'TipsTricksCategoryResponse';
    id: number;
    title: string;
  }>;
};
export type GetTikTokHashtagQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetTikTokHashtagQueryQuery = {
  __typename?: 'Query';
  getTiktokHashtag: Array<{
    __typename?: 'TiktokHashtagResponse';
    id: number;
    title: string;
    hashtag: string;
  }>;
};
export type GetTikTokVdoByHashtagQueryQueryVariables = Exact<{
  where: GetTiktokVideoByHashtagRequest;
}>;
export type GetTikTokVdoByHashtagQueryQuery = {
  __typename?: 'Query';
  getTiktokVideoByHashtag: Array<{
    __typename?: 'TiktokVideoResponse';
    id: number;
    videoUrl: string;
    thumbUrl?: string | null;
    tiktokThumbUrl?: string | null;
    viewCount: number;
  }>;
};
export type GetUserProfileQueryQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetUserProfileQueryQuery = {
  __typename?: 'Query';
  cdpGetUserProfile?: {
    __typename?: 'CdpProfileResponse';
    uuid: string;
    firstName: string;
    lastName: string;
    gender: string;
    birthDate: number;
    phoneNumber: string;
    email?: string | null;
    point: number;
    imageUrl?: string | null;
    shouldShowInteresting: boolean;
  } | null;
};
export const GetActivityByMonthQueryDocument = gql`
    query GetActivityByMonthQuery($data: GetActivityListRequest, $pagination: PaginationRequest) {
  getActivityList(data: $data, pagination: $pagination) {
    total
    activities {
      id
      title
      description
      createdAt
      thumbUrl
      startedAt
      endedAt
      slugUrl
    }
  }
}
    `;

/**
 * __useGetActivityByMonthQueryQuery__
 *
 * To run a query within a React component, call `useGetActivityByMonthQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityByMonthQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityByMonthQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetActivityByMonthQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetActivityByMonthQueryQuery, GetActivityByMonthQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetActivityByMonthQueryQuery, GetActivityByMonthQueryQueryVariables>(GetActivityByMonthQueryDocument, options);
}
export function useGetActivityByMonthQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityByMonthQueryQuery, GetActivityByMonthQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetActivityByMonthQueryQuery, GetActivityByMonthQueryQueryVariables>(GetActivityByMonthQueryDocument, options);
}
export type GetActivityByMonthQueryQueryHookResult = ReturnType<typeof useGetActivityByMonthQueryQuery>;
export type GetActivityByMonthQueryLazyQueryHookResult = ReturnType<typeof useGetActivityByMonthQueryLazyQuery>;
export type GetActivityByMonthQueryQueryResult = Apollo.QueryResult<GetActivityByMonthQueryQuery, GetActivityByMonthQueryQueryVariables>;
export const GetActivityDetailDocument = gql`
    query GetActivityDetail($data: GetActivityDetailRequest!) {
  getActivityDetail(data: $data) {
    id
    creator {
      id
      imageUrl
      name
      slugUrl
    }
    detail
    imageUrl
    endedAt
    location
    startedAt
    title
    createdAt
    slugUrl
  }
}
    `;

/**
 * __useGetActivityDetailQuery__
 *
 * To run a query within a React component, call `useGetActivityDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityDetailQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetActivityDetailQuery(baseOptions: Apollo.QueryHookOptions<GetActivityDetailQuery, GetActivityDetailQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetActivityDetailQuery, GetActivityDetailQueryVariables>(GetActivityDetailDocument, options);
}
export function useGetActivityDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityDetailQuery, GetActivityDetailQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetActivityDetailQuery, GetActivityDetailQueryVariables>(GetActivityDetailDocument, options);
}
export type GetActivityDetailQueryHookResult = ReturnType<typeof useGetActivityDetailQuery>;
export type GetActivityDetailLazyQueryHookResult = ReturnType<typeof useGetActivityDetailLazyQuery>;
export type GetActivityDetailQueryResult = Apollo.QueryResult<GetActivityDetailQuery, GetActivityDetailQueryVariables>;
export const GetActivityFilterQueryDocument = gql`
    query GetActivityFilterQuery {
  getActivityFilter {
    label
    options {
      label
      value
    }
  }
}
    `;

/**
 * __useGetActivityFilterQueryQuery__
 *
 * To run a query within a React component, call `useGetActivityFilterQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityFilterQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityFilterQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActivityFilterQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetActivityFilterQueryQuery, GetActivityFilterQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetActivityFilterQueryQuery, GetActivityFilterQueryQueryVariables>(GetActivityFilterQueryDocument, options);
}
export function useGetActivityFilterQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityFilterQueryQuery, GetActivityFilterQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetActivityFilterQueryQuery, GetActivityFilterQueryQueryVariables>(GetActivityFilterQueryDocument, options);
}
export type GetActivityFilterQueryQueryHookResult = ReturnType<typeof useGetActivityFilterQueryQuery>;
export type GetActivityFilterQueryLazyQueryHookResult = ReturnType<typeof useGetActivityFilterQueryLazyQuery>;
export type GetActivityFilterQueryQueryResult = Apollo.QueryResult<GetActivityFilterQueryQuery, GetActivityFilterQueryQueryVariables>;
export const GetRelatedActivityDocument = gql`
    query GetRelatedActivity($data: GetLatestActivityRequest!) {
  getLatestActivity(data: $data) {
    id
    title
    thumbUrl
    description
    endedAt
    startedAt
    slugUrl
  }
}
    `;

/**
 * __useGetRelatedActivityQuery__
 *
 * To run a query within a React component, call `useGetRelatedActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelatedActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelatedActivityQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetRelatedActivityQuery(baseOptions: Apollo.QueryHookOptions<GetRelatedActivityQuery, GetRelatedActivityQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetRelatedActivityQuery, GetRelatedActivityQueryVariables>(GetRelatedActivityDocument, options);
}
export function useGetRelatedActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRelatedActivityQuery, GetRelatedActivityQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetRelatedActivityQuery, GetRelatedActivityQueryVariables>(GetRelatedActivityDocument, options);
}
export type GetRelatedActivityQueryHookResult = ReturnType<typeof useGetRelatedActivityQuery>;
export type GetRelatedActivityLazyQueryHookResult = ReturnType<typeof useGetRelatedActivityLazyQuery>;
export type GetRelatedActivityQueryResult = Apollo.QueryResult<GetRelatedActivityQuery, GetRelatedActivityQueryVariables>;
export const GetTokenMutationDocument = gql`
    mutation GetTokenMutation($data: CdpLoginRequest!) {
  cdpLogin(data: $data) {
    accessToken
  }
}
    `;
export type GetTokenMutationMutationFn = Apollo.MutationFunction<GetTokenMutationMutation, GetTokenMutationMutationVariables>;

/**
 * __useGetTokenMutationMutation__
 *
 * To run a mutation, you first call `useGetTokenMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetTokenMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getTokenMutationMutation, { data, loading, error }] = useGetTokenMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetTokenMutationMutation(baseOptions?: Apollo.MutationHookOptions<GetTokenMutationMutation, GetTokenMutationMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useMutation<GetTokenMutationMutation, GetTokenMutationMutationVariables>(GetTokenMutationDocument, options);
}
export type GetTokenMutationMutationHookResult = ReturnType<typeof useGetTokenMutationMutation>;
export type GetTokenMutationMutationResult = Apollo.MutationResult<GetTokenMutationMutation>;
export type GetTokenMutationMutationOptions = Apollo.BaseMutationOptions<GetTokenMutationMutation, GetTokenMutationMutationVariables>;
export const RenewTokenMutationDocument = gql`
    mutation RenewTokenMutation {
  cdpRenewToken {
    accessToken
  }
}
    `;
export type RenewTokenMutationMutationFn = Apollo.MutationFunction<RenewTokenMutationMutation, RenewTokenMutationMutationVariables>;

/**
 * __useRenewTokenMutationMutation__
 *
 * To run a mutation, you first call `useRenewTokenMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewTokenMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewTokenMutationMutation, { data, loading, error }] = useRenewTokenMutationMutation({
 *   variables: {
 *   },
 * });
 */
export function useRenewTokenMutationMutation(baseOptions?: Apollo.MutationHookOptions<RenewTokenMutationMutation, RenewTokenMutationMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useMutation<RenewTokenMutationMutation, RenewTokenMutationMutationVariables>(RenewTokenMutationDocument, options);
}
export type RenewTokenMutationMutationHookResult = ReturnType<typeof useRenewTokenMutationMutation>;
export type RenewTokenMutationMutationResult = Apollo.MutationResult<RenewTokenMutationMutation>;
export type RenewTokenMutationMutationOptions = Apollo.BaseMutationOptions<RenewTokenMutationMutation, RenewTokenMutationMutationVariables>;
export const RevokeTokenMutationDocument = gql`
    mutation RevokeTokenMutation {
  cdpLogout
}
    `;
export type RevokeTokenMutationMutationFn = Apollo.MutationFunction<RevokeTokenMutationMutation, RevokeTokenMutationMutationVariables>;

/**
 * __useRevokeTokenMutationMutation__
 *
 * To run a mutation, you first call `useRevokeTokenMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeTokenMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeTokenMutationMutation, { data, loading, error }] = useRevokeTokenMutationMutation({
 *   variables: {
 *   },
 * });
 */
export function useRevokeTokenMutationMutation(baseOptions?: Apollo.MutationHookOptions<RevokeTokenMutationMutation, RevokeTokenMutationMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useMutation<RevokeTokenMutationMutation, RevokeTokenMutationMutationVariables>(RevokeTokenMutationDocument, options);
}
export type RevokeTokenMutationMutationHookResult = ReturnType<typeof useRevokeTokenMutationMutation>;
export type RevokeTokenMutationMutationResult = Apollo.MutationResult<RevokeTokenMutationMutation>;
export type RevokeTokenMutationMutationOptions = Apollo.BaseMutationOptions<RevokeTokenMutationMutation, RevokeTokenMutationMutationVariables>;
export const GetBannerByPageDocument = gql`
    query GetBannerByPage($where: GetBannerByPageRequest!) {
  getBannerByPage(where: $where) {
    highlight {
      id
      bannerDesktop
      bannerMobile
      linkOutUrl
    }
    bottom {
      id
      bannerDesktop
      bannerMobile
      linkOutUrl
    }
  }
}
    `;

/**
 * __useGetBannerByPageQuery__
 *
 * To run a query within a React component, call `useGetBannerByPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBannerByPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBannerByPageQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBannerByPageQuery(baseOptions: Apollo.QueryHookOptions<GetBannerByPageQuery, GetBannerByPageQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetBannerByPageQuery, GetBannerByPageQueryVariables>(GetBannerByPageDocument, options);
}
export function useGetBannerByPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBannerByPageQuery, GetBannerByPageQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetBannerByPageQuery, GetBannerByPageQueryVariables>(GetBannerByPageDocument, options);
}
export type GetBannerByPageQueryHookResult = ReturnType<typeof useGetBannerByPageQuery>;
export type GetBannerByPageLazyQueryHookResult = ReturnType<typeof useGetBannerByPageLazyQuery>;
export type GetBannerByPageQueryResult = Apollo.QueryResult<GetBannerByPageQuery, GetBannerByPageQueryVariables>;
export const AllCollectionQueryDocument = gql`
    query AllCollectionQuery($pagination: PaginationRequest) {
  getAllCollection(pagination: $pagination) {
    total
    collections {
      id
      title
      slugUrl
      recipeListTotal
      recipeList {
        id
        slugUrl
        thumbUrl
      }
    }
  }
}
    `;

/**
 * __useAllCollectionQueryQuery__
 *
 * To run a query within a React component, call `useAllCollectionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCollectionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCollectionQueryQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAllCollectionQueryQuery(baseOptions?: Apollo.QueryHookOptions<AllCollectionQueryQuery, AllCollectionQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<AllCollectionQueryQuery, AllCollectionQueryQueryVariables>(AllCollectionQueryDocument, options);
}
export function useAllCollectionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCollectionQueryQuery, AllCollectionQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<AllCollectionQueryQuery, AllCollectionQueryQueryVariables>(AllCollectionQueryDocument, options);
}
export type AllCollectionQueryQueryHookResult = ReturnType<typeof useAllCollectionQueryQuery>;
export type AllCollectionQueryLazyQueryHookResult = ReturnType<typeof useAllCollectionQueryLazyQuery>;
export type AllCollectionQueryQueryResult = Apollo.QueryResult<AllCollectionQueryQuery, AllCollectionQueryQueryVariables>;
export const CollectionDetailQueryDocument = gql`
    query CollectionDetailQuery($where: GetCollectionDetailRequest!) {
  getCollectionDetail(where: $where) {
    id
    slugUrl
    thumbUrl
    title
    bgCover
    video
    description
    recipeListTotal
    recipeList {
      id
      slugUrl
      title
      thumbUrl
      recipeProduct {
        product {
          id
          slugUrl
          thumbUrl
          title
          tag
          weight
        }
      }
    }
  }
}
    `;

/**
 * __useCollectionDetailQueryQuery__
 *
 * To run a query within a React component, call `useCollectionDetailQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionDetailQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionDetailQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCollectionDetailQueryQuery(baseOptions: Apollo.QueryHookOptions<CollectionDetailQueryQuery, CollectionDetailQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<CollectionDetailQueryQuery, CollectionDetailQueryQueryVariables>(CollectionDetailQueryDocument, options);
}
export function useCollectionDetailQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CollectionDetailQueryQuery, CollectionDetailQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<CollectionDetailQueryQuery, CollectionDetailQueryQueryVariables>(CollectionDetailQueryDocument, options);
}
export type CollectionDetailQueryQueryHookResult = ReturnType<typeof useCollectionDetailQueryQuery>;
export type CollectionDetailQueryLazyQueryHookResult = ReturnType<typeof useCollectionDetailQueryLazyQuery>;
export type CollectionDetailQueryQueryResult = Apollo.QueryResult<CollectionDetailQueryQuery, CollectionDetailQueryQueryVariables>;
export const NewCollectionQueryDocument = gql`
    query NewCollectionQuery {
  getHighlightCollection {
    id
    title
    slugUrl
    thumbUrl
    video
    description
    recipeListTotal
    recipeList {
      id
      title
      slugUrl
      thumbUrl
    }
  }
}
    `;

/**
 * __useNewCollectionQueryQuery__
 *
 * To run a query within a React component, call `useNewCollectionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewCollectionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewCollectionQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewCollectionQueryQuery(baseOptions?: Apollo.QueryHookOptions<NewCollectionQueryQuery, NewCollectionQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<NewCollectionQueryQuery, NewCollectionQueryQueryVariables>(NewCollectionQueryDocument, options);
}
export function useNewCollectionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewCollectionQueryQuery, NewCollectionQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<NewCollectionQueryQuery, NewCollectionQueryQueryVariables>(NewCollectionQueryDocument, options);
}
export type NewCollectionQueryQueryHookResult = ReturnType<typeof useNewCollectionQueryQuery>;
export type NewCollectionQueryLazyQueryHookResult = ReturnType<typeof useNewCollectionQueryLazyQuery>;
export type NewCollectionQueryQueryResult = Apollo.QueryResult<NewCollectionQueryQuery, NewCollectionQueryQueryVariables>;
export const PopularCollectionQueryDocument = gql`
    query PopularCollectionQuery {
  getPopularCollection {
    id
    title
    colorTitlePopular
    thumbUrl
    colorMenuPopular
    thumbPopular
    recipeListTotal
    recipeList {
      id
      slugUrl
      title
      thumbUrl
    }
  }
}
    `;

/**
 * __usePopularCollectionQueryQuery__
 *
 * To run a query within a React component, call `usePopularCollectionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularCollectionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularCollectionQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function usePopularCollectionQueryQuery(baseOptions?: Apollo.QueryHookOptions<PopularCollectionQueryQuery, PopularCollectionQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<PopularCollectionQueryQuery, PopularCollectionQueryQueryVariables>(PopularCollectionQueryDocument, options);
}
export function usePopularCollectionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PopularCollectionQueryQuery, PopularCollectionQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<PopularCollectionQueryQuery, PopularCollectionQueryQueryVariables>(PopularCollectionQueryDocument, options);
}
export type PopularCollectionQueryQueryHookResult = ReturnType<typeof usePopularCollectionQueryQuery>;
export type PopularCollectionQueryLazyQueryHookResult = ReturnType<typeof usePopularCollectionQueryLazyQuery>;
export type PopularCollectionQueryQueryResult = Apollo.QueryResult<PopularCollectionQueryQuery, PopularCollectionQueryQueryVariables>;
export const GetCollectionRelatedProductQueryDocument = gql`
    query GetCollectionRelatedProductQuery($where: GetCollectionDetailRequest!) {
  getCollectionDetail(where: $where) {
    recipeList {
      recipeProduct {
        product {
          id
          slugUrl
          thumbUrl
          title
          tag
          weight
        }
      }
    }
  }
}
    `;

/**
 * __useGetCollectionRelatedProductQueryQuery__
 *
 * To run a query within a React component, call `useGetCollectionRelatedProductQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionRelatedProductQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionRelatedProductQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCollectionRelatedProductQueryQuery(baseOptions: Apollo.QueryHookOptions<GetCollectionRelatedProductQueryQuery, GetCollectionRelatedProductQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetCollectionRelatedProductQueryQuery, GetCollectionRelatedProductQueryQueryVariables>(GetCollectionRelatedProductQueryDocument, options);
}
export function useGetCollectionRelatedProductQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionRelatedProductQueryQuery, GetCollectionRelatedProductQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetCollectionRelatedProductQueryQuery, GetCollectionRelatedProductQueryQueryVariables>(GetCollectionRelatedProductQueryDocument, options);
}
export type GetCollectionRelatedProductQueryQueryHookResult = ReturnType<typeof useGetCollectionRelatedProductQueryQuery>;
export type GetCollectionRelatedProductQueryLazyQueryHookResult = ReturnType<typeof useGetCollectionRelatedProductQueryLazyQuery>;
export type GetCollectionRelatedProductQueryQueryResult = Apollo.QueryResult<GetCollectionRelatedProductQueryQuery, GetCollectionRelatedProductQueryQueryVariables>;
export const GetCpConnectQueryDocument = gql`
    query GetCpConnectQuery($data: GetCpConnectRequest) {
  getCpConnect(data: $data) {
    title
    mainCoverUrl
    linkOutUrl
    imageLogoUrl
    id
    description
  }
}
    `;

/**
 * __useGetCpConnectQueryQuery__
 *
 * To run a query within a React component, call `useGetCpConnectQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCpConnectQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCpConnectQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetCpConnectQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetCpConnectQueryQuery, GetCpConnectQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetCpConnectQueryQuery, GetCpConnectQueryQueryVariables>(GetCpConnectQueryDocument, options);
}
export function useGetCpConnectQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCpConnectQueryQuery, GetCpConnectQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetCpConnectQueryQuery, GetCpConnectQueryQueryVariables>(GetCpConnectQueryDocument, options);
}
export type GetCpConnectQueryQueryHookResult = ReturnType<typeof useGetCpConnectQueryQuery>;
export type GetCpConnectQueryLazyQueryHookResult = ReturnType<typeof useGetCpConnectQueryLazyQuery>;
export type GetCpConnectQueryQueryResult = Apollo.QueryResult<GetCpConnectQueryQuery, GetCpConnectQueryQueryVariables>;
export const UpdateMemberFavoriteDocument = gql`
    mutation UpdateMemberFavorite($data: UpdateMemberFavoriteRequest!) {
  updateMemberFavorite(data: $data) {
    isFavorite
  }
}
    `;
export type UpdateMemberFavoriteMutationFn = Apollo.MutationFunction<UpdateMemberFavoriteMutation, UpdateMemberFavoriteMutationVariables>;

/**
 * __useUpdateMemberFavoriteMutation__
 *
 * To run a mutation, you first call `useUpdateMemberFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberFavoriteMutation, { data, loading, error }] = useUpdateMemberFavoriteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMemberFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberFavoriteMutation, UpdateMemberFavoriteMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useMutation<UpdateMemberFavoriteMutation, UpdateMemberFavoriteMutationVariables>(UpdateMemberFavoriteDocument, options);
}
export type UpdateMemberFavoriteMutationHookResult = ReturnType<typeof useUpdateMemberFavoriteMutation>;
export type UpdateMemberFavoriteMutationResult = Apollo.MutationResult<UpdateMemberFavoriteMutation>;
export type UpdateMemberFavoriteMutationOptions = Apollo.BaseMutationOptions<UpdateMemberFavoriteMutation, UpdateMemberFavoriteMutationVariables>;
export const GetMemberFavoriteCollectionQueryDocument = gql`
    query GetMemberFavoriteCollectionQuery($data: GetMemberFavoriteCollectionRequest) {
  getMemberFavoriteCollection(data: $data)
}
    `;

/**
 * __useGetMemberFavoriteCollectionQueryQuery__
 *
 * To run a query within a React component, call `useGetMemberFavoriteCollectionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberFavoriteCollectionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberFavoriteCollectionQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetMemberFavoriteCollectionQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberFavoriteCollectionQueryQuery, GetMemberFavoriteCollectionQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetMemberFavoriteCollectionQueryQuery, GetMemberFavoriteCollectionQueryQueryVariables>(GetMemberFavoriteCollectionQueryDocument, options);
}
export function useGetMemberFavoriteCollectionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberFavoriteCollectionQueryQuery, GetMemberFavoriteCollectionQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetMemberFavoriteCollectionQueryQuery, GetMemberFavoriteCollectionQueryQueryVariables>(GetMemberFavoriteCollectionQueryDocument, options);
}
export type GetMemberFavoriteCollectionQueryQueryHookResult = ReturnType<typeof useGetMemberFavoriteCollectionQueryQuery>;
export type GetMemberFavoriteCollectionQueryLazyQueryHookResult = ReturnType<typeof useGetMemberFavoriteCollectionQueryLazyQuery>;
export type GetMemberFavoriteCollectionQueryQueryResult = Apollo.QueryResult<GetMemberFavoriteCollectionQueryQuery, GetMemberFavoriteCollectionQueryQueryVariables>;
export const GetMemberFavoriteProductQueryDocument = gql`
    query GetMemberFavoriteProductQuery($data: GetMemberFavoriteProductRequest) {
  getMemberFavoriteProduct(data: $data)
}
    `;

/**
 * __useGetMemberFavoriteProductQueryQuery__
 *
 * To run a query within a React component, call `useGetMemberFavoriteProductQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberFavoriteProductQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberFavoriteProductQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetMemberFavoriteProductQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberFavoriteProductQueryQuery, GetMemberFavoriteProductQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetMemberFavoriteProductQueryQuery, GetMemberFavoriteProductQueryQueryVariables>(GetMemberFavoriteProductQueryDocument, options);
}
export function useGetMemberFavoriteProductQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberFavoriteProductQueryQuery, GetMemberFavoriteProductQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetMemberFavoriteProductQueryQuery, GetMemberFavoriteProductQueryQueryVariables>(GetMemberFavoriteProductQueryDocument, options);
}
export type GetMemberFavoriteProductQueryQueryHookResult = ReturnType<typeof useGetMemberFavoriteProductQueryQuery>;
export type GetMemberFavoriteProductQueryLazyQueryHookResult = ReturnType<typeof useGetMemberFavoriteProductQueryLazyQuery>;
export type GetMemberFavoriteProductQueryQueryResult = Apollo.QueryResult<GetMemberFavoriteProductQueryQuery, GetMemberFavoriteProductQueryQueryVariables>;
export const GetMemberFavoriteRecipeQueryDocument = gql`
    query GetMemberFavoriteRecipeQuery($data: GetMemberFavoriteRecipeRequest) {
  getMemberFavoriteRecipe(data: $data)
}
    `;

/**
 * __useGetMemberFavoriteRecipeQueryQuery__
 *
 * To run a query within a React component, call `useGetMemberFavoriteRecipeQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberFavoriteRecipeQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberFavoriteRecipeQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetMemberFavoriteRecipeQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberFavoriteRecipeQueryQuery, GetMemberFavoriteRecipeQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetMemberFavoriteRecipeQueryQuery, GetMemberFavoriteRecipeQueryQueryVariables>(GetMemberFavoriteRecipeQueryDocument, options);
}
export function useGetMemberFavoriteRecipeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberFavoriteRecipeQueryQuery, GetMemberFavoriteRecipeQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetMemberFavoriteRecipeQueryQuery, GetMemberFavoriteRecipeQueryQueryVariables>(GetMemberFavoriteRecipeQueryDocument, options);
}
export type GetMemberFavoriteRecipeQueryQueryHookResult = ReturnType<typeof useGetMemberFavoriteRecipeQueryQuery>;
export type GetMemberFavoriteRecipeQueryLazyQueryHookResult = ReturnType<typeof useGetMemberFavoriteRecipeQueryLazyQuery>;
export type GetMemberFavoriteRecipeQueryQueryResult = Apollo.QueryResult<GetMemberFavoriteRecipeQueryQuery, GetMemberFavoriteRecipeQueryQueryVariables>;
export const GetMemberFavoriteResultQueryDocument = gql`
    query GetMemberFavoriteResultQuery($where: GetMemberFavoriteResultRequest!) {
  getMemberFavoriteResult(where: $where) {
    recipeTotal
    productTotal
    collectionTotal
    data {
      recipes {
        id
        title
        thumbUrl
        slugUrl
        updatedAt
        recipeMeatCategory {
          title
        }
      }
      products {
        id
        title
        thumbUrl
        slugUrl
        tag
        updatedAt
      }
      collections {
        id
        title
        thumbUrl
        video
        recipeListTotal
        slugUrl
      }
    }
  }
}
    `;

/**
 * __useGetMemberFavoriteResultQueryQuery__
 *
 * To run a query within a React component, call `useGetMemberFavoriteResultQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberFavoriteResultQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberFavoriteResultQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMemberFavoriteResultQueryQuery(baseOptions: Apollo.QueryHookOptions<GetMemberFavoriteResultQueryQuery, GetMemberFavoriteResultQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetMemberFavoriteResultQueryQuery, GetMemberFavoriteResultQueryQueryVariables>(GetMemberFavoriteResultQueryDocument, options);
}
export function useGetMemberFavoriteResultQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberFavoriteResultQueryQuery, GetMemberFavoriteResultQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetMemberFavoriteResultQueryQuery, GetMemberFavoriteResultQueryQueryVariables>(GetMemberFavoriteResultQueryDocument, options);
}
export type GetMemberFavoriteResultQueryQueryHookResult = ReturnType<typeof useGetMemberFavoriteResultQueryQuery>;
export type GetMemberFavoriteResultQueryLazyQueryHookResult = ReturnType<typeof useGetMemberFavoriteResultQueryLazyQuery>;
export type GetMemberFavoriteResultQueryQueryResult = Apollo.QueryResult<GetMemberFavoriteResultQueryQuery, GetMemberFavoriteResultQueryQueryVariables>;
export const GetFilterCategoryQueryDocument = gql`
    query GetFilterCategoryQuery {
  getAllFilterMeatCategory {
    id
    title
    logo
  }
  getAllFilterRecipeType {
    id
    title
  }
  getAllFilterRecipeMethod {
    id
    title
  }
  getAllFilterRecipeRegion {
    id
    title
  }
  getAllFilterTipsTricksCategory {
    id
    title
  }
  getAllBranchFilter {
    id
    title
  }
  getAllProductCategory {
    id
    title
  }
  getAllBrand {
    brandId
    title
    thumbnailUrl
  }
}
    `;

/**
 * __useGetFilterCategoryQueryQuery__
 *
 * To run a query within a React component, call `useGetFilterCategoryQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterCategoryQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterCategoryQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFilterCategoryQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetFilterCategoryQueryQuery, GetFilterCategoryQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetFilterCategoryQueryQuery, GetFilterCategoryQueryQueryVariables>(GetFilterCategoryQueryDocument, options);
}
export function useGetFilterCategoryQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterCategoryQueryQuery, GetFilterCategoryQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetFilterCategoryQueryQuery, GetFilterCategoryQueryQueryVariables>(GetFilterCategoryQueryDocument, options);
}
export type GetFilterCategoryQueryQueryHookResult = ReturnType<typeof useGetFilterCategoryQueryQuery>;
export type GetFilterCategoryQueryLazyQueryHookResult = ReturnType<typeof useGetFilterCategoryQueryLazyQuery>;
export type GetFilterCategoryQueryQueryResult = Apollo.QueryResult<GetFilterCategoryQueryQuery, GetFilterCategoryQueryQueryVariables>;
export const GroupCategoriesTrainDocument = gql`
    query GroupCategoriesTrain {
  getGroupCategoriesTrain {
    bottomContent {
      title
      slugUrl
      mainColor
      imageIconUrl
      id
      tooltipText
    }
    middleContent {
      title
      slugUrl
      mainColor
      imageIconUrl
      id
      tooltipText
    }
    topContent {
      title
      slugUrl
      mainColor
      imageIconUrl
      id
      tooltipText
    }
  }
}
    `;

/**
 * __useGroupCategoriesTrainQuery__
 *
 * To run a query within a React component, call `useGroupCategoriesTrainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupCategoriesTrainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupCategoriesTrainQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupCategoriesTrainQuery(baseOptions?: Apollo.QueryHookOptions<GroupCategoriesTrainQuery, GroupCategoriesTrainQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GroupCategoriesTrainQuery, GroupCategoriesTrainQueryVariables>(GroupCategoriesTrainDocument, options);
}
export function useGroupCategoriesTrainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupCategoriesTrainQuery, GroupCategoriesTrainQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GroupCategoriesTrainQuery, GroupCategoriesTrainQueryVariables>(GroupCategoriesTrainDocument, options);
}
export type GroupCategoriesTrainQueryHookResult = ReturnType<typeof useGroupCategoriesTrainQuery>;
export type GroupCategoriesTrainLazyQueryHookResult = ReturnType<typeof useGroupCategoriesTrainLazyQuery>;
export type GroupCategoriesTrainQueryResult = Apollo.QueryResult<GroupCategoriesTrainQuery, GroupCategoriesTrainQueryVariables>;
export const GetCategoryMenuQueryDocument = gql`
    query GetCategoryMenuQuery {
  getWhatShouldIEatGroupCategories {
    id
    title
    slugUrl
  }
  getWhatShouldIEatCollections {
    id
    title
    slugUrl
  }
  getWhatShouldIEatMeatCategories {
    id
    title
  }
  getWhatShouldIEatRecipeMethods {
    id
    title
  }
  getWhatShouldIEatRecipeTypes {
    id
    title
  }
}
    `;

/**
 * __useGetCategoryMenuQueryQuery__
 *
 * To run a query within a React component, call `useGetCategoryMenuQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryMenuQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryMenuQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoryMenuQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoryMenuQueryQuery, GetCategoryMenuQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetCategoryMenuQueryQuery, GetCategoryMenuQueryQueryVariables>(GetCategoryMenuQueryDocument, options);
}
export function useGetCategoryMenuQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryMenuQueryQuery, GetCategoryMenuQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetCategoryMenuQueryQuery, GetCategoryMenuQueryQueryVariables>(GetCategoryMenuQueryDocument, options);
}
export type GetCategoryMenuQueryQueryHookResult = ReturnType<typeof useGetCategoryMenuQueryQuery>;
export type GetCategoryMenuQueryLazyQueryHookResult = ReturnType<typeof useGetCategoryMenuQueryLazyQuery>;
export type GetCategoryMenuQueryQueryResult = Apollo.QueryResult<GetCategoryMenuQueryQuery, GetCategoryMenuQueryQueryVariables>;
export const HomeCampaignQueryDocument = gql`
    query HomeCampaignQuery {
  getLatestCampaign {
    id
    title
    description
    linkoutUrl
    thumbUrl
  }
}
    `;

/**
 * __useHomeCampaignQueryQuery__
 *
 * To run a query within a React component, call `useHomeCampaignQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeCampaignQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeCampaignQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeCampaignQueryQuery(baseOptions?: Apollo.QueryHookOptions<HomeCampaignQueryQuery, HomeCampaignQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<HomeCampaignQueryQuery, HomeCampaignQueryQueryVariables>(HomeCampaignQueryDocument, options);
}
export function useHomeCampaignQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeCampaignQueryQuery, HomeCampaignQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<HomeCampaignQueryQuery, HomeCampaignQueryQueryVariables>(HomeCampaignQueryDocument, options);
}
export type HomeCampaignQueryQueryHookResult = ReturnType<typeof useHomeCampaignQueryQuery>;
export type HomeCampaignQueryLazyQueryHookResult = ReturnType<typeof useHomeCampaignQueryLazyQuery>;
export type HomeCampaignQueryQueryResult = Apollo.QueryResult<HomeCampaignQueryQuery, HomeCampaignQueryQueryVariables>;
export const GameBannerQueryDocument = gql`
    query GameBannerQuery($where: GetBannerByPageRequest!) {
  getBannerByPage(where: $where) {
    highlight {
      id
      title
      bannerDesktop
      bannerMobile
      bannerIos
      linkOutUrl
      path
      bannerType
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGameBannerQueryQuery__
 *
 * To run a query within a React component, call `useGameBannerQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameBannerQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameBannerQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGameBannerQueryQuery(baseOptions: Apollo.QueryHookOptions<GameBannerQueryQuery, GameBannerQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GameBannerQueryQuery, GameBannerQueryQueryVariables>(GameBannerQueryDocument, options);
}
export function useGameBannerQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameBannerQueryQuery, GameBannerQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GameBannerQueryQuery, GameBannerQueryQueryVariables>(GameBannerQueryDocument, options);
}
export type GameBannerQueryQueryHookResult = ReturnType<typeof useGameBannerQueryQuery>;
export type GameBannerQueryLazyQueryHookResult = ReturnType<typeof useGameBannerQueryLazyQuery>;
export type GameBannerQueryQueryResult = Apollo.QueryResult<GameBannerQueryQuery, GameBannerQueryQueryVariables>;
export const GetHomePopupQueryDocument = gql`
    query GetHomePopupQuery {
  getPopup {
    status
    createdAt
    updatedAt
    deletedAt
    id
    title
    popupType
    youtubeUrl
    imageUrl
    linkOutUrl
    priority
    startedAt
    endedAt
  }
}
    `;

/**
 * __useGetHomePopupQueryQuery__
 *
 * To run a query within a React component, call `useGetHomePopupQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomePopupQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomePopupQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomePopupQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetHomePopupQueryQuery, GetHomePopupQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetHomePopupQueryQuery, GetHomePopupQueryQueryVariables>(GetHomePopupQueryDocument, options);
}
export function useGetHomePopupQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHomePopupQueryQuery, GetHomePopupQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetHomePopupQueryQuery, GetHomePopupQueryQueryVariables>(GetHomePopupQueryDocument, options);
}
export type GetHomePopupQueryQueryHookResult = ReturnType<typeof useGetHomePopupQueryQuery>;
export type GetHomePopupQueryLazyQueryHookResult = ReturnType<typeof useGetHomePopupQueryLazyQuery>;
export type GetHomePopupQueryQueryResult = Apollo.QueryResult<GetHomePopupQueryQuery, GetHomePopupQueryQueryVariables>;
export const RecommendProductQueryDocument = gql`
    query RecommendProductQuery {
  getRecommendedProduct {
    id
    slugUrl
    title
    tag
    thumbUrl
    weight
  }
}
    `;

/**
 * __useRecommendProductQueryQuery__
 *
 * To run a query within a React component, call `useRecommendProductQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendProductQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendProductQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecommendProductQueryQuery(baseOptions?: Apollo.QueryHookOptions<RecommendProductQueryQuery, RecommendProductQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<RecommendProductQueryQuery, RecommendProductQueryQueryVariables>(RecommendProductQueryDocument, options);
}
export function useRecommendProductQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecommendProductQueryQuery, RecommendProductQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<RecommendProductQueryQuery, RecommendProductQueryQueryVariables>(RecommendProductQueryDocument, options);
}
export type RecommendProductQueryQueryHookResult = ReturnType<typeof useRecommendProductQueryQuery>;
export type RecommendProductQueryLazyQueryHookResult = ReturnType<typeof useRecommendProductQueryLazyQuery>;
export type RecommendProductQueryQueryResult = Apollo.QueryResult<RecommendProductQueryQuery, RecommendProductQueryQueryVariables>;
export const TrendsForYouQueryDocument = gql`
    query TrendsForYouQuery {
  getTrendsForYou {
    hashtag
    id
    socials {
      name
      url
    }
  }
}
    `;

/**
 * __useTrendsForYouQueryQuery__
 *
 * To run a query within a React component, call `useTrendsForYouQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrendsForYouQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrendsForYouQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrendsForYouQueryQuery(baseOptions?: Apollo.QueryHookOptions<TrendsForYouQueryQuery, TrendsForYouQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<TrendsForYouQueryQuery, TrendsForYouQueryQueryVariables>(TrendsForYouQueryDocument, options);
}
export function useTrendsForYouQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrendsForYouQueryQuery, TrendsForYouQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<TrendsForYouQueryQuery, TrendsForYouQueryQueryVariables>(TrendsForYouQueryDocument, options);
}
export type TrendsForYouQueryQueryHookResult = ReturnType<typeof useTrendsForYouQueryQuery>;
export type TrendsForYouQueryLazyQueryHookResult = ReturnType<typeof useTrendsForYouQueryLazyQuery>;
export type TrendsForYouQueryQueryResult = Apollo.QueryResult<TrendsForYouQueryQuery, TrendsForYouQueryQueryVariables>;
export const SaveChoosedInterestingMutationDocument = gql`
    mutation SaveChoosedInterestingMutation($data: UpdateMemberInterestingRequest!) {
  updateMemberInteresting(data: $data)
}
    `;
export type SaveChoosedInterestingMutationMutationFn = Apollo.MutationFunction<SaveChoosedInterestingMutationMutation, SaveChoosedInterestingMutationMutationVariables>;

/**
 * __useSaveChoosedInterestingMutationMutation__
 *
 * To run a mutation, you first call `useSaveChoosedInterestingMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveChoosedInterestingMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveChoosedInterestingMutationMutation, { data, loading, error }] = useSaveChoosedInterestingMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveChoosedInterestingMutationMutation(baseOptions?: Apollo.MutationHookOptions<SaveChoosedInterestingMutationMutation, SaveChoosedInterestingMutationMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useMutation<SaveChoosedInterestingMutationMutation, SaveChoosedInterestingMutationMutationVariables>(SaveChoosedInterestingMutationDocument, options);
}
export type SaveChoosedInterestingMutationMutationHookResult = ReturnType<typeof useSaveChoosedInterestingMutationMutation>;
export type SaveChoosedInterestingMutationMutationResult = Apollo.MutationResult<SaveChoosedInterestingMutationMutation>;
export type SaveChoosedInterestingMutationMutationOptions = Apollo.BaseMutationOptions<SaveChoosedInterestingMutationMutation, SaveChoosedInterestingMutationMutationVariables>;
export const UpdateShouldShowInterestingMutationDocument = gql`
    mutation UpdateShouldShowInterestingMutation($data: UpdateShouldShowInterestingRequest!) {
  updateShouldShowInterestingMember(data: $data)
}
    `;
export type UpdateShouldShowInterestingMutationMutationFn = Apollo.MutationFunction<UpdateShouldShowInterestingMutationMutation, UpdateShouldShowInterestingMutationMutationVariables>;

/**
 * __useUpdateShouldShowInterestingMutationMutation__
 *
 * To run a mutation, you first call `useUpdateShouldShowInterestingMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShouldShowInterestingMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShouldShowInterestingMutationMutation, { data, loading, error }] = useUpdateShouldShowInterestingMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateShouldShowInterestingMutationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShouldShowInterestingMutationMutation, UpdateShouldShowInterestingMutationMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useMutation<UpdateShouldShowInterestingMutationMutation, UpdateShouldShowInterestingMutationMutationVariables>(UpdateShouldShowInterestingMutationDocument, options);
}
export type UpdateShouldShowInterestingMutationMutationHookResult = ReturnType<typeof useUpdateShouldShowInterestingMutationMutation>;
export type UpdateShouldShowInterestingMutationMutationResult = Apollo.MutationResult<UpdateShouldShowInterestingMutationMutation>;
export type UpdateShouldShowInterestingMutationMutationOptions = Apollo.BaseMutationOptions<UpdateShouldShowInterestingMutationMutation, UpdateShouldShowInterestingMutationMutationVariables>;
export const GetAllInterestingQueryDocument = gql`
    query GetAllInterestingQuery {
  getMeatCategoryInteresting {
    id
    title
    logo
  }
  getGroupCategoryMainInteresting {
    id
    title
    interestingCoverUrl
  }
  getRecipeTypeInteresting {
    id
    title
    interestingCoverUrl
  }
}
    `;

/**
 * __useGetAllInterestingQueryQuery__
 *
 * To run a query within a React component, call `useGetAllInterestingQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInterestingQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInterestingQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllInterestingQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetAllInterestingQueryQuery, GetAllInterestingQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetAllInterestingQueryQuery, GetAllInterestingQueryQueryVariables>(GetAllInterestingQueryDocument, options);
}
export function useGetAllInterestingQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInterestingQueryQuery, GetAllInterestingQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetAllInterestingQueryQuery, GetAllInterestingQueryQueryVariables>(GetAllInterestingQueryDocument, options);
}
export type GetAllInterestingQueryQueryHookResult = ReturnType<typeof useGetAllInterestingQueryQuery>;
export type GetAllInterestingQueryLazyQueryHookResult = ReturnType<typeof useGetAllInterestingQueryLazyQuery>;
export type GetAllInterestingQueryQueryResult = Apollo.QueryResult<GetAllInterestingQueryQuery, GetAllInterestingQueryQueryVariables>;
export const GetChoosedInterestingContentQueryDocument = gql`
    query GetChoosedInterestingContentQuery($where: GetMemberInterestingResultRequest!) {
  getMemberInterestingResult(where: $where)
}
    `;

/**
 * __useGetChoosedInterestingContentQueryQuery__
 *
 * To run a query within a React component, call `useGetChoosedInterestingContentQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChoosedInterestingContentQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChoosedInterestingContentQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetChoosedInterestingContentQueryQuery(baseOptions: Apollo.QueryHookOptions<GetChoosedInterestingContentQueryQuery, GetChoosedInterestingContentQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetChoosedInterestingContentQueryQuery, GetChoosedInterestingContentQueryQueryVariables>(GetChoosedInterestingContentQueryDocument, options);
}
export function useGetChoosedInterestingContentQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChoosedInterestingContentQueryQuery, GetChoosedInterestingContentQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetChoosedInterestingContentQueryQuery, GetChoosedInterestingContentQueryQueryVariables>(GetChoosedInterestingContentQueryDocument, options);
}
export type GetChoosedInterestingContentQueryQueryHookResult = ReturnType<typeof useGetChoosedInterestingContentQueryQuery>;
export type GetChoosedInterestingContentQueryLazyQueryHookResult = ReturnType<typeof useGetChoosedInterestingContentQueryLazyQuery>;
export type GetChoosedInterestingContentQueryQueryResult = Apollo.QueryResult<GetChoosedInterestingContentQueryQuery, GetChoosedInterestingContentQueryQueryVariables>;
export const AllMeatCategoryQueryDocument = gql`
    query AllMeatCategoryQuery {
  getAllMeatCategory {
    id
    title
  }
}
    `;

/**
 * __useAllMeatCategoryQueryQuery__
 *
 * To run a query within a React component, call `useAllMeatCategoryQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMeatCategoryQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMeatCategoryQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllMeatCategoryQueryQuery(baseOptions?: Apollo.QueryHookOptions<AllMeatCategoryQueryQuery, AllMeatCategoryQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<AllMeatCategoryQueryQuery, AllMeatCategoryQueryQueryVariables>(AllMeatCategoryQueryDocument, options);
}
export function useAllMeatCategoryQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllMeatCategoryQueryQuery, AllMeatCategoryQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<AllMeatCategoryQueryQuery, AllMeatCategoryQueryQueryVariables>(AllMeatCategoryQueryDocument, options);
}
export type AllMeatCategoryQueryQueryHookResult = ReturnType<typeof useAllMeatCategoryQueryQuery>;
export type AllMeatCategoryQueryLazyQueryHookResult = ReturnType<typeof useAllMeatCategoryQueryLazyQuery>;
export type AllMeatCategoryQueryQueryResult = Apollo.QueryResult<AllMeatCategoryQueryQuery, AllMeatCategoryQueryQueryVariables>;
export const ProductAllPopularBrandQueryDocument = gql`
    query ProductAllPopularBrandQuery {
  getAllBrand {
    brandId
    title
    coverImg
    thumbnailUrl
    linkOutUrl
  }
}
    `;

/**
 * __useProductAllPopularBrandQueryQuery__
 *
 * To run a query within a React component, call `useProductAllPopularBrandQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductAllPopularBrandQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductAllPopularBrandQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductAllPopularBrandQueryQuery(baseOptions?: Apollo.QueryHookOptions<ProductAllPopularBrandQueryQuery, ProductAllPopularBrandQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<ProductAllPopularBrandQueryQuery, ProductAllPopularBrandQueryQueryVariables>(ProductAllPopularBrandQueryDocument, options);
}
export function useProductAllPopularBrandQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductAllPopularBrandQueryQuery, ProductAllPopularBrandQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<ProductAllPopularBrandQueryQuery, ProductAllPopularBrandQueryQueryVariables>(ProductAllPopularBrandQueryDocument, options);
}
export type ProductAllPopularBrandQueryQueryHookResult = ReturnType<typeof useProductAllPopularBrandQueryQuery>;
export type ProductAllPopularBrandQueryLazyQueryHookResult = ReturnType<typeof useProductAllPopularBrandQueryLazyQuery>;
export type ProductAllPopularBrandQueryQueryResult = Apollo.QueryResult<ProductAllPopularBrandQueryQuery, ProductAllPopularBrandQueryQueryVariables>;
export const GetProductByBrandIdQueryDocument = gql`
    query GetProductByBrandIdQuery($data: GetProductByBrandIdRequest!) {
  getProductByBrandId(data: $data) {
    id
    title
    thumbUrl
    slugUrl
  }
}
    `;

/**
 * __useGetProductByBrandIdQueryQuery__
 *
 * To run a query within a React component, call `useGetProductByBrandIdQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByBrandIdQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByBrandIdQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetProductByBrandIdQueryQuery(baseOptions: Apollo.QueryHookOptions<GetProductByBrandIdQueryQuery, GetProductByBrandIdQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetProductByBrandIdQueryQuery, GetProductByBrandIdQueryQueryVariables>(GetProductByBrandIdQueryDocument, options);
}
export function useGetProductByBrandIdQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductByBrandIdQueryQuery, GetProductByBrandIdQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetProductByBrandIdQueryQuery, GetProductByBrandIdQueryQueryVariables>(GetProductByBrandIdQueryDocument, options);
}
export type GetProductByBrandIdQueryQueryHookResult = ReturnType<typeof useGetProductByBrandIdQueryQuery>;
export type GetProductByBrandIdQueryLazyQueryHookResult = ReturnType<typeof useGetProductByBrandIdQueryLazyQuery>;
export type GetProductByBrandIdQueryQueryResult = Apollo.QueryResult<GetProductByBrandIdQueryQuery, GetProductByBrandIdQueryQueryVariables>;
export const ProductByMeatCategoryQueryDocument = gql`
    query ProductByMeatCategoryQuery($where: GetAllProductByMeatCategoryRequest, $pagination: PaginationRequest) {
  getAllProductByMeatCategory(where: $where, pagination: $pagination) {
    products {
      id
      title
      slugUrl
      tag
      thumbUrl
      weight
    }
    total
  }
}
    `;

/**
 * __useProductByMeatCategoryQueryQuery__
 *
 * To run a query within a React component, call `useProductByMeatCategoryQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductByMeatCategoryQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductByMeatCategoryQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useProductByMeatCategoryQueryQuery(baseOptions?: Apollo.QueryHookOptions<ProductByMeatCategoryQueryQuery, ProductByMeatCategoryQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<ProductByMeatCategoryQueryQuery, ProductByMeatCategoryQueryQueryVariables>(ProductByMeatCategoryQueryDocument, options);
}
export function useProductByMeatCategoryQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductByMeatCategoryQueryQuery, ProductByMeatCategoryQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<ProductByMeatCategoryQueryQuery, ProductByMeatCategoryQueryQueryVariables>(ProductByMeatCategoryQueryDocument, options);
}
export type ProductByMeatCategoryQueryQueryHookResult = ReturnType<typeof useProductByMeatCategoryQueryQuery>;
export type ProductByMeatCategoryQueryLazyQueryHookResult = ReturnType<typeof useProductByMeatCategoryQueryLazyQuery>;
export type ProductByMeatCategoryQueryQueryResult = Apollo.QueryResult<ProductByMeatCategoryQueryQuery, ProductByMeatCategoryQueryQueryVariables>;
export const ProductDetailQueryDocument = gql`
    query ProductDetailQuery($data: GetProductRequest!) {
  getProduct(data: $data) {
    id
    title
    tag
    slugUrl
    shortDescription
    nutrition
    cooking
    preserve
    images
    meatCategories {
      id
    }
    shops {
      title
      imageUrl
      shopUrl
    }
    productCategories {
      id
    }
    productIngredients
    productDescription {
      brand
      brandImg
      category
      weight
      amount
      meatType
      allergen
      productStartDate
      productExpiredDate
      fdaNumber
    }
    recipe {
      id
      title
      slugUrl
      thumbUrl
      recipeMeatCategory {
        title
      }
    }
  }
}
    `;

/**
 * __useProductDetailQueryQuery__
 *
 * To run a query within a React component, call `useProductDetailQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProductDetailQueryQuery(baseOptions: Apollo.QueryHookOptions<ProductDetailQueryQuery, ProductDetailQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<ProductDetailQueryQuery, ProductDetailQueryQueryVariables>(ProductDetailQueryDocument, options);
}
export function useProductDetailQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductDetailQueryQuery, ProductDetailQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<ProductDetailQueryQuery, ProductDetailQueryQueryVariables>(ProductDetailQueryDocument, options);
}
export type ProductDetailQueryQueryHookResult = ReturnType<typeof useProductDetailQueryQuery>;
export type ProductDetailQueryLazyQueryHookResult = ReturnType<typeof useProductDetailQueryLazyQuery>;
export type ProductDetailQueryQueryResult = Apollo.QueryResult<ProductDetailQueryQuery, ProductDetailQueryQueryVariables>;
export const GetProductPinQueryDocument = gql`
    query GetProductPinQuery($where: GetProductPinRequest!) {
  getProductPin(where: $where) {
    id
    product {
      id
      slugUrl
      thumbUrl
      title
    }
  }
}
    `;

/**
 * __useGetProductPinQueryQuery__
 *
 * To run a query within a React component, call `useGetProductPinQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductPinQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductPinQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetProductPinQueryQuery(baseOptions: Apollo.QueryHookOptions<GetProductPinQueryQuery, GetProductPinQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetProductPinQueryQuery, GetProductPinQueryQueryVariables>(GetProductPinQueryDocument, options);
}
export function useGetProductPinQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductPinQueryQuery, GetProductPinQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetProductPinQueryQuery, GetProductPinQueryQueryVariables>(GetProductPinQueryDocument, options);
}
export type GetProductPinQueryQueryHookResult = ReturnType<typeof useGetProductPinQueryQuery>;
export type GetProductPinQueryLazyQueryHookResult = ReturnType<typeof useGetProductPinQueryLazyQuery>;
export type GetProductPinQueryQueryResult = Apollo.QueryResult<GetProductPinQueryQuery, GetProductPinQueryQueryVariables>;
export const GetProductRelateProductByInterestingDocument = gql`
    query GetProductRelateProductByInteresting($where: GetProductRelateProductByInterestingRequest!) {
  getProductRelateProductByInteresting(where: $where) {
    id
    title
    slugUrl
    tag
    thumbUrl
    weight
  }
}
    `;

/**
 * __useGetProductRelateProductByInterestingQuery__
 *
 * To run a query within a React component, call `useGetProductRelateProductByInterestingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductRelateProductByInterestingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductRelateProductByInterestingQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetProductRelateProductByInterestingQuery(baseOptions: Apollo.QueryHookOptions<GetProductRelateProductByInterestingQuery, GetProductRelateProductByInterestingQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetProductRelateProductByInterestingQuery, GetProductRelateProductByInterestingQueryVariables>(GetProductRelateProductByInterestingDocument, options);
}
export function useGetProductRelateProductByInterestingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductRelateProductByInterestingQuery, GetProductRelateProductByInterestingQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetProductRelateProductByInterestingQuery, GetProductRelateProductByInterestingQueryVariables>(GetProductRelateProductByInterestingDocument, options);
}
export type GetProductRelateProductByInterestingQueryHookResult = ReturnType<typeof useGetProductRelateProductByInterestingQuery>;
export type GetProductRelateProductByInterestingLazyQueryHookResult = ReturnType<typeof useGetProductRelateProductByInterestingLazyQuery>;
export type GetProductRelateProductByInterestingQueryResult = Apollo.QueryResult<GetProductRelateProductByInterestingQuery, GetProductRelateProductByInterestingQueryVariables>;
export const GetProductRelatedProductQueryDocument = gql`
    query GetProductRelatedProductQuery($data: GetProductRelateProductRequest!) {
  getProductRelateProduct(data: $data) {
    id
    title
    slugUrl
    tag
    thumbUrl
    weight
  }
}
    `;

/**
 * __useGetProductRelatedProductQueryQuery__
 *
 * To run a query within a React component, call `useGetProductRelatedProductQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductRelatedProductQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductRelatedProductQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetProductRelatedProductQueryQuery(baseOptions: Apollo.QueryHookOptions<GetProductRelatedProductQueryQuery, GetProductRelatedProductQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetProductRelatedProductQueryQuery, GetProductRelatedProductQueryQueryVariables>(GetProductRelatedProductQueryDocument, options);
}
export function useGetProductRelatedProductQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductRelatedProductQueryQuery, GetProductRelatedProductQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetProductRelatedProductQueryQuery, GetProductRelatedProductQueryQueryVariables>(GetProductRelatedProductQueryDocument, options);
}
export type GetProductRelatedProductQueryQueryHookResult = ReturnType<typeof useGetProductRelatedProductQueryQuery>;
export type GetProductRelatedProductQueryLazyQueryHookResult = ReturnType<typeof useGetProductRelatedProductQueryLazyQuery>;
export type GetProductRelatedProductQueryQueryResult = Apollo.QueryResult<GetProductRelatedProductQueryQuery, GetProductRelatedProductQueryQueryVariables>;
export const GetProductRelatedRecipeQueryDocument = gql`
    query GetProductRelatedRecipeQuery($data: GetProductRequest!) {
  getProduct(data: $data) {
    recipe {
      id
      title
      slugUrl
      thumbUrl
    }
  }
}
    `;

/**
 * __useGetProductRelatedRecipeQueryQuery__
 *
 * To run a query within a React component, call `useGetProductRelatedRecipeQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductRelatedRecipeQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductRelatedRecipeQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetProductRelatedRecipeQueryQuery(baseOptions: Apollo.QueryHookOptions<GetProductRelatedRecipeQueryQuery, GetProductRelatedRecipeQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetProductRelatedRecipeQueryQuery, GetProductRelatedRecipeQueryQueryVariables>(GetProductRelatedRecipeQueryDocument, options);
}
export function useGetProductRelatedRecipeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductRelatedRecipeQueryQuery, GetProductRelatedRecipeQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetProductRelatedRecipeQueryQuery, GetProductRelatedRecipeQueryQueryVariables>(GetProductRelatedRecipeQueryDocument, options);
}
export type GetProductRelatedRecipeQueryQueryHookResult = ReturnType<typeof useGetProductRelatedRecipeQueryQuery>;
export type GetProductRelatedRecipeQueryLazyQueryHookResult = ReturnType<typeof useGetProductRelatedRecipeQueryLazyQuery>;
export type GetProductRelatedRecipeQueryQueryResult = Apollo.QueryResult<GetProductRelatedRecipeQueryQuery, GetProductRelatedRecipeQueryQueryVariables>;
export const GetPromotionDetailQueryDocument = gql`
    query GetPromotionDetailQuery($data: GetActivityDetailRequest!) {
  getPromotionDetail(data: $data) {
    status
    createdAt
    updatedAt
    deletedAt
    id
    title
    description
    feedTitle
    feedDescription
    ogImage
    feedKeyword
    detail
    thumbUrl
    imageUrl
    location
    activityCategory
    startedAt
    endedAt
    publishedAt
    expiredAt
    creator {
      status
      createdAt
      updatedAt
      deletedAt
      id
      name
      imageUrl
      slugUrl
    }
    slugUrl
    isExpire
  }
}
    `;

/**
 * __useGetPromotionDetailQueryQuery__
 *
 * To run a query within a React component, call `useGetPromotionDetailQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionDetailQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionDetailQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetPromotionDetailQueryQuery(baseOptions: Apollo.QueryHookOptions<GetPromotionDetailQueryQuery, GetPromotionDetailQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetPromotionDetailQueryQuery, GetPromotionDetailQueryQueryVariables>(GetPromotionDetailQueryDocument, options);
}
export function useGetPromotionDetailQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionDetailQueryQuery, GetPromotionDetailQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetPromotionDetailQueryQuery, GetPromotionDetailQueryQueryVariables>(GetPromotionDetailQueryDocument, options);
}
export type GetPromotionDetailQueryQueryHookResult = ReturnType<typeof useGetPromotionDetailQueryQuery>;
export type GetPromotionDetailQueryLazyQueryHookResult = ReturnType<typeof useGetPromotionDetailQueryLazyQuery>;
export type GetPromotionDetailQueryQueryResult = Apollo.QueryResult<GetPromotionDetailQueryQuery, GetPromotionDetailQueryQueryVariables>;
export const GetRealtedPromotionQueryDocument = gql`
    query GetRealtedPromotionQuery($data: GetLatestActivityRequest!) {
  getLatestPromotion(data: $data) {
    status
    createdAt
    updatedAt
    deletedAt
    id
    title
    description
    feedTitle
    feedDescription
    ogImage
    feedKeyword
    detail
    thumbUrl
    imageUrl
    location
    activityCategory
    startedAt
    endedAt
    publishedAt
    expiredAt
    isExpire
    slugUrl
  }
}
    `;

/**
 * __useGetRealtedPromotionQueryQuery__
 *
 * To run a query within a React component, call `useGetRealtedPromotionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRealtedPromotionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRealtedPromotionQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetRealtedPromotionQueryQuery(baseOptions: Apollo.QueryHookOptions<GetRealtedPromotionQueryQuery, GetRealtedPromotionQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetRealtedPromotionQueryQuery, GetRealtedPromotionQueryQueryVariables>(GetRealtedPromotionQueryDocument, options);
}
export function useGetRealtedPromotionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRealtedPromotionQueryQuery, GetRealtedPromotionQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetRealtedPromotionQueryQuery, GetRealtedPromotionQueryQueryVariables>(GetRealtedPromotionQueryDocument, options);
}
export type GetRealtedPromotionQueryQueryHookResult = ReturnType<typeof useGetRealtedPromotionQueryQuery>;
export type GetRealtedPromotionQueryLazyQueryHookResult = ReturnType<typeof useGetRealtedPromotionQueryLazyQuery>;
export type GetRealtedPromotionQueryQueryResult = Apollo.QueryResult<GetRealtedPromotionQueryQuery, GetRealtedPromotionQueryQueryVariables>;
export const GetNewestPromotionQueryDocument = gql`
    query GetNewestPromotionQuery {
  getPromotionEightItems {
    id
    title
    description
    thumbUrl
    startedAt
    endedAt
    isExpire
    slugUrl
  }
}
    `;

/**
 * __useGetNewestPromotionQueryQuery__
 *
 * To run a query within a React component, call `useGetNewestPromotionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewestPromotionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewestPromotionQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNewestPromotionQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetNewestPromotionQueryQuery, GetNewestPromotionQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetNewestPromotionQueryQuery, GetNewestPromotionQueryQueryVariables>(GetNewestPromotionQueryDocument, options);
}
export function useGetNewestPromotionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewestPromotionQueryQuery, GetNewestPromotionQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetNewestPromotionQueryQuery, GetNewestPromotionQueryQueryVariables>(GetNewestPromotionQueryDocument, options);
}
export type GetNewestPromotionQueryQueryHookResult = ReturnType<typeof useGetNewestPromotionQueryQuery>;
export type GetNewestPromotionQueryLazyQueryHookResult = ReturnType<typeof useGetNewestPromotionQueryLazyQuery>;
export type GetNewestPromotionQueryQueryResult = Apollo.QueryResult<GetNewestPromotionQueryQuery, GetNewestPromotionQueryQueryVariables>;
export const GetPromotionByMonthQueryDocument = gql`
    query GetPromotionByMonthQuery($data: GetActivityListRequest, $pagination: PaginationRequest) {
  getPromotionList(data: $data, pagination: $pagination) {
    promotions {
      id
      title
      description
      thumbUrl
      startedAt
      endedAt
      isExpire
      slugUrl
    }
    total
  }
}
    `;

/**
 * __useGetPromotionByMonthQueryQuery__
 *
 * To run a query within a React component, call `useGetPromotionByMonthQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionByMonthQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionByMonthQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetPromotionByMonthQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetPromotionByMonthQueryQuery, GetPromotionByMonthQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetPromotionByMonthQueryQuery, GetPromotionByMonthQueryQueryVariables>(GetPromotionByMonthQueryDocument, options);
}
export function useGetPromotionByMonthQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionByMonthQueryQuery, GetPromotionByMonthQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetPromotionByMonthQueryQuery, GetPromotionByMonthQueryQueryVariables>(GetPromotionByMonthQueryDocument, options);
}
export type GetPromotionByMonthQueryQueryHookResult = ReturnType<typeof useGetPromotionByMonthQueryQuery>;
export type GetPromotionByMonthQueryLazyQueryHookResult = ReturnType<typeof useGetPromotionByMonthQueryLazyQuery>;
export type GetPromotionByMonthQueryQueryResult = Apollo.QueryResult<GetPromotionByMonthQueryQuery, GetPromotionByMonthQueryQueryVariables>;
export const GetPromotionFilterQueryDocument = gql`
    query GetPromotionFilterQuery {
  getPromotionFilter {
    label
    options {
      label
      value
    }
  }
}
    `;

/**
 * __useGetPromotionFilterQueryQuery__
 *
 * To run a query within a React component, call `useGetPromotionFilterQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionFilterQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionFilterQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPromotionFilterQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetPromotionFilterQueryQuery, GetPromotionFilterQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetPromotionFilterQueryQuery, GetPromotionFilterQueryQueryVariables>(GetPromotionFilterQueryDocument, options);
}
export function useGetPromotionFilterQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionFilterQueryQuery, GetPromotionFilterQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetPromotionFilterQueryQuery, GetPromotionFilterQueryQueryVariables>(GetPromotionFilterQueryDocument, options);
}
export type GetPromotionFilterQueryQueryHookResult = ReturnType<typeof useGetPromotionFilterQueryQuery>;
export type GetPromotionFilterQueryLazyQueryHookResult = ReturnType<typeof useGetPromotionFilterQueryLazyQuery>;
export type GetPromotionFilterQueryQueryResult = Apollo.QueryResult<GetPromotionFilterQueryQuery, GetPromotionFilterQueryQueryVariables>;
export const RecipeGroupCarouselQueryDocument = gql`
    query RecipeGroupCarouselQuery($where: GetProductByGroupCategoryIdRequest!) {
  getProductByGroupCategoryId(where: $where) {
    title
    id
    thumbUrl
    slugUrl
  }
}
    `;

/**
 * __useRecipeGroupCarouselQueryQuery__
 *
 * To run a query within a React component, call `useRecipeGroupCarouselQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeGroupCarouselQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeGroupCarouselQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRecipeGroupCarouselQueryQuery(baseOptions: Apollo.QueryHookOptions<RecipeGroupCarouselQueryQuery, RecipeGroupCarouselQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<RecipeGroupCarouselQueryQuery, RecipeGroupCarouselQueryQueryVariables>(RecipeGroupCarouselQueryDocument, options);
}
export function useRecipeGroupCarouselQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeGroupCarouselQueryQuery, RecipeGroupCarouselQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<RecipeGroupCarouselQueryQuery, RecipeGroupCarouselQueryQueryVariables>(RecipeGroupCarouselQueryDocument, options);
}
export type RecipeGroupCarouselQueryQueryHookResult = ReturnType<typeof useRecipeGroupCarouselQueryQuery>;
export type RecipeGroupCarouselQueryLazyQueryHookResult = ReturnType<typeof useRecipeGroupCarouselQueryLazyQuery>;
export type RecipeGroupCarouselQueryQueryResult = Apollo.QueryResult<RecipeGroupCarouselQueryQuery, RecipeGroupCarouselQueryQueryVariables>;
export const RecipeGroupChildrenQueryDocument = gql`
    query RecipeGroupChildrenQuery($data: GetChildrenGroupCategoryRequest!) {
  getChildrenGroupCategory(data: $data) {
    id
    title
  }
}
    `;

/**
 * __useRecipeGroupChildrenQueryQuery__
 *
 * To run a query within a React component, call `useRecipeGroupChildrenQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeGroupChildrenQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeGroupChildrenQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRecipeGroupChildrenQueryQuery(baseOptions: Apollo.QueryHookOptions<RecipeGroupChildrenQueryQuery, RecipeGroupChildrenQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<RecipeGroupChildrenQueryQuery, RecipeGroupChildrenQueryQueryVariables>(RecipeGroupChildrenQueryDocument, options);
}
export function useRecipeGroupChildrenQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeGroupChildrenQueryQuery, RecipeGroupChildrenQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<RecipeGroupChildrenQueryQuery, RecipeGroupChildrenQueryQueryVariables>(RecipeGroupChildrenQueryDocument, options);
}
export type RecipeGroupChildrenQueryQueryHookResult = ReturnType<typeof useRecipeGroupChildrenQueryQuery>;
export type RecipeGroupChildrenQueryLazyQueryHookResult = ReturnType<typeof useRecipeGroupChildrenQueryLazyQuery>;
export type RecipeGroupChildrenQueryQueryResult = Apollo.QueryResult<RecipeGroupChildrenQueryQuery, RecipeGroupChildrenQueryQueryVariables>;
export const RecipeGroupDetailQueryDocument = gql`
    query RecipeGroupDetailQuery($data: GetGroupCategoryRequest!) {
  getGroupCategory(data: $data) {
    description
    id
    imageIconUrl
    mainColor
    mainCoverMobileUrl
    mainCoverUrl
    subMainColor
    title
  }
}
    `;

/**
 * __useRecipeGroupDetailQueryQuery__
 *
 * To run a query within a React component, call `useRecipeGroupDetailQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeGroupDetailQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeGroupDetailQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRecipeGroupDetailQueryQuery(baseOptions: Apollo.QueryHookOptions<RecipeGroupDetailQueryQuery, RecipeGroupDetailQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<RecipeGroupDetailQueryQuery, RecipeGroupDetailQueryQueryVariables>(RecipeGroupDetailQueryDocument, options);
}
export function useRecipeGroupDetailQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeGroupDetailQueryQuery, RecipeGroupDetailQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<RecipeGroupDetailQueryQuery, RecipeGroupDetailQueryQueryVariables>(RecipeGroupDetailQueryDocument, options);
}
export type RecipeGroupDetailQueryQueryHookResult = ReturnType<typeof useRecipeGroupDetailQueryQuery>;
export type RecipeGroupDetailQueryLazyQueryHookResult = ReturnType<typeof useRecipeGroupDetailQueryLazyQuery>;
export type RecipeGroupDetailQueryQueryResult = Apollo.QueryResult<RecipeGroupDetailQueryQuery, RecipeGroupDetailQueryQueryVariables>;
export const RecipeListByGroupChildrenQueryDocument = gql`
    query RecipeListByGroupChildrenQuery($where: GetRecipeByGroupCategoryIdRequest!, $pagination: PaginationRequest) {
  getRecipeByGroupCategoryId(where: $where, pagination: $pagination) {
    recipes {
      slugUrl
      title
      id
      thumbUrl
      recipeMeatCategory {
        id
        title
      }
    }
    total
  }
}
    `;

/**
 * __useRecipeListByGroupChildrenQueryQuery__
 *
 * To run a query within a React component, call `useRecipeListByGroupChildrenQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeListByGroupChildrenQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeListByGroupChildrenQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useRecipeListByGroupChildrenQueryQuery(baseOptions: Apollo.QueryHookOptions<RecipeListByGroupChildrenQueryQuery, RecipeListByGroupChildrenQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<RecipeListByGroupChildrenQueryQuery, RecipeListByGroupChildrenQueryQueryVariables>(RecipeListByGroupChildrenQueryDocument, options);
}
export function useRecipeListByGroupChildrenQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeListByGroupChildrenQueryQuery, RecipeListByGroupChildrenQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<RecipeListByGroupChildrenQueryQuery, RecipeListByGroupChildrenQueryQueryVariables>(RecipeListByGroupChildrenQueryDocument, options);
}
export type RecipeListByGroupChildrenQueryQueryHookResult = ReturnType<typeof useRecipeListByGroupChildrenQueryQuery>;
export type RecipeListByGroupChildrenQueryLazyQueryHookResult = ReturnType<typeof useRecipeListByGroupChildrenQueryLazyQuery>;
export type RecipeListByGroupChildrenQueryQueryResult = Apollo.QueryResult<RecipeListByGroupChildrenQueryQuery, RecipeListByGroupChildrenQueryQueryVariables>;
export const AllRecipeHackQueryDocument = gql`
    query AllRecipeHackQuery {
  getAllRecipeHack {
    id
    title
  }
}
    `;

/**
 * __useAllRecipeHackQueryQuery__
 *
 * To run a query within a React component, call `useAllRecipeHackQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRecipeHackQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRecipeHackQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRecipeHackQueryQuery(baseOptions?: Apollo.QueryHookOptions<AllRecipeHackQueryQuery, AllRecipeHackQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<AllRecipeHackQueryQuery, AllRecipeHackQueryQueryVariables>(AllRecipeHackQueryDocument, options);
}
export function useAllRecipeHackQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllRecipeHackQueryQuery, AllRecipeHackQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<AllRecipeHackQueryQuery, AllRecipeHackQueryQueryVariables>(AllRecipeHackQueryDocument, options);
}
export type AllRecipeHackQueryQueryHookResult = ReturnType<typeof useAllRecipeHackQueryQuery>;
export type AllRecipeHackQueryLazyQueryHookResult = ReturnType<typeof useAllRecipeHackQueryLazyQuery>;
export type AllRecipeHackQueryQueryResult = Apollo.QueryResult<AllRecipeHackQueryQuery, AllRecipeHackQueryQueryVariables>;
export const RecipeByHackIdQueryDocument = gql`
    query RecipeByHackIdQuery($data: GetRecipeByHackIdRequest!) {
  getRecipeByHackId(data: $data) {
    id
    title
    thumbUrl
    slugUrl
    recipeMeatCategory {
      id
      title
    }
  }
}
    `;

/**
 * __useRecipeByHackIdQueryQuery__
 *
 * To run a query within a React component, call `useRecipeByHackIdQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeByHackIdQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeByHackIdQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRecipeByHackIdQueryQuery(baseOptions: Apollo.QueryHookOptions<RecipeByHackIdQueryQuery, RecipeByHackIdQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<RecipeByHackIdQueryQuery, RecipeByHackIdQueryQueryVariables>(RecipeByHackIdQueryDocument, options);
}
export function useRecipeByHackIdQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeByHackIdQueryQuery, RecipeByHackIdQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<RecipeByHackIdQueryQuery, RecipeByHackIdQueryQueryVariables>(RecipeByHackIdQueryDocument, options);
}
export type RecipeByHackIdQueryQueryHookResult = ReturnType<typeof useRecipeByHackIdQueryQuery>;
export type RecipeByHackIdQueryLazyQueryHookResult = ReturnType<typeof useRecipeByHackIdQueryLazyQuery>;
export type RecipeByHackIdQueryQueryResult = Apollo.QueryResult<RecipeByHackIdQueryQuery, RecipeByHackIdQueryQueryVariables>;
export const RecipeDetailQueryDocument = gql`
    query RecipeDetailQuery($data: GetRecipeRequest!) {
  getRecipe(data: $data) {
    id
    title
    description
    slugUrl
    people
    toPeople
    ingredientImage
    cooking
    time
    youtubeUrl
    coverImageUrl
    creator {
      id
      name
      imageUrl
      slugUrl
    }
    recipeIngredient {
      id
      ingredient {
        id
        title
      }
      unit
      quantity
      ingredientType
      linkoutUrl
    }
    recipeProduct {
      id
      product {
        id
        title
      }
      unit
      quantity
      linkoutUrl
    }
  }
}
    `;

/**
 * __useRecipeDetailQueryQuery__
 *
 * To run a query within a React component, call `useRecipeDetailQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeDetailQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeDetailQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRecipeDetailQueryQuery(baseOptions: Apollo.QueryHookOptions<RecipeDetailQueryQuery, RecipeDetailQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<RecipeDetailQueryQuery, RecipeDetailQueryQueryVariables>(RecipeDetailQueryDocument, options);
}
export function useRecipeDetailQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeDetailQueryQuery, RecipeDetailQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<RecipeDetailQueryQuery, RecipeDetailQueryQueryVariables>(RecipeDetailQueryDocument, options);
}
export type RecipeDetailQueryQueryHookResult = ReturnType<typeof useRecipeDetailQueryQuery>;
export type RecipeDetailQueryLazyQueryHookResult = ReturnType<typeof useRecipeDetailQueryLazyQuery>;
export type RecipeDetailQueryQueryResult = Apollo.QueryResult<RecipeDetailQueryQuery, RecipeDetailQueryQueryVariables>;
export const GetRecipeRecommendMenuQueryDocument = gql`
    query GetRecipeRecommendMenuQuery($where: GetGroupCategoryRelateRecipeByIdRequest!) {
  getGroupCategoryRelateRecipeById(where: $where) {
    id
    title
    bgCoverUrl
    imageIconUrl
    mainColor
    slugUrl
    recipeList {
      id
      thumbUrl
      title
      slugUrl
    }
  }
}
    `;

/**
 * __useGetRecipeRecommendMenuQueryQuery__
 *
 * To run a query within a React component, call `useGetRecipeRecommendMenuQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeRecommendMenuQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeRecommendMenuQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRecipeRecommendMenuQueryQuery(baseOptions: Apollo.QueryHookOptions<GetRecipeRecommendMenuQueryQuery, GetRecipeRecommendMenuQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetRecipeRecommendMenuQueryQuery, GetRecipeRecommendMenuQueryQueryVariables>(GetRecipeRecommendMenuQueryDocument, options);
}
export function useGetRecipeRecommendMenuQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecipeRecommendMenuQueryQuery, GetRecipeRecommendMenuQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetRecipeRecommendMenuQueryQuery, GetRecipeRecommendMenuQueryQueryVariables>(GetRecipeRecommendMenuQueryDocument, options);
}
export type GetRecipeRecommendMenuQueryQueryHookResult = ReturnType<typeof useGetRecipeRecommendMenuQueryQuery>;
export type GetRecipeRecommendMenuQueryLazyQueryHookResult = ReturnType<typeof useGetRecipeRecommendMenuQueryLazyQuery>;
export type GetRecipeRecommendMenuQueryQueryResult = Apollo.QueryResult<GetRecipeRecommendMenuQueryQuery, GetRecipeRecommendMenuQueryQueryVariables>;
export const GetRecipeRecommendTagQueryDocument = gql`
    query GetRecipeRecommendTagQuery($pagination: PaginationRequest) {
  getAllGroupCategory(pagination: $pagination) {
    id
    title
  }
}
    `;

/**
 * __useGetRecipeRecommendTagQueryQuery__
 *
 * To run a query within a React component, call `useGetRecipeRecommendTagQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeRecommendTagQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeRecommendTagQueryQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetRecipeRecommendTagQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetRecipeRecommendTagQueryQuery, GetRecipeRecommendTagQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetRecipeRecommendTagQueryQuery, GetRecipeRecommendTagQueryQueryVariables>(GetRecipeRecommendTagQueryDocument, options);
}
export function useGetRecipeRecommendTagQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecipeRecommendTagQueryQuery, GetRecipeRecommendTagQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetRecipeRecommendTagQueryQuery, GetRecipeRecommendTagQueryQueryVariables>(GetRecipeRecommendTagQueryDocument, options);
}
export type GetRecipeRecommendTagQueryQueryHookResult = ReturnType<typeof useGetRecipeRecommendTagQueryQuery>;
export type GetRecipeRecommendTagQueryLazyQueryHookResult = ReturnType<typeof useGetRecipeRecommendTagQueryLazyQuery>;
export type GetRecipeRecommendTagQueryQueryResult = Apollo.QueryResult<GetRecipeRecommendTagQueryQuery, GetRecipeRecommendTagQueryQueryVariables>;
export const GetRecipeRelateRecipeByMemberInterestingDocument = gql`
    query GetRecipeRelateRecipeByMemberInteresting($where: GetRecipeRelateRecipeCategoryRequest!) {
  getRecipeRelateRecipeByMemberInteresting(where: $where) {
    id
    title
    slugUrl
    thumbUrl
    tags
    people
  }
}
    `;

/**
 * __useGetRecipeRelateRecipeByMemberInterestingQuery__
 *
 * To run a query within a React component, call `useGetRecipeRelateRecipeByMemberInterestingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeRelateRecipeByMemberInterestingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeRelateRecipeByMemberInterestingQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRecipeRelateRecipeByMemberInterestingQuery(baseOptions: Apollo.QueryHookOptions<GetRecipeRelateRecipeByMemberInterestingQuery, GetRecipeRelateRecipeByMemberInterestingQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetRecipeRelateRecipeByMemberInterestingQuery, GetRecipeRelateRecipeByMemberInterestingQueryVariables>(GetRecipeRelateRecipeByMemberInterestingDocument, options);
}
export function useGetRecipeRelateRecipeByMemberInterestingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecipeRelateRecipeByMemberInterestingQuery, GetRecipeRelateRecipeByMemberInterestingQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetRecipeRelateRecipeByMemberInterestingQuery, GetRecipeRelateRecipeByMemberInterestingQueryVariables>(GetRecipeRelateRecipeByMemberInterestingDocument, options);
}
export type GetRecipeRelateRecipeByMemberInterestingQueryHookResult = ReturnType<typeof useGetRecipeRelateRecipeByMemberInterestingQuery>;
export type GetRecipeRelateRecipeByMemberInterestingLazyQueryHookResult = ReturnType<typeof useGetRecipeRelateRecipeByMemberInterestingLazyQuery>;
export type GetRecipeRelateRecipeByMemberInterestingQueryResult = Apollo.QueryResult<GetRecipeRelateRecipeByMemberInterestingQuery, GetRecipeRelateRecipeByMemberInterestingQueryVariables>;
export const RecipeRelatedProductQueryDocument = gql`
    query RecipeRelatedProductQuery($data: GetRecipeRequest!) {
  getRecipe(data: $data) {
    recipeProduct {
      id
      product {
        id
        tag
        title
        slugUrl
        thumbUrl
        weight
      }
    }
  }
}
    `;

/**
 * __useRecipeRelatedProductQueryQuery__
 *
 * To run a query within a React component, call `useRecipeRelatedProductQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeRelatedProductQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeRelatedProductQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRecipeRelatedProductQueryQuery(baseOptions: Apollo.QueryHookOptions<RecipeRelatedProductQueryQuery, RecipeRelatedProductQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<RecipeRelatedProductQueryQuery, RecipeRelatedProductQueryQueryVariables>(RecipeRelatedProductQueryDocument, options);
}
export function useRecipeRelatedProductQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeRelatedProductQueryQuery, RecipeRelatedProductQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<RecipeRelatedProductQueryQuery, RecipeRelatedProductQueryQueryVariables>(RecipeRelatedProductQueryDocument, options);
}
export type RecipeRelatedProductQueryQueryHookResult = ReturnType<typeof useRecipeRelatedProductQueryQuery>;
export type RecipeRelatedProductQueryLazyQueryHookResult = ReturnType<typeof useRecipeRelatedProductQueryLazyQuery>;
export type RecipeRelatedProductQueryQueryResult = Apollo.QueryResult<RecipeRelatedProductQueryQuery, RecipeRelatedProductQueryQueryVariables>;
export const GetRecipeRelateRecipeDocument = gql`
    query GetRecipeRelateRecipe($where: GetRecipeRelateRecipeCategoryRequest!) {
  getRecipeRelateRecipe(where: $where) {
    id
    title
    slugUrl
    thumbUrl
    tags
    people
  }
}
    `;

/**
 * __useGetRecipeRelateRecipeQuery__
 *
 * To run a query within a React component, call `useGetRecipeRelateRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeRelateRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeRelateRecipeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRecipeRelateRecipeQuery(baseOptions: Apollo.QueryHookOptions<GetRecipeRelateRecipeQuery, GetRecipeRelateRecipeQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetRecipeRelateRecipeQuery, GetRecipeRelateRecipeQueryVariables>(GetRecipeRelateRecipeDocument, options);
}
export function useGetRecipeRelateRecipeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecipeRelateRecipeQuery, GetRecipeRelateRecipeQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetRecipeRelateRecipeQuery, GetRecipeRelateRecipeQueryVariables>(GetRecipeRelateRecipeDocument, options);
}
export type GetRecipeRelateRecipeQueryHookResult = ReturnType<typeof useGetRecipeRelateRecipeQuery>;
export type GetRecipeRelateRecipeLazyQueryHookResult = ReturnType<typeof useGetRecipeRelateRecipeLazyQuery>;
export type GetRecipeRelateRecipeQueryResult = Apollo.QueryResult<GetRecipeRelateRecipeQuery, GetRecipeRelateRecipeQueryVariables>;
export const GetRegisterBannerQueryDocument = gql`
    query GetRegisterBannerQuery($where: GetRegisterBannerRequest!) {
  getRegisterBanner(where: $where) {
    id
    title
    bannerDesktop
    bannerMobile
    linkoutUrl
  }
}
    `;

/**
 * __useGetRegisterBannerQueryQuery__
 *
 * To run a query within a React component, call `useGetRegisterBannerQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisterBannerQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisterBannerQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRegisterBannerQueryQuery(baseOptions: Apollo.QueryHookOptions<GetRegisterBannerQueryQuery, GetRegisterBannerQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetRegisterBannerQueryQuery, GetRegisterBannerQueryQueryVariables>(GetRegisterBannerQueryDocument, options);
}
export function useGetRegisterBannerQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegisterBannerQueryQuery, GetRegisterBannerQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetRegisterBannerQueryQuery, GetRegisterBannerQueryQueryVariables>(GetRegisterBannerQueryDocument, options);
}
export type GetRegisterBannerQueryQueryHookResult = ReturnType<typeof useGetRegisterBannerQueryQuery>;
export type GetRegisterBannerQueryLazyQueryHookResult = ReturnType<typeof useGetRegisterBannerQueryLazyQuery>;
export type GetRegisterBannerQueryQueryResult = Apollo.QueryResult<GetRegisterBannerQueryQuery, GetRegisterBannerQueryQueryVariables>;
export const GetSocialMediaBannerDocument = gql`
    query GetSocialMediaBanner {
  getRegisterBannerList {
    id
    title
    bannerDesktop
    bannerMobile
    linkoutUrl
  }
}
    `;

/**
 * __useGetSocialMediaBannerQuery__
 *
 * To run a query within a React component, call `useGetSocialMediaBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSocialMediaBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSocialMediaBannerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSocialMediaBannerQuery(baseOptions?: Apollo.QueryHookOptions<GetSocialMediaBannerQuery, GetSocialMediaBannerQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetSocialMediaBannerQuery, GetSocialMediaBannerQueryVariables>(GetSocialMediaBannerDocument, options);
}
export function useGetSocialMediaBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSocialMediaBannerQuery, GetSocialMediaBannerQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetSocialMediaBannerQuery, GetSocialMediaBannerQueryVariables>(GetSocialMediaBannerDocument, options);
}
export type GetSocialMediaBannerQueryHookResult = ReturnType<typeof useGetSocialMediaBannerQuery>;
export type GetSocialMediaBannerLazyQueryHookResult = ReturnType<typeof useGetSocialMediaBannerLazyQuery>;
export type GetSocialMediaBannerQueryResult = Apollo.QueryResult<GetSocialMediaBannerQuery, GetSocialMediaBannerQueryVariables>;
export const PopularSearchQueryDocument = gql`
    query PopularSearchQuery {
  getPopularSearch {
    id
    slugUrl
    title
    type
  }
}
    `;

/**
 * __usePopularSearchQueryQuery__
 *
 * To run a query within a React component, call `usePopularSearchQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularSearchQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularSearchQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function usePopularSearchQueryQuery(baseOptions?: Apollo.QueryHookOptions<PopularSearchQueryQuery, PopularSearchQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<PopularSearchQueryQuery, PopularSearchQueryQueryVariables>(PopularSearchQueryDocument, options);
}
export function usePopularSearchQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PopularSearchQueryQuery, PopularSearchQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<PopularSearchQueryQuery, PopularSearchQueryQueryVariables>(PopularSearchQueryDocument, options);
}
export type PopularSearchQueryQueryHookResult = ReturnType<typeof usePopularSearchQueryQuery>;
export type PopularSearchQueryLazyQueryHookResult = ReturnType<typeof usePopularSearchQueryLazyQuery>;
export type PopularSearchQueryQueryResult = Apollo.QueryResult<PopularSearchQueryQuery, PopularSearchQueryQueryVariables>;
export const PopularSearchAutoSuggestionQueryDocument = gql`
    query PopularSearchAutoSuggestionQuery($where: GetPopularSearchAutoSuggestionRequest!) {
  getPopularSearchAutoSuggestion(where: $where) {
    products {
      title
      id
      slugUrl
    }
    recipes {
      title
      slugUrl
    }
    tipsTricks {
      id
      slugUrl
      title
    }
  }
}
    `;

/**
 * __usePopularSearchAutoSuggestionQueryQuery__
 *
 * To run a query within a React component, call `usePopularSearchAutoSuggestionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularSearchAutoSuggestionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularSearchAutoSuggestionQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePopularSearchAutoSuggestionQueryQuery(baseOptions: Apollo.QueryHookOptions<PopularSearchAutoSuggestionQueryQuery, PopularSearchAutoSuggestionQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<PopularSearchAutoSuggestionQueryQuery, PopularSearchAutoSuggestionQueryQueryVariables>(PopularSearchAutoSuggestionQueryDocument, options);
}
export function usePopularSearchAutoSuggestionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PopularSearchAutoSuggestionQueryQuery, PopularSearchAutoSuggestionQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<PopularSearchAutoSuggestionQueryQuery, PopularSearchAutoSuggestionQueryQueryVariables>(PopularSearchAutoSuggestionQueryDocument, options);
}
export type PopularSearchAutoSuggestionQueryQueryHookResult = ReturnType<typeof usePopularSearchAutoSuggestionQueryQuery>;
export type PopularSearchAutoSuggestionQueryLazyQueryHookResult = ReturnType<typeof usePopularSearchAutoSuggestionQueryLazyQuery>;
export type PopularSearchAutoSuggestionQueryQueryResult = Apollo.QueryResult<PopularSearchAutoSuggestionQueryQuery, PopularSearchAutoSuggestionQueryQueryVariables>;
export const SearchResultQueryDocument = gql`
    query SearchResultQuery($where: GetSearchResultRequest!, $pagination: PaginationRequest!) {
  getSearchResult(where: $where, pagination: $pagination) {
    productTotal
    recipeTotal
    tipsTrickTotal
    data {
      products {
        id
        title
        slugUrl
        tag
        thumbUrl
        weight
        updatedAt
      }
      recipes {
        id
        title
        thumbUrl
        slugUrl
        updatedAt
        recipeMeatCategory {
          title
        }
      }
      tipsTricks {
        id
        title
        description
        publishedAt
        slugUrl
        thumbUrl
        category {
          title
        }
      }
    }
  }
}
    `;

/**
 * __useSearchResultQueryQuery__
 *
 * To run a query within a React component, call `useSearchResultQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchResultQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchResultQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchResultQueryQuery(baseOptions: Apollo.QueryHookOptions<SearchResultQueryQuery, SearchResultQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<SearchResultQueryQuery, SearchResultQueryQueryVariables>(SearchResultQueryDocument, options);
}
export function useSearchResultQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchResultQueryQuery, SearchResultQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<SearchResultQueryQuery, SearchResultQueryQueryVariables>(SearchResultQueryDocument, options);
}
export type SearchResultQueryQueryHookResult = ReturnType<typeof useSearchResultQueryQuery>;
export type SearchResultQueryLazyQueryHookResult = ReturnType<typeof useSearchResultQueryLazyQuery>;
export type SearchResultQueryQueryResult = Apollo.QueryResult<SearchResultQueryQuery, SearchResultQueryQueryVariables>;
export const SeoDocument = gql`
    query Seo($data: GetFeedSEObyPathRequest!) {
  getFeedSEOByPath(data: $data) {
    title
    description
    image
    keywords
  }
}
    `;

/**
 * __useSeoQuery__
 *
 * To run a query within a React component, call `useSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeoQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSeoQuery(baseOptions: Apollo.QueryHookOptions<SeoQuery, SeoQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<SeoQuery, SeoQueryVariables>(SeoDocument, options);
}
export function useSeoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeoQuery, SeoQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<SeoQuery, SeoQueryVariables>(SeoDocument, options);
}
export type SeoQueryHookResult = ReturnType<typeof useSeoQuery>;
export type SeoLazyQueryHookResult = ReturnType<typeof useSeoLazyQuery>;
export type SeoQueryResult = Apollo.QueryResult<SeoQuery, SeoQueryVariables>;
export const SurpriseMenuResultQueryDocument = gql`
    query SurpriseMenuResultQuery($data: GetSurpriseMenuResultRequest!) {
  getSurpriseMenuResult(data: $data) {
    id
    thumbUrl
    title
    slugUrl
  }
}
    `;

/**
 * __useSurpriseMenuResultQueryQuery__
 *
 * To run a query within a React component, call `useSurpriseMenuResultQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurpriseMenuResultQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurpriseMenuResultQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSurpriseMenuResultQueryQuery(baseOptions: Apollo.QueryHookOptions<SurpriseMenuResultQueryQuery, SurpriseMenuResultQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<SurpriseMenuResultQueryQuery, SurpriseMenuResultQueryQueryVariables>(SurpriseMenuResultQueryDocument, options);
}
export function useSurpriseMenuResultQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurpriseMenuResultQueryQuery, SurpriseMenuResultQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<SurpriseMenuResultQueryQuery, SurpriseMenuResultQueryQueryVariables>(SurpriseMenuResultQueryDocument, options);
}
export type SurpriseMenuResultQueryQueryHookResult = ReturnType<typeof useSurpriseMenuResultQueryQuery>;
export type SurpriseMenuResultQueryLazyQueryHookResult = ReturnType<typeof useSurpriseMenuResultQueryLazyQuery>;
export type SurpriseMenuResultQueryQueryResult = Apollo.QueryResult<SurpriseMenuResultQueryQuery, SurpriseMenuResultQueryQueryVariables>;
export const GetSurpriseMenuFilterQueryDocument = gql`
    query GetSurpriseMenuFilterQuery {
  getAllFilterMeatCategory {
    id
    title
    surpriseMenuImgUrl
  }
  getAllFilterRecipeMethod {
    id
    title
    surpriseMenuImgUrl
  }
}
    `;

/**
 * __useGetSurpriseMenuFilterQueryQuery__
 *
 * To run a query within a React component, call `useGetSurpriseMenuFilterQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurpriseMenuFilterQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurpriseMenuFilterQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSurpriseMenuFilterQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetSurpriseMenuFilterQueryQuery, GetSurpriseMenuFilterQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetSurpriseMenuFilterQueryQuery, GetSurpriseMenuFilterQueryQueryVariables>(GetSurpriseMenuFilterQueryDocument, options);
}
export function useGetSurpriseMenuFilterQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurpriseMenuFilterQueryQuery, GetSurpriseMenuFilterQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetSurpriseMenuFilterQueryQuery, GetSurpriseMenuFilterQueryQueryVariables>(GetSurpriseMenuFilterQueryDocument, options);
}
export type GetSurpriseMenuFilterQueryQueryHookResult = ReturnType<typeof useGetSurpriseMenuFilterQueryQuery>;
export type GetSurpriseMenuFilterQueryLazyQueryHookResult = ReturnType<typeof useGetSurpriseMenuFilterQueryLazyQuery>;
export type GetSurpriseMenuFilterQueryQueryResult = Apollo.QueryResult<GetSurpriseMenuFilterQueryQuery, GetSurpriseMenuFilterQueryQueryVariables>;
export const TipsTricksHighlightBannerQueryDocument = gql`
    query TipsTricksHighlightBannerQuery {
  getAllTipsAndTricksHighlight {
    id
    title
    description
    detail
    slugUrl
    imageUrl
  }
}
    `;

/**
 * __useTipsTricksHighlightBannerQueryQuery__
 *
 * To run a query within a React component, call `useTipsTricksHighlightBannerQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipsTricksHighlightBannerQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipsTricksHighlightBannerQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useTipsTricksHighlightBannerQueryQuery(baseOptions?: Apollo.QueryHookOptions<TipsTricksHighlightBannerQueryQuery, TipsTricksHighlightBannerQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<TipsTricksHighlightBannerQueryQuery, TipsTricksHighlightBannerQueryQueryVariables>(TipsTricksHighlightBannerQueryDocument, options);
}
export function useTipsTricksHighlightBannerQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TipsTricksHighlightBannerQueryQuery, TipsTricksHighlightBannerQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<TipsTricksHighlightBannerQueryQuery, TipsTricksHighlightBannerQueryQueryVariables>(TipsTricksHighlightBannerQueryDocument, options);
}
export type TipsTricksHighlightBannerQueryQueryHookResult = ReturnType<typeof useTipsTricksHighlightBannerQueryQuery>;
export type TipsTricksHighlightBannerQueryLazyQueryHookResult = ReturnType<typeof useTipsTricksHighlightBannerQueryLazyQuery>;
export type TipsTricksHighlightBannerQueryQueryResult = Apollo.QueryResult<TipsTricksHighlightBannerQueryQuery, TipsTricksHighlightBannerQueryQueryVariables>;
export const PopularTipsQueryDocument = gql`
    query PopularTipsQuery {
  getTipsTricksPopular {
    id
    title
    description
    detail
    slugUrl
    thumbUrl
    publishedAt
    category {
      title
    }
  }
}
    `;

/**
 * __usePopularTipsQueryQuery__
 *
 * To run a query within a React component, call `usePopularTipsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularTipsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularTipsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function usePopularTipsQueryQuery(baseOptions?: Apollo.QueryHookOptions<PopularTipsQueryQuery, PopularTipsQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<PopularTipsQueryQuery, PopularTipsQueryQueryVariables>(PopularTipsQueryDocument, options);
}
export function usePopularTipsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PopularTipsQueryQuery, PopularTipsQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<PopularTipsQueryQuery, PopularTipsQueryQueryVariables>(PopularTipsQueryDocument, options);
}
export type PopularTipsQueryQueryHookResult = ReturnType<typeof usePopularTipsQueryQuery>;
export type PopularTipsQueryLazyQueryHookResult = ReturnType<typeof usePopularTipsQueryLazyQuery>;
export type PopularTipsQueryQueryResult = Apollo.QueryResult<PopularTipsQueryQuery, PopularTipsQueryQueryVariables>;
export const GetRecommendTipsTricksDocument = gql`
    query GetRecommendTipsTricks {
  getRecommendTipsTricks {
    id
    title
    description
    thumbUrl
    publishedAt
    slugUrl
    category {
      title
    }
  }
}
    `;

/**
 * __useGetRecommendTipsTricksQuery__
 *
 * To run a query within a React component, call `useGetRecommendTipsTricksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendTipsTricksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendTipsTricksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecommendTipsTricksQuery(baseOptions?: Apollo.QueryHookOptions<GetRecommendTipsTricksQuery, GetRecommendTipsTricksQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetRecommendTipsTricksQuery, GetRecommendTipsTricksQueryVariables>(GetRecommendTipsTricksDocument, options);
}
export function useGetRecommendTipsTricksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecommendTipsTricksQuery, GetRecommendTipsTricksQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetRecommendTipsTricksQuery, GetRecommendTipsTricksQueryVariables>(GetRecommendTipsTricksDocument, options);
}
export type GetRecommendTipsTricksQueryHookResult = ReturnType<typeof useGetRecommendTipsTricksQuery>;
export type GetRecommendTipsTricksLazyQueryHookResult = ReturnType<typeof useGetRecommendTipsTricksLazyQuery>;
export type GetRecommendTipsTricksQueryResult = Apollo.QueryResult<GetRecommendTipsTricksQuery, GetRecommendTipsTricksQueryVariables>;
export const TipDetailQueryDocument = gql`
    query TipDetailQuery($where: GetTipsTricksDetailRequest!) {
  getTipsTricksDetail(where: $where) {
    id
    title
    detail
    slugUrl
    creator {
      id
      name
      imageUrl
      slugUrl
    }
    publishedAt
    tipsTricksCategory {
      id
      title
    }
  }
}
    `;

/**
 * __useTipDetailQueryQuery__
 *
 * To run a query within a React component, call `useTipDetailQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipDetailQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipDetailQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTipDetailQueryQuery(baseOptions: Apollo.QueryHookOptions<TipDetailQueryQuery, TipDetailQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<TipDetailQueryQuery, TipDetailQueryQueryVariables>(TipDetailQueryDocument, options);
}
export function useTipDetailQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TipDetailQueryQuery, TipDetailQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<TipDetailQueryQuery, TipDetailQueryQueryVariables>(TipDetailQueryDocument, options);
}
export type TipDetailQueryQueryHookResult = ReturnType<typeof useTipDetailQueryQuery>;
export type TipDetailQueryLazyQueryHookResult = ReturnType<typeof useTipDetailQueryLazyQuery>;
export type TipDetailQueryQueryResult = Apollo.QueryResult<TipDetailQueryQuery, TipDetailQueryQueryVariables>;
export const TipsTricksByCategoryIdQueryDocument = gql`
    query TipsTricksByCategoryIdQuery($where: GetTipsTricksByCategoryIdRequest!, $pagination: PaginationRequest) {
  getTipsTricksByCategoryId(where: $where, pagination: $pagination) {
    tipsTricks {
      id
      title
      description
      slugUrl
      thumbUrl
      publishedAt
      category {
        id
        title
      }
    }
    total
  }
}
    `;

/**
 * __useTipsTricksByCategoryIdQueryQuery__
 *
 * To run a query within a React component, call `useTipsTricksByCategoryIdQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipsTricksByCategoryIdQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipsTricksByCategoryIdQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTipsTricksByCategoryIdQueryQuery(baseOptions: Apollo.QueryHookOptions<TipsTricksByCategoryIdQueryQuery, TipsTricksByCategoryIdQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<TipsTricksByCategoryIdQueryQuery, TipsTricksByCategoryIdQueryQueryVariables>(TipsTricksByCategoryIdQueryDocument, options);
}
export function useTipsTricksByCategoryIdQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TipsTricksByCategoryIdQueryQuery, TipsTricksByCategoryIdQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<TipsTricksByCategoryIdQueryQuery, TipsTricksByCategoryIdQueryQueryVariables>(TipsTricksByCategoryIdQueryDocument, options);
}
export type TipsTricksByCategoryIdQueryQueryHookResult = ReturnType<typeof useTipsTricksByCategoryIdQueryQuery>;
export type TipsTricksByCategoryIdQueryLazyQueryHookResult = ReturnType<typeof useTipsTricksByCategoryIdQueryLazyQuery>;
export type TipsTricksByCategoryIdQueryQueryResult = Apollo.QueryResult<TipsTricksByCategoryIdQueryQuery, TipsTricksByCategoryIdQueryQueryVariables>;
export const TipsTricksCategoryQueryDocument = gql`
    query TipsTricksCategoryQuery {
  getTipsTricksCategory {
    id
    title
  }
}
    `;

/**
 * __useTipsTricksCategoryQueryQuery__
 *
 * To run a query within a React component, call `useTipsTricksCategoryQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipsTricksCategoryQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipsTricksCategoryQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useTipsTricksCategoryQueryQuery(baseOptions?: Apollo.QueryHookOptions<TipsTricksCategoryQueryQuery, TipsTricksCategoryQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<TipsTricksCategoryQueryQuery, TipsTricksCategoryQueryQueryVariables>(TipsTricksCategoryQueryDocument, options);
}
export function useTipsTricksCategoryQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TipsTricksCategoryQueryQuery, TipsTricksCategoryQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<TipsTricksCategoryQueryQuery, TipsTricksCategoryQueryQueryVariables>(TipsTricksCategoryQueryDocument, options);
}
export type TipsTricksCategoryQueryQueryHookResult = ReturnType<typeof useTipsTricksCategoryQueryQuery>;
export type TipsTricksCategoryQueryLazyQueryHookResult = ReturnType<typeof useTipsTricksCategoryQueryLazyQuery>;
export type TipsTricksCategoryQueryQueryResult = Apollo.QueryResult<TipsTricksCategoryQueryQuery, TipsTricksCategoryQueryQueryVariables>;
export const GetTikTokHashtagQueryDocument = gql`
    query GetTikTokHashtagQuery {
  getTiktokHashtag {
    id
    title
    hashtag
  }
}
    `;

/**
 * __useGetTikTokHashtagQueryQuery__
 *
 * To run a query within a React component, call `useGetTikTokHashtagQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTikTokHashtagQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTikTokHashtagQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTikTokHashtagQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetTikTokHashtagQueryQuery, GetTikTokHashtagQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetTikTokHashtagQueryQuery, GetTikTokHashtagQueryQueryVariables>(GetTikTokHashtagQueryDocument, options);
}
export function useGetTikTokHashtagQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTikTokHashtagQueryQuery, GetTikTokHashtagQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetTikTokHashtagQueryQuery, GetTikTokHashtagQueryQueryVariables>(GetTikTokHashtagQueryDocument, options);
}
export type GetTikTokHashtagQueryQueryHookResult = ReturnType<typeof useGetTikTokHashtagQueryQuery>;
export type GetTikTokHashtagQueryLazyQueryHookResult = ReturnType<typeof useGetTikTokHashtagQueryLazyQuery>;
export type GetTikTokHashtagQueryQueryResult = Apollo.QueryResult<GetTikTokHashtagQueryQuery, GetTikTokHashtagQueryQueryVariables>;
export const GetTikTokVdoByHashtagQueryDocument = gql`
    query GetTikTokVdoByHashtagQuery($where: GetTiktokVideoByHashtagRequest!) {
  getTiktokVideoByHashtag(where: $where) {
    id
    videoUrl
    thumbUrl
    tiktokThumbUrl
    viewCount
  }
}
    `;

/**
 * __useGetTikTokVdoByHashtagQueryQuery__
 *
 * To run a query within a React component, call `useGetTikTokVdoByHashtagQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTikTokVdoByHashtagQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTikTokVdoByHashtagQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTikTokVdoByHashtagQueryQuery(baseOptions: Apollo.QueryHookOptions<GetTikTokVdoByHashtagQueryQuery, GetTikTokVdoByHashtagQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetTikTokVdoByHashtagQueryQuery, GetTikTokVdoByHashtagQueryQueryVariables>(GetTikTokVdoByHashtagQueryDocument, options);
}
export function useGetTikTokVdoByHashtagQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTikTokVdoByHashtagQueryQuery, GetTikTokVdoByHashtagQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetTikTokVdoByHashtagQueryQuery, GetTikTokVdoByHashtagQueryQueryVariables>(GetTikTokVdoByHashtagQueryDocument, options);
}
export type GetTikTokVdoByHashtagQueryQueryHookResult = ReturnType<typeof useGetTikTokVdoByHashtagQueryQuery>;
export type GetTikTokVdoByHashtagQueryLazyQueryHookResult = ReturnType<typeof useGetTikTokVdoByHashtagQueryLazyQuery>;
export type GetTikTokVdoByHashtagQueryQueryResult = Apollo.QueryResult<GetTikTokVdoByHashtagQueryQuery, GetTikTokVdoByHashtagQueryQueryVariables>;
export const GetUserProfileQueryDocument = gql`
    query GetUserProfileQuery {
  cdpGetUserProfile {
    uuid
    firstName
    lastName
    gender
    birthDate
    phoneNumber
    email
    point
    imageUrl
    shouldShowInteresting
  }
}
    `;

/**
 * __useGetUserProfileQueryQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfileQueryQuery, GetUserProfileQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetUserProfileQueryQuery, GetUserProfileQueryQueryVariables>(GetUserProfileQueryDocument, options);
}
export function useGetUserProfileQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQueryQuery, GetUserProfileQueryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetUserProfileQueryQuery, GetUserProfileQueryQueryVariables>(GetUserProfileQueryDocument, options);
}
export type GetUserProfileQueryQueryHookResult = ReturnType<typeof useGetUserProfileQueryQuery>;
export type GetUserProfileQueryLazyQueryHookResult = ReturnType<typeof useGetUserProfileQueryLazyQuery>;
export type GetUserProfileQueryQueryResult = Apollo.QueryResult<GetUserProfileQueryQuery, GetUserProfileQueryQueryVariables>;