import { useWindowSize } from 'usehooks-ts';
import breakpoint from '@shared/assets/breakpoint.json';
interface IScreen {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}
export const useScreen = (): IScreen => {
  const {
    width
  } = useWindowSize();
  const isMobile = width < breakpoint.md;
  const isTablet = width >= breakpoint.md && width < breakpoint.lg;
  const isDesktop = width >= breakpoint.lg;
  return {
    isMobile,
    isTablet,
    isDesktop
  };
};