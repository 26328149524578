import { FC, ReactNode } from 'react';
import { CustomContainer, Wrapper } from './Layout.style';
interface Props {
  children?: ReactNode;
}
const ContentComponent: FC<Props> = ({
  children
}: Props) => {
  return <CustomContainer>
            <Wrapper>{children}</Wrapper>
        </CustomContainer>;
};
export default ContentComponent;