import { IInterestingRepository } from '@modules/interesting/adapter/outbound/ports/interesting.repository.port';
import { IInteresting, IInterestingContent } from '@modules/interesting/domain/models/interesting';
import { IGetAllInterestingUsecase } from '@modules/interesting/domain/ports/getAllInteresting.usecase.port';
import { mapInterestingTypeToQuery } from '@shared/helpers/mapInterestingType.helper';
import { InterestingType } from '@shared/types/interstingType.type';
export class GetAllInterestingUsecase implements IGetAllInterestingUsecase {
  constructor(private readonly interestingRepository: IInterestingRepository) {}
  async handle(): IGetAllInterestingUsecase.output {
    const response = await this.interestingRepository.getAllInteresting();
    const getMixer = await this.interestingRepository.getChoosedInteresting(mapInterestingTypeToQuery(InterestingType.Mixer));
    const getMenu = await this.interestingRepository.getChoosedInteresting(mapInterestingTypeToQuery(InterestingType.Menu));
    const getLifeStyle = await this.interestingRepository.getChoosedInteresting(mapInterestingTypeToQuery(InterestingType.LifeStyle));
    const checkChoosed = (interesting: IInterestingContent[], choosedList: number[]) => {
      return interesting.map(content => {
        if (choosedList.find(choosedId => choosedId === content.id)) {
          return {
            ...content,
            isChoosed: true
          };
        }
        return content;
      });
    };
    const result: IInteresting[] = [{
      id: 1,
      title: 'ส่วนผสม',
      content: checkChoosed(response.meatCategory, getMixer),
      type: InterestingType.Mixer
    }, {
      id: 2,
      title: 'ประเภทอาหาร',
      content: checkChoosed(response.recipeType, getMenu),
      type: InterestingType.Menu
    }, {
      id: 3,
      title: 'ไลฟ์สไตล์',
      content: checkChoosed(response.groupCategoryMain, getLifeStyle),
      type: InterestingType.LifeStyle
    }];
    return result;
  }
}