import Image from 'next/image';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "Contactstyle__Container",
  componentId: "sc-1lyemse-0"
})(["", " @media (max-width:", "){", "}"], {
  "marginTop": "0px",
  "marginBottom": "0px"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "marginTop": "1rem",
  "marginBottom": "1rem"
});
export const BrandLogo = styled(Image).withConfig({
  displayName: "Contactstyle__BrandLogo",
  componentId: "sc-1lyemse-1"
})(["", " @media (max-width:", "){", "}"], {
  "marginBottom": "1rem",
  "width": "11rem",
  "cursor": "pointer"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "marginBottom": "0.75rem",
  "width": "13rem"
});
export const BrandDesc = styled.div.withConfig({
  displayName: "Contactstyle__BrandDesc",
  componentId: "sc-1lyemse-2"
})(["", " @media (max-width:", "){", "}"], {
  "width": "13rem",
  "fontSize": "1.25rem",
  "lineHeight": "20px",
  "fontWeight": "300",
  "--tw-text-opacity": "1",
  "color": "rgb(206 206 206 / var(--tw-text-opacity, 1))"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "width": "100%"
});
const CustomLink = styled.a.withConfig({
  displayName: "Contactstyle__CustomLink",
  componentId: "sc-1lyemse-3"
})(["", ""], {
  "display": "flex",
  "alignItems": "center",
  "gap": "0.25rem",
  "fill": "orange-50"
});
export const Phone = styled(CustomLink).withConfig({
  displayName: "Contactstyle__Phone",
  componentId: "sc-1lyemse-4"
})(["", " svg{", " fill:white;transition:all 0.1s ease-in;}&:hover{", " svg{fill:", ";transition:all 0.1s ease-in;}}@media (max-width:", "){&:hover{", " svg{fill:white;}}}"], {
  "marginTop": "0.25rem",
  "marginBottom": "0.25rem",
  "width": "fit-content",
  "fontSize": "28px",
  "lineHeight": "30px",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity, 1))"
}, {
  "aspectRatio": "1 / 1",
  "width": "1rem"
}, {
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity, 1))"
}, ({
  theme
}) => theme.colors.orange[50], ({
  theme
}) => theme.breakpoint.md, {
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity, 1))"
});
export const Mail = styled(CustomLink).withConfig({
  displayName: "Contactstyle__Mail",
  componentId: "sc-1lyemse-5"
})(["", " svg{", " fill:", ";transition:all 0.1s ease-in;}&:hover{", " svg{", " fill:", ";transition:all 0.1s ease-in;}}@media (max-width:", "){&:hover{", " svg{fill:", ";}}}"], {
  "fontSize": "1.25rem",
  "lineHeight": "1.75rem",
  "fontWeight": "400",
  "--tw-text-opacity": "1",
  "color": "rgb(255 191 60 / var(--tw-text-opacity, 1))"
}, {
  "aspectRatio": "1 / 1",
  "width": "1rem"
}, ({
  theme
}) => theme.colors.yellow[50], {
  "--tw-text-opacity": "1",
  "color": "rgb(255 191 60 / var(--tw-text-opacity, 1))"
}, {
  "aspectRatio": "1 / 1",
  "width": "1rem"
}, ({
  theme
}) => theme.colors.orange[50], ({
  theme
}) => theme.breakpoint.md, {
  "--tw-text-opacity": "1",
  "color": "rgb(255 191 60 / var(--tw-text-opacity, 1))"
}, ({
  theme
}) => theme.colors.yellow[50]);