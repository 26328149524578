import { FC } from 'react';
import { match } from 'ts-pattern';
import { interestingStore } from '@modules/interesting/adapter/outbound/stores/interesting.store';
import { InterestingType } from '@shared/types/interstingType.type';
import { Container, Content, ContentDescription, ContentDescriptionSkeleton, ContentWrapper, LineMask, Wrapper } from './ContentModal.style';
import InterestContentItemLogic from './item/Item.logic';
import InterestStepperLogic from './stepper/Stepper.logic';
const InterestContentModalLogic: FC = () => {
  const {
    interestStep
  } = interestingStore(state => state);
  const mapContentDescription = (): string => {
    return match(interestStep?.type).with(InterestingType.LifeStyle, () => 'เลือกอาหารตามไลฟ์สไตล์ที่คุณชอบ').with(InterestingType.Mixer, () => 'เลือกส่วนผสมที่คุณชอบ').with(InterestingType.Menu, () => 'เลือกประเภทอาหารที่คุณชอบ').exhaustive();
  };
  return <Container>
            <InterestStepperLogic />
            <Wrapper>
                <LineMask />
                <ContentWrapper>
                    {interestStep?.type ? <ContentDescription>
                            {mapContentDescription()}
                        </ContentDescription> : <ContentDescriptionSkeleton active paragraph={false} />}
                    <Content>
                        <InterestContentItemLogic isMixer={interestStep?.type === InterestingType.Mixer} />
                    </Content>
                </ContentWrapper>
            </Wrapper>
        </Container>;
};
export default InterestContentModalLogic;