import { FC, ReactNode } from 'react';
import Image from 'next/image';
import { useScreen } from '@shared/helpers/screen.helper';
import { BottomContent, Content, CustomModal, Wrapper } from './AlertModal.style';
interface Props {
  isOpen: boolean;
  imageUrl: string;
  title: ReactNode;
  description: ReactNode;
  bottomContent?: ReactNode;
}
const AlertModal: FC<Props> = ({
  isOpen,
  imageUrl,
  title,
  description,
  bottomContent
}: Props) => {
  const {
    isMobile
  } = useScreen();
  return <CustomModal title={null} open={isOpen} footer={null} width={900} closable={false} maskClosable={false} rootClassName="modal" centered styles={{
    wrapper: {
      inset: isMobile ? 'auto 0 0' : 0
    },
    mask: {
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
      backdropFilter: 'blur(8px)'
    },
    content: {
      height: isMobile ? '100%' : 'auto',
      display: isMobile ? 'flex' : 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      borderRadius: isMobile ? 0 : 16,
      boxShadow: '0px 0px 0px 0px #0000000D,0px 7px 16px 0px #0000000D,0px 29px 29px 0px #0000000A,0px 66px 40px 0px #00000008,0px 117px 47px 0px #00000003,0px 183px 51px 0px #00000000'
    },
    body: {
      width: '100%',
      height: '100%'
    }
  }}>
            <Wrapper>
                <Content>
                    <Image className="image" src={imageUrl} alt="alert image" width={250} height={286} />
                    <div className="title">{title}</div>
                    <div className="description">{description}</div>
                </Content>
                <BottomContent>{bottomContent}</BottomContent>
            </Wrapper>
        </CustomModal>;
};
export default AlertModal;