import { FC, Fragment, useEffect } from 'react';
import { useScreen } from '@shared/helpers/screen.helper';
import useLockedBody from '@shared/utils/hooks/useLockedBody.utils';
import AlertModal from './AlertModal';
import { ButtonWrapper, CustomButton } from './AlertModal.style';
interface Props {
  isOpen: boolean;
}
const SuccessModalLogic: FC<Props> = ({
  isOpen
}: Props) => {
  const {
    isMobile
  } = useScreen();
  const [_locked, setLocked] = useLockedBody(false, 'root');
  useEffect(() => {
    setLocked(isOpen);
  }, [isOpen]);
  const renderDescription = () => {
    return isMobile ? <Fragment>
                เย้! สำเร็จแล้ว
                <br />
                เตรียมพบกับเมนูที่ใช่สำหรับคุณได้เลย
            </Fragment> : 'เย้! สำเร็จแล้ว เตรียมพบกับเมนูที่ใช่สำหรับคุณได้เลย';
  };
  const renderBottomContent = () => {
    return <ButtonWrapper>
                <CustomButton type="default" onClick={() => window.location.href = '/'}>
                    กลับหน้าหลัก
                </CustomButton>
            </ButtonWrapper>;
  };
  return <AlertModal isOpen={isOpen} imageUrl={'/images/interesting/interesting-success.png'} title={'สำเร็จ'} description={renderDescription()} bottomContent={renderBottomContent()} />;
};
export default SuccessModalLogic;